import { View } from "@react-pdf/renderer";
import { BreakLine } from "./Components";
import { horizontalPadding, perelynRed, verticalPadding } from "./const";
import { SmallTextPDF, TinyTextPDF } from "./Texts";

export default function Footer() {
  return (
    <View
      fixed
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
      }}
    >
      <SmallTextPDF
        style={{
          padding: `${verticalPadding} ${horizontalPadding}`,
          color: perelynRed,
        }}
        render={({
          pageNumber,
          totalPages,
        }: {
          pageNumber: number;
          totalPages: number;
        }) => (totalPages - pageNumber === 0 ? "" : `Continues on next page`)}
      />
      <BreakLine />
      <View
        style={{
          padding: `${verticalPadding} ${horizontalPadding}`,
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
        }}
      >
        <TinyTextPDF>Page </TinyTextPDF>
        <TinyTextPDF
          render={({ pageNumber }: { pageNumber: number }) => `${pageNumber}`}
        />
        {/* <TinyText>Perelyn GmbH</TinyText>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TinyText>Reichenbachstr. 31, D-80469 München </TinyText>
          <RedSlash size={8} />
          <TinyText
            style={{
              color: perelynRed,
              textDecoration: "underline",
            }}
          >
            contact@perelyn.com
          </TinyText>
          <RedSlash size={8} />
        </View> */}
      </View>
    </View>
  );
}
