import { useState } from "react";
import userService from "../../../../../api/UserService";
import { useSnackbar } from "../../../../../common/context/SnackbarProvider";
import { useUser } from "../../../../../common/context/UserProvider";
import { flip } from "../../../../../utils";
import ModifyParameterPopup from "../../../../Settings/CustomParameters/ModifyParameterPopup";
import { useTask } from "../../../MarketingTextTool/context/TaskProvider";

export default function ModifyParameterPopupTask({
  setIsOpen,
  parameterName,
  parameterValue,
  parameterGroup,
}: {
  setIsOpen: (isOpen: boolean) => void;
  parameterName?: string;
  parameterValue?: string;
  parameterGroup?: string;
}) {
  const { showSnackbar } = useSnackbar();
  const { user, setUser } = useUser();
  const { task, saveTask } = useTask();
  const [isSaving, setIsSaving] = useState(false);
  const [selectedParameterGroup, setSelectedParameterGroup] = useState(
    parameterGroup || "custom"
  );

  const saveParameter = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const newParameterName = data.get("parameter-name")?.toString();
    const newContent = data.get("parameter-content")?.toString();
    const newGroup =
      data.get("parameter-group")?.toString() || selectedParameterGroup;
    if (!newParameterName || !newContent) {
      showSnackbar("Please fill in all fields", true);
      flip(setIsSaving)();
      return;
    }

    if (
      user.parameters?.value_options.some(
        (option) =>
          option.name === newParameterName && option.name !== parameterName
      )
    ) {
      showSnackbar(
        "Parameter with this name already exists, please pick a different name.",
        true
      );
      flip(setIsSaving)();
      return;
    }

    const valueOption = {
      default: false,
      group: newGroup,
      name: newParameterName,
      value: [newContent.toLowerCase()],
    };

    // Update user parameters
    const updatedUserOptions =
      user.parameters?.value_options.map((option) =>
        option.name === parameterName ? valueOption : option
      ) || [];

    if (!parameterName) {
      updatedUserOptions.push(valueOption);
    }

    const additionalParams = {};
    if (parameterName && parameterName !== newParameterName) {
      additionalParams["previousParameterName"] = parameterName;
    }

    await userService.updateUserParameter(
      {
        parameters: {
          value_options: updatedUserOptions,
        },
        ...additionalParams,
      },
      setUser
    );

    // Update task parameters
    const currentOptions =
      task.configs.entityExtraction.nodes.EntityExtractionNode.entities
        .value_options || [];

    const updatedTaskOptions = [...currentOptions, valueOption];
    const updatedValues = [
      ...task.configs.entityExtraction.nodes.EntityExtractionNode.entities
        .value,
      newContent.toLowerCase(),
    ];

    saveTask({
      "configs.entityExtraction.nodes.EntityExtractionNode.entities.value_options":
        updatedTaskOptions,
      "configs.entityExtraction.nodes.EntityExtractionNode.entities.value":
        updatedValues,
    });

    showSnackbar("Parameter saved successfully", false);
    flip(setIsOpen)();
  };

  return (
    <ModifyParameterPopup
      saveParameter={saveParameter}
      parameterName={parameterName}
      parameterValue={parameterValue}
      setIsSaving={setIsSaving}
      isSaving={isSaving}
      selectedParameterGroup={selectedParameterGroup}
      setSelectedParameterGroup={setSelectedParameterGroup}
    />
  );
}
