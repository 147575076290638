import { Box, Stack } from "@mui/material";
import { HeadingText } from "../../common/styles/Text.styles";
import { borderStyle, defaultGap } from "../../styles/consts";

export default function SettingsPage({
  children,
  heading,
}: {
  children: React.ReactNode;
  heading: string;
}) {
  return (
    <Box>
      <Stack sx={{ padding: `${defaultGap} ${defaultGap} 0` }}>
        <HeadingText>{heading}</HeadingText>
      </Stack>

      <Stack
        sx={{
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
          borderBottom: borderStyle,
          boxSizing: "border-box",
          padding: `${defaultGap} ${defaultGap} 3.75rem`,
          gap: "3.75rem",
        }}
      >
        {children}
      </Stack>
    </Box>
  );
}
