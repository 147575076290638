import { Image, Text, View } from "@react-pdf/renderer";
import { BreakLine, RedBulletPointSquare, RedSlash } from "./Components";
import { horizontalPadding, perelynRed, verticalPadding } from "./const";
import { LargeTextPDF, SmallTextPDF } from "./Texts";
import { useContext } from "react";
import { CVContentContext } from "./CVPDF";
import { HeaderRow, Row, Table } from "./TableComponents";

export default function FirstPageContent() {
  const cvContent = useContext<any>(CVContentContext);
  return (
    cvContent && (
      <>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              paddingLeft: horizontalPadding,
              paddingTop: verticalPadding,
              paddingBottom: verticalPadding,
            }}
          >
            <SmallTextPDF
              style={{
                color: perelynRed,
              }}
            >
              Profile
            </SmallTextPDF>
            <LargeTextPDF>{cvContent["fullName"]}</LargeTextPDF>
            <Text
              style={{
                fontSize: "14px",
                fontFamily: "ABCWhyte",
              }}
            >
              {cvContent["jobTitle"]}
            </Text>
            <SmallTextPDF>Perelyn GmbH, {cvContent["location"]}</SmallTextPDF>
          </View>
          <Image
            src={`/cv-generation/profile/${cvContent["fullName"].replaceAll(
              " ",
              "-"
            )}.jpg`}
            style={{
              width: "150px",
              height: "150px",
            }}
          />
        </View>
        <BreakLine />
        <View
          style={{
            padding: `${verticalPadding} ${horizontalPadding}`,
          }}
        >
          <SmallTextPDF
            style={{
              color: perelynRed,
              marginBottom: verticalPadding,
            }}
          >
            Summary
          </SmallTextPDF>
          <SmallTextPDF>
            {cvContent["headlines"].map((headline, index) => (
              <Text key={index}>
                {headline}
                {index < cvContent["headlines"].length - 1 && <RedSlash />}
              </Text>
            ))}
          </SmallTextPDF>
        </View>
        <BreakLine />
        <View
          style={{
            padding: `${verticalPadding} ${horizontalPadding}`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SmallTextPDF
            style={{
              color: perelynRed,
            }}
          >
            Education/Academics
          </SmallTextPDF>
          <SmallTextPDF>B.Sc. Informatics and Econometrics</SmallTextPDF>
        </View>
        <BreakLine />
        <View
          style={{
            padding: `${verticalPadding} ${horizontalPadding}`,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View style={{ maxWidth: "70%" }}>
            {cvContent["certificates"].map((certificate, index) => (
              <View
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                }}
              >
                <RedBulletPointSquare
                  style={{
                    marginTop: "5px",
                  }}
                />
                <SmallTextPDF>
                  <SmallTextPDF
                    style={{
                      color: perelynRed,
                    }}
                  >
                    {certificate["label"]}
                  </SmallTextPDF>
                  {`: ${certificate["name"]}`}
                </SmallTextPDF>
              </View>
            ))}
          </View>
        </View>
        <BreakLine />
        <View style={{ padding: horizontalPadding }}>
          <Table>
            <HeaderRow label="Nationality / Languages" />
            <Row>
              <SmallTextPDF
                style={{
                  width: "30%",
                }}
              >
                Nationality:
              </SmallTextPDF>
              <SmallTextPDF>{cvContent["nationality"]}</SmallTextPDF>
            </Row>
            <Row>
              <SmallTextPDF
                style={{
                  width: "30%",
                }}
              >
                Languages:
              </SmallTextPDF>
              <View>
                {cvContent["languages"].map((language, index) => (
                  <View
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <SmallTextPDF
                      style={{
                        width: "40%",
                      }}
                    >
                      {language.name}:
                    </SmallTextPDF>
                    <SmallTextPDF>{language.level}</SmallTextPDF>
                  </View>
                ))}
              </View>
            </Row>
          </Table>
        </View>
        {/* <View
          style={{
            padding: `${verticalPadding} ${horizontalPadding}`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SmallText
            style={{
              color: perelynRed,
            }}
          >
            Your contact person:
          </SmallText>
          <MediumText>Philipp Ullmann</MediumText>
          <SmallText>Mobile: +49 175 805 289 22</SmallText>
          <SmallText>
            Email:{" "}
            <SmallText
              style={{
                color: perelynRed,
                textDecoration: "underline",
              }}
            >
              philipp.ullmann@perelyn.com
            </SmallText>
          </SmallText>
        </View> */}
      </>
    )
  );
}
