import { Collapse, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Checkbox from "../../../../../common/components/fundamentals/Checkbox";
import { useUser } from "../../../../../common/context/UserProvider";
import { DisabledDropdown } from "../../../../../common/styles/Box.styles";
import {
  SmallText,
  UnderlinedOnHoverText,
} from "../../../../../common/styles/Text.styles";
import {
  borderGrey,
  borderStyle,
  perelynBlack,
  perelynGrey,
  perelynPrimary,
  smallGap,
} from "../../../../../styles/consts";
import {
  capitalizeFirstLetter,
  flip,
  replaceUnderscoreForWhitespace,
} from "../../../../../utils";
import ModifyPromptPopup from "../../../../Settings/CustomPrompts/ModifyPromptPopup";
import DropdownWithLabel from "../../components/config/section/SocialMediaSettings/DropdownWithLabel";
import PopUpBox from "../../components/result/PopUpBox";
import ArenaAutoUpdateTextField from "../ArenaAutoUpdateTextField";
import { useArena } from "../ArenaProvider";
import ArenaCustomPromptPresetDropdown from "./ArenaCustomPromptPresetDropdown";

export default function ArenaPromptPresetSection({
  model,
  configModel,
  id,
  nodeType,
  newPromptPreset,
  setNewPromptPreset,
}: {
  model: string;
  configModel: string;
  id?: string;
  nodeType: string;
  newPromptPreset: string;
  setNewPromptPreset: (value: string) => void;
}) {
  const { config, saveArena } = useArena();
  const { user } = useUser();
  const [iC, setIC] = useState(config.userPrompts[configModel].use ?? false);
  const [promptPreset, setPromptPreset] = useState(
    config.configs[configModel].nodes[nodeType].promptPresets.value
      ?.presetName ?? ""
  );

  useEffect(() => {
    if (newPromptPreset !== "") {
      setPromptPreset(newPromptPreset);
      setNewPromptPreset("");
    }
  }, [newPromptPreset, setNewPromptPreset]);

  const onCheckboxChange = async () => {
    flip(setIC)();
    const updatedArena = await saveArena({
      [`userPrompts.${configModel}.use`]: !config.userPrompts[configModel]?.use,
    });
    setIC(updatedArena.userPrompts[configModel]?.use ?? false);
  };

  return (
    <Stack sx={{ gap: smallGap }}>
      {iC ? (
        <Stack>
          <Typography>Model {model}</Typography>
          <DisabledDropdown
            sx={{
              "&&": {
                border: iC ? borderGrey : borderStyle,
                color: iC ? perelynGrey : perelynBlack,
              },
            }}
          >
            {capitalizeFirstLetter(
              replaceUnderscoreForWhitespace(
                config.configs[configModel].nodes[nodeType].promptPresets.value
                  .presetName
              )
            )}
          </DisabledDropdown>
        </Stack>
      ) : (
        <DropdownWithLabel
          id={id}
          zIndexModifier={1000}
          isColumn
          label={`Model ${model}`}
          options={config.configs[configModel].nodes[
            nodeType
          ].promptPresets.value_options.map((option) => ({
            value: option.presetName,
          }))}
          value={promptPreset}
          onChange={(value) => {
            const newSelectedPromptPreset = config.configs[configModel].nodes[
              nodeType
            ].promptPresets.value_options.find(
              (option) => option.presetName === value
            );

            if (newSelectedPromptPreset) {
              const { default: _, ...rest } = newSelectedPromptPreset;
              saveArena({
                [`configs.${configModel}.nodes.${nodeType}.promptPresets.value`]:
                  rest,
              });
            }

            setPromptPreset(value);
          }}
        />
      )}

      <PopUpBox
        button={
          <UnderlinedOnHoverText>
            Customize prompt {model}
          </UnderlinedOnHoverText>
        }
        heading={`Customize prompt ${model}`}
      >
        <ArenaAutoUpdateTextField
          id="prompt"
          defaultValue={
            iC
              ? config.userPrompts[configModel]?.content
              : config.configs[configModel].nodes[nodeType].promptPresets.value
                  .prompts.systemPrompt1
          }
          propPath={
            iC
              ? `userPrompts.${configModel}.content`
              : `configs.${configModel}.nodes.${nodeType}.promptPresets.value.prompts.systemPrompt1`
          }
          multiline
          minRows={13}
          maxRows={15}
          sx={{
            width: "100%",
          }}
        />
      </PopUpBox>

      {user.prompts && Object.keys(user.prompts).length > 0 ? (
        <>
          <Stack sx={{ flexDirection: "row", gap: smallGap }}>
            <Checkbox
              iconName="CheckboxX"
              sx={{
                border: borderStyle,
              }}
              isChecked={iC}
              onChange={onCheckboxChange}
            />
            Use my own custom prompt
          </Stack>
          <Collapse in={iC}>
            <ArenaCustomPromptPresetDropdown
              model={model}
              configModel={configModel}
            />
          </Collapse>
          <Collapse in={iC}>
            <Stack
              sx={{ flexDirection: "row", justifyContent: "right" }}
            ></Stack>
          </Collapse>
        </>
      ) : (
        <Stack>
          <SmallText>You can use your own custom prompts</SmallText>
          <PopUpBox
            button={
              <SmallText
                sx={{
                  textDecoration: "underline",
                  cursor: "crosshair",
                  ":hover": {
                    color: perelynPrimary,
                  },
                }}
              >
                Create one
              </SmallText>
            }
            heading={"Create new prompt"}
          >
            {(setIsOpen) => <ModifyPromptPopup setIsOpen={setIsOpen} />}
          </PopUpBox>
        </Stack>
      )}
    </Stack>
  );
}
