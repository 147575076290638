import { Stack, Typography } from "@mui/material";
import Dropdown from "../../../../../../../common/components/fundamentals/Dropdown";
import { tinyGap } from "../../../../../../../styles/consts";
import { capitalizeFirstLetter } from "../../../../../../../utils";

export type Option = {
  name?: string;
  value: string;
  iconName?: string;
};

export default function DropdownWithLabel({
  label,
  options,
  value,
  onChange,
  isColumn,
  zIndexModifier = 0,
  id,
}: {
  label: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  isColumn?: boolean;
  zIndexModifier?: number;
  id?: string;
}) {
  const dropdownOptions = options.map((option) => ({
    name: option.name ?? capitalizeFirstLetter(option.value),
    value: option.value,
  }));

  return (
    <Stack
      sx={{
        flexDirection: isColumn ? "column" : "row",
        alignItems: isColumn ? "none" : "center",
        gap: isColumn ? "none" : tinyGap,
        justifyContent: "space-between",
      }}
    >
      <Typography sx={{ whiteSpace: "nowrap" }}>{label}</Typography>
      <Dropdown
        id={id}
        options={dropdownOptions}
        zIndexModifier={label === "Prompt preset" ? 100 : zIndexModifier}
        onChange={onChange}
        initialSelected={capitalizeFirstLetter(value)}
      />
    </Stack>
  );
}
