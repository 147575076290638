import { apiAxios } from "./axios";

class BackgroundMusicService {
  async getPresignedDownloadURL(): Promise<string> {
    return (await apiAxios.get<string>("/background-music")).data;
  }
}

const backgroundMusicService = new BackgroundMusicService();

export default backgroundMusicService;
