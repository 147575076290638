import { Arena, Task } from "../common/models/Models";
import { apiAxios } from "./axios";

const RUN_AGENT_ONE_URL = "/run-agent-one";
const RUN_AGENT_ONE_ARENA_URL = "/run-agent-one-arena";

class AgentOneService {
  async runMarketingTextGeneration(task: Task): Promise<any> {
    return (
      await apiAxios.post<any>(RUN_AGENT_ONE_URL, {
        task,
        configs: ["marketingText", "marketingMetadata"],
        idToken: localStorage.getItem("idToken"),
      })
    ).data;
  }

  async runMarketingTextArena(arena: Arena): Promise<any> {
    return (
      await apiAxios.post<any>(RUN_AGENT_ONE_ARENA_URL, {
        arena,
        configs: ["marketingTextA", "marketingTextB"],
      })
    ).data;
  }

  async runEntityExtraction(task: Task): Promise<any> {
    return (
      await apiAxios.post<any>(RUN_AGENT_ONE_URL, {
        task,
        configs: ["entityExtraction"],
      })
    ).data;
  }

  async getMarketingConfig(): Promise<any> {
    return (await apiAxios.get<any>("/config/marketingText")).data;
  }
}

const agentOneService = new AgentOneService();

export default agentOneService;
