import { Stack, Typography } from "@mui/material";
import Icon from "../../../common/components/fundamentals/Icon";
import { OrangeButton } from "../../../common/styles/Button.styles";
import { HeadingText, SmallText } from "../../../common/styles/Text.styles";
import {
  borderStyle,
  defaultTransition,
  iconGap,
  smallerGap,
} from "../../../styles/consts";
import PopUpBox from "../../Modules/MarketingTextTool/components/result/PopUpBox";
import { SettingsArea, SettingsButtonSection } from "../Settings.styles";
import ModifyPromptPopup from "./ModifyPromptPopup";
import PromptsList from "./PromptsList";

export default function PromptsSettings() {
  return (
    <SettingsArea sx={{ maxHeight: "28rem" }}>
      <Stack sx={{ borderBottom: borderStyle }}>
        <Stack sx={{ padding: smallerGap }}>
          <HeadingText>Customize prompt presets</HeadingText>
          <SmallText>You can create and save customized prompts here</SmallText>
        </Stack>
      </Stack>

      <PromptsList />

      <SettingsButtonSection>
        <PopUpBox
          button={
            <OrangeButton
              sx={{
                borderLeft: borderStyle,
                gap: iconGap,
                ":hover": {
                  "& > *": {
                    cursor: "crosshair",
                    transition: defaultTransition,
                    filter:
                      "brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(1354%) hue-rotate(315deg) brightness(98%) contrast(107%)",
                  },
                },
              }}
            >
              <Typography>Add new</Typography>
              <Icon
                sx={{
                  height: smallerGap,
                }}
                name="Plus"
              />
            </OrangeButton>
          }
          heading={`Create new prompt`}
        >
          {(setIsOpen) => <ModifyPromptPopup setIsOpen={setIsOpen} />}
        </PopUpBox>
      </SettingsButtonSection>
    </SettingsArea>
  );
}
