import {
  Box,
  CircularProgress,
  Collapse,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Dropdown from "../../../common/components/fundamentals/Dropdown";
import { useUser } from "../../../common/context/UserProvider";
import { OrangeButton } from "../../../common/styles/Button.styles";
import { UnderlinedOnHoverText } from "../../../common/styles/Text.styles";
import {
  borderStyle,
  defaultGap,
  perelynBlack,
  perelynPrimary,
  sidebarWidth,
  smallerGap,
  smallGap,
} from "../../../styles/consts";
import { flip } from "../../../utils";
import { InternalSettingsAreaWithPadding } from "../Settings.styles";

export default function ModifyParameterPopup({
  saveParameter,
  parameterName,
  parameterValue,
  setIsSaving,
  isSaving,
  selectedParameterGroup,
  setSelectedParameterGroup,
}: {
  saveParameter: (event: React.FormEvent<HTMLFormElement>) => void;
  parameterName?: string;
  parameterValue?: string;
  setIsSaving: (isSaving: boolean) => void;
  isSaving: boolean;
  selectedParameterGroup: string;
  setSelectedParameterGroup: (value: string) => void;
}) {
  const { user } = useUser();
  const [createNewGroup, setCreateNewGroup] = useState(false);

  const groupOptions = Array.from(
    new Set(user.parameters?.value_options.map((option) => option.group))
  );
  if (!groupOptions.includes("custom")) {
    groupOptions.unshift("custom");
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      component="form"
      onSubmit={saveParameter}
    >
      <InternalSettingsAreaWithPadding
        sx={{ height: "100%", border: borderStyle }}
      >
        <Stack>
          <Stack
            sx={{ flexDirection: "row", gap: "0.225rem", alignItems: "center" }}
          >
            <UnderlinedOnHoverText
              sx={{ color: !createNewGroup ? perelynPrimary : "inherit" }}
              onClick={() => setCreateNewGroup(false)}
            >
              Select parameter's group
            </UnderlinedOnHoverText>
            <Typography>or</Typography>
            <UnderlinedOnHoverText
              sx={{ color: createNewGroup ? perelynPrimary : "inherit" }}
              onClick={() => setCreateNewGroup(true)}
            >
              create new one +
            </UnderlinedOnHoverText>
          </Stack>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: smallGap }}
          >
            {!createNewGroup ? (
              <Collapse in={!createNewGroup} orientation="horizontal">
                <Dropdown
                  id="parameter-group-dropdown"
                  options={groupOptions.map((option) => ({
                    name: option,
                    value: option,
                  }))}
                  initialSelected={selectedParameterGroup}
                  onChange={(value) => setSelectedParameterGroup(value)}
                />
              </Collapse>
            ) : (
              <Collapse in={createNewGroup} orientation="horizontal">
                <TextField
                  sx={{ width: sidebarWidth, height: defaultGap }}
                  id="parameter-group"
                  name="parameter-group"
                  placeholder="Enter group name*"
                  inputProps={{ maxLength: 40 }}
                />
              </Collapse>
            )}
          </Stack>
        </Stack>

        <Stack>
          <Typography>Name (1-40 characters)</Typography>
          <TextField
            id="parameter-name"
            name="parameter-name"
            placeholder="Enter parameter name*"
            defaultValue={parameterName}
            inputProps={{ maxLength: 40 }}
          />
        </Stack>
        <Stack>
          <TextField
            id="parameter-content"
            name="parameter-content"
            multiline
            rows="10"
            placeholder="Enter parameters description*"
            defaultValue={parameterValue}
          />
        </Stack>
      </InternalSettingsAreaWithPadding>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "right",
          border: borderStyle,
          borderTop: "none",
        }}
      >
        <OrangeButton
          onClick={flip(setIsSaving)}
          type="submit"
          sx={{
            borderLeft: borderStyle,
            width: "4rem",
            ":hover": {
              "& .MuiCircularProgress-root": {
                color: perelynPrimary,
              },
            },
          }}
        >
          {isSaving ? (
            <CircularProgress sx={{ color: perelynBlack }} size={smallerGap} />
          ) : (
            "Save"
          )}
        </OrangeButton>
      </Stack>
    </Box>
  );
}
