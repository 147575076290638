import { DefaultPage, RedSlash } from "./Components";
import { ContentRow, HeaderRow, Table } from "./TableComponents";
import { SmallTextPDF } from "./Texts";
import { useContext } from "react";
import { CVContentContext } from "./CVPDF";

export default function Tech() {
  const cvContent = useContext<any>(CVContentContext);
  return (
    <DefaultPage>
      <Table>
        <HeaderRow label="Industry & Technical Expertise" />
        {Object.entries(cvContent["tech"]).map(([key, value]: any, index) => (
          <ContentRow
            key={key}
            label={key}
            content={value.map((headline, index) => (
              <SmallTextPDF key={index}>
                {headline}
                {index < value.length - 1 && <RedSlash />}
              </SmallTextPDF>
            ))}
            last={index === Object.keys(cvContent["tech"]).length - 1}
          />
        ))}
      </Table>
    </DefaultPage>
  );
}
