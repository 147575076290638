import { Collapse, Stack } from "@mui/material";
import { useState } from "react";
import Dropdown from "../../../../common/components/fundamentals/Dropdown";
import Icon from "../../../../common/components/fundamentals/Icon";
import {
  DisabledDropdown,
  GreyOnHoverStack,
} from "../../../../common/styles/Box.styles";
import {
  defaultTransition,
  iconGap,
  sidebarWidth,
  smallGap,
} from "../../../../styles/consts";
import ArenaAutoUpdateTextField from "./ArenaAutoUpdateTextField";
import { useArena } from "./ArenaProvider";

export default function ArenaContextCustomization({ isDisabled }) {
  const { config, saveArena } = useArena();
  const firstValueOption =
    config.configs.marketingTextA.general.context.value_options[0];
  const [contextType, setContextType] = useState(
    firstValueOption?.name ?? "N/A"
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const textFieldProps = {
    Text: {
      placeholder: "Enter custom context*",
      multiline: true,
      maxRows: 8,
    },
    URL: {
      placeholder: "Enter website URL*",
    },
    File: {
      placeholder: "NOT WORKING YET",
      disabled: true,
    },
  };

  return (
    <Stack>
      <Stack sx={{ gap: smallGap }}>
        <GreyOnHoverStack
          sx={{
            flexDirection: "row",
            gap: iconGap,
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          Context Customization{" "}
          <Icon
            name="ChevronUp"
            sx={{
              transition: defaultTransition,
              transform: isOpen ? "none" : "scaleY(-1)",
            }}
          />
        </GreyOnHoverStack>
        <Collapse in={isOpen}>
          <Stack
            sx={{
              gap: smallGap,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {isDisabled ||
            !!config.processStatuses?.marketingTextA?.result ||
            !!config.processStatuses?.marketingTextB?.result ? (
              <DisabledDropdown>
                {
                  config.configs.marketingTextA.general.context.value?.at(0)
                    ?.name
                }
              </DisabledDropdown>
            ) : (
              <Dropdown
                initialSelected={contextType}
                options={config.configs.marketingTextA.general.context.value_options.map(
                  (o) => {
                    return {
                      name: o.name,
                      type: o.type,
                      value: o.value,
                    };
                  }
                )}
                onChange={async (name, type, value) => {
                  setContextType(name);
                  const c = await saveArena({
                    "configs.marketingTextA.general.context.value[0]": {
                      name,
                      type,
                      value,
                    },
                    "configs.marketingTextB.general.context.value[0]": {
                      name,
                      type,
                      value,
                    },
                  });
                  setContextType(
                    c.configs.marketingTextA.general.context.value?.at(0)
                      ?.name ?? "N/A"
                  );
                }}
              />
            )}

            <ArenaAutoUpdateTextField
              disabled={
                isDisabled ||
                !!config.processStatuses?.marketingTextA?.result ||
                !!config.processStatuses?.marketingTextB?.result
              }
              id="task-context"
              sx={{
                width: "50%",
                minWidth: sidebarWidth,
              }}
              {...textFieldProps[contextType]}
              defaultValue={
                config.configs.marketingTextA.general.context.value?.at(0)
                  ?.value ?? ""
              }
              propPath={[
                "configs.marketingTextA.general.context.value[0].value",
                "configs.marketingTextB.general.context.value[0].value",
              ]}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
}
