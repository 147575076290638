import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import configService from "../../../../api/ConfigService";
import CircularLoader from "../../../../common/components/fundamentals/Loader/CircularLoader";
import { smallGap } from "../../../../styles/consts";
import ModifyAlqemiMatchingConfig from "./ModifyAlqemiMatchingConfig";

const alqemiMatchingConfigId = {
  dev: "3b026335-d1a8-4f1e-ac5a-8dd914441d1f",
  prod: "e9644ebe-a3f1-4112-92b2-44b718661bf5",
};
const getCurrentEnv = () => {
  return process.env.REACT_APP_ENV;
};
export default function ConfigButtons() {
  const [alqemiMatchingConfig, setAlqemiMatchingConfig] = useState<any>(null);
  const currentEnv = getCurrentEnv() || "prod";

  const getAlqemiMatchingConfig = async () => {
    const config = await configService.getConfiguration(
      alqemiMatchingConfigId[currentEnv]
    );
    setAlqemiMatchingConfig(config);
    return config;
  };

  const saveConfig = async (updateProperties) => {
    await configService.updateConfigItem(
      { id: alqemiMatchingConfigId[currentEnv] },
      updateProperties
    );
    getAlqemiMatchingConfig();
  };

  useEffect(() => {
    getAlqemiMatchingConfig();
  }, []);

  return (
    <Stack>
      {alqemiMatchingConfig ? (
        <Stack sx={{ flexDirection: "row", gap: smallGap }}>
          <ModifyAlqemiMatchingConfig
            type="Email Template"
            defaultValue={
              alqemiMatchingConfig.nodes.WriteEmailNode.email_template.value[0]
                .email_template
            }
            propPath="nodes.WriteEmailNode.email_template.value[0].email_template"
            saveConfig={saveConfig}
          />

          <ModifyAlqemiMatchingConfig
            type="Email Prompt"
            defaultValue={
              alqemiMatchingConfig.nodes.WriteEmailNode.promptPresets.value
                .prompts.systemPrompt1
            }
            propPath="nodes.WriteEmailNode.promptPresets.value.prompts.systemPrompt1"
            saveConfig={saveConfig}
          />
          <ModifyAlqemiMatchingConfig
            type="CV Prompt"
            defaultValue={
              alqemiMatchingConfig.nodes.CVGeneratorNode.promptPresets.value
                .prompts.systemPrompt1
            }
            propPath="nodes.CVGeneratorNode.promptPresets.value.prompts.systemPrompt1"
            saveConfig={saveConfig}
          />
        </Stack>
      ) : (
        <CircularLoader size="2rem" />
      )}
    </Stack>
  );
}
