import { View } from "@react-pdf/renderer";
import { DefaultPage, RedBulletPointSquare, RedSlash } from "./Components";
import { perelynRed } from "./const";
import { ContentRow, HeaderRow, Table } from "./TableComponents";
import { LargeTextPDF, SmallTextPDF } from "./Texts";
import { useContext } from "react";
import { CVContentContext } from "./CVPDF";

export default function Projects() {
  const cvContent = useContext<any>(CVContentContext);
  return (
    <DefaultPage>
      <LargeTextPDF
        style={{
          color: perelynRed,
        }}
      >
        Project experience
      </LargeTextPDF>
      <SmallTextPDF>(Excerpt of relevant projects)</SmallTextPDF>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {cvContent["projects"].map((project, index) => (
          <Table
            key={index}
            style={{
              marginTop: "20px",
            }}
          >
            <HeaderRow label={project["title"]} />
            {Object.entries(project.info).map(([key, value]: any, idx) => {
              let content;
              if (key === "Technologies" && Array.isArray(value)) {
                content = value.map((tech, index) => (
                  <SmallTextPDF key={index}>
                    {tech}
                    {index < value.length - 1 && <RedSlash />}
                  </SmallTextPDF>
                ));
              } else if (key === "Tasks") {
                content = (
                  <View>
                    {value.map((task: any, idx: number) => (
                      <View
                        key={idx}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          maxWidth: "95%",
                        }}
                      >
                        <RedBulletPointSquare
                          style={{
                            marginTop: "5px",
                          }}
                        />
                        {typeof task === "string" ? (
                          <SmallTextPDF>{task}</SmallTextPDF>
                        ) : (
                          <SmallTextPDF>
                            <SmallTextPDF>{task["label"]}: </SmallTextPDF>
                            {task["description"]}
                          </SmallTextPDF>
                        )}
                      </View>
                    ))}
                  </View>
                );
              } else {
                content = <SmallTextPDF>{value}</SmallTextPDF>;
              }
              return (
                <ContentRow
                  key={idx}
                  label={key}
                  content={content}
                  last={idx === Object.entries(project.info).length - 1}
                />
              );
            })}
          </Table>
        ))}
      </View>
    </DefaultPage>
  );
}
