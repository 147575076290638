import { Collapse, Stack } from "@mui/material";
import { useState } from "react";
import TaskAutoUpdateTextField from "../../../../../../common/components/fundamentals/AutoUpdateTextField";
import Dropdown from "../../../../../../common/components/fundamentals/Dropdown";
import Icon from "../../../../../../common/components/fundamentals/Icon";
import { GreyOnHoverStack } from "../../../../../../common/styles/Box.styles";
import {
  defaultTransition,
  iconGap,
  sidebarWidth,
  smallGap,
} from "../../../../../../styles/consts";
import { useTask } from "../../../context/TaskProvider";

export default function ContextCustomization() {
  const { task, saveTask } = useTask();
  const firstValueOption =
    task.configs.marketingText.general.context.value_options[0];
  const [contextType, setContextType] = useState(
    firstValueOption?.name ?? "N/A"
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const textFieldProps = {
    Text: {
      placeholder: "Enter custom context*",
      multiline: true,
      maxRows: 8,
    },
    URL: {
      placeholder: "Enter website URL*",
    },
    File: {
      placeholder: "NOT WORKING YET",
      disabled: true,
    },
  };

  return (
    <Stack>
      <Stack sx={{ gap: smallGap }}>
        <GreyOnHoverStack
          sx={{
            flexDirection: "row",
            gap: iconGap,
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          Context Customization{" "}
          <Icon
            name="ChevronUp"
            sx={{
              transition: defaultTransition,
              transform: isOpen ? "none" : "scaleY(-1)",
            }}
          />
        </GreyOnHoverStack>
        <Collapse in={isOpen}>
          <Stack
            sx={{
              gap: smallGap,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Dropdown
              initialSelected={contextType}
              options={task.configs.marketingText.general.context.value_options.map(
                (o) => {
                  return {
                    name: o.name,
                    type: o.type,
                    value: o.value,
                  };
                }
              )}
              onChange={async (name, type, value) => {
                setContextType(name);
                const t = await saveTask({
                  "configs.marketingText.general.context.value[0]": {
                    name,
                    type,
                    value,
                  },
                  // "configs.marketingMetadata.nodes.MetadataNode.context.value[0]":
                  //   {
                  //     name,
                  //     type,
                  //     value,
                  //   },
                });
                setContextType(
                  t.configs.marketingText.general.context.value?.at(0)?.name ??
                    ""
                );
              }}
            />
            <TaskAutoUpdateTextField
              id="task-context"
              sx={{
                width: "50%",
                minWidth: sidebarWidth,
              }}
              {...textFieldProps[contextType]}
              defaultValue={
                task.configs.marketingText.general.context.value?.at(0)
                  ?.value ?? ""
              }
              propPath={[
                "configs.marketingText.general.context.value[0].value",
                // "configs.marketingMetadata.nodes.MetadataNode.context.value[0].value",
              ]}
              saveFunction={saveTask}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
}
