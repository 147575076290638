import { View } from "@react-pdf/renderer";
import { DefaultPage } from "./Components";
import { HeaderRow, Row, Table } from "./TableComponents";
import { SmallTextPDF } from "./Texts";
import { useContext } from "react";
import { CVContentContext } from "./CVPDF";
type Career = {
  Company: string;
  "Employment period": string;
  Position: string;
};
export default function ProfessionalCareer() {
  const cvContent = useContext<any>(CVContentContext);
  return (
    <DefaultPage>
      <Table>
        <HeaderRow label="Professional career" />
        {cvContent["professionalCareer"].map((career, index) => (
          <Row
            key={index}
            style={{
              flexDirection: "column",
            }}
            last={index === cvContent["professionalCareer"].length - 1}
          >
            {Object.keys(career).map((key, index) => (
              <View
                key={key}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <SmallTextPDF
                  style={{
                    width: "30%",
                  }}
                >
                  {key}:
                </SmallTextPDF>
                <SmallTextPDF>{career[key as keyof Career]}</SmallTextPDF>
              </View>
            ))}
          </Row>
        ))}
      </Table>
    </DefaultPage>
  );
}
