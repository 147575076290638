export const modulesNames = {
  marketingText: "Social Media Assistant",
  dataExtraction: "Data Extraction Assistant",
  alqemi: "Alqemi",
  imageGeneration: "Image Generation Assistant",
  translator: "Translator Assistant",
  competitorAnalysis: "Competitor Analysis Assistant",
  aiRiskAssessment: "AI Risk Assessment Assistant",
  customerSupport: "Customer Support Assistant",
  customerFeedback: "Customer Feedback Assistant",
  onboarding: "Onboarding Assistant",
  corporateSearch: "Corporate Search Assistant",
};

export const overviews = {
  marketingText: {
    heading: modulesNames.marketingText,
    status: "active",
    tags: ["Marketing", "Social media", "Text"],
    description: `Fyndr's social media assistant is an advanced tool designed to simplify and enhance social media content creation. The system efficiently processes and analyzes various input formats, including text documents, website URLs, and PDF files. It extracts key information and transforms it into platform-specific, engaging content for social media platforms such as LinkedIn and X (formerly Twitter). The assistant adapts content to align with a company's brand identity, product focus, or specific marketing objectives. The tool offers multilingual capabilities, enabling businesses to reach diverse global audiences effectively. Furthermore, it generates valuable metadata for improved content discoverability and suggests complementary background images to enhance visual appeal.
This comprehensive and dynamic approach to social media content creation empowers organizations to maintain a consistent and impactful online presence. Fyndr's assistant facilitates efficient content workflows for various social media platforms.`,
    shortDescription:
      "Transforms texts, URLs, and PDFs into brand-aligned, multilingual content for LinkedIn and X, complete with metadata and custom images for enhanced engagement and global reach.",
    capabilities: [
      {
        heading: "Input options",
        options: ["URL", "Text"],
      },
      {
        heading: "Customisation options",
        options: ["Languages", "Tone of voice", "Length of text"],
      },
      {
        heading: "Output",
        options: [
          "Summarised texts",
          "Meta texts for web",
          "Social media texts",
        ],
      },
    ],
  },
  dataExtraction: {
    heading: modulesNames.dataExtraction,
    status: "active",
    tags: ["Entity extraction", "Text", "Research"],
    description: `Allows to extract data from various sources and create reports. Allows to extract data from various sources and create reports. Allows to extract data from various sources and create reports. Allows to extract data from various sources and create reports.`,
    shortDescription:
      "Extracts structured data from PDF files using advanced OCR and robust validation techniques, ensuring high-quality information retrieval from complex or low-quality documents. Can be combined with different assistants to improve workflows.",
    capabilities: [
      {
        heading: "Input options",
        options: ["PDF files"],
      },
      {
        heading: "Customisation options",
        options: ["Languages", "Tone of voice", "Length of text"],
      },
      {
        heading: "Output",
        options: [
          "Summarised texts",
          "Meta texts for web",
          "Social media texts",
        ],
      },
    ],
  },

  alqemi: {
    heading: modulesNames.alqemi,
    status: "active",
    tags: ["Sales automation", "CV", "Research"],
    description: `Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. `,
    shortDescription:
      "Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes. Automates sales processes.",
    capabilities: [
      {
        heading: "Input options",
        options: ["PDF files"],
      },
      {
        heading: "Customisation options",
        options: ["Languages", "Tone of voice", "Length of text"],
      },
      {
        heading: "Output",
        options: [
          "Summarised texts",
          "Meta texts for web",
          "Social media texts",
        ],
      },
    ],
  },

  imageGeneration: {
    heading: modulesNames.imageGeneration,
    status: "inDevelopment",
    tags: ["Image", "Marketing", "Generation", "Social media"],
    description: `Allows to generate images for marketing purposes. Allows to generate images for marketing purposes. Allows to generate images for marketing purposes. Allows to generate images for marketing purposes.`,
    shortDescription:
      "Creates custom background visuals aligned with corporate branding for presentations and articles, ensuring consistent and professional visual communication.",
    capabilities: [
      {
        heading: "Input options",
        options: ["URL", "Text"],
      },
      {
        heading: "Customisation options",
        options: ["Size", "Purpose", "Style"],
      },
      {
        heading: "Output",
        options: [
          "Profile pictures",
          "Backgrounds",
          "Social media post covers",
        ],
      },
    ],
  },

  translator: {
    heading: modulesNames.translator,
    status: "idea",
    tags: ["Translation", "Marketing", "Text", "Speech-to-Text"],
    description: `Allows to translate text to multiple languages and create marketing materials. Allows to translate text to multiple languages and create marketing materials. Allows to translate text to multiple languages and create marketing materials. Allows to translate text to multiple languages and create marketing materials.`,
    shortDescription:
      "Intelligent translation assistant that processes texts, PDFs, and websites, adhering to corporate terminology and incorporating optional validation and research steps to ensure accurate, context-aware translations tailored to your brand's voice.",
    capabilities: [
      {
        heading: "Input options",
        options: ["Text", "PDF files"],
      },
      {
        heading: "Customisation options",
        options: ["Language", "Purpose", "Tone of voice"],
      },
      {
        heading: "Output",
        options: ["Translations", "Social media texts"],
      },
    ],
  },
  competitorAnalysis: {
    heading: modulesNames.competitorAnalysis,
    status: "idea",
    tags: ["Analysis", "Marketing", "Text", "Speech-to-Text"],
    description: `Fyndr's competitor analysis agent is an advanced tool engineered to streamline the process and ensure continuous, up-to-date information. The system efficiently collects, processes, and analyzes extensive publicly available data about competitors. This encompasses monitoring their digital footprint, product portfolios, pricing models, customer feedback, and market stance. Subsequently, Fyndr transforms this data into strategic insights, enabling businesses to identify market opportunities, forecast industry trends, and make well-informed strategic decisions. This comprehensive and dynamic approach to competitor analysis facilitates organizational agility, rapid adaptation to market fluctuations, and identification of avenues for differentiation and expansion.`,
    shortDescription:
      "Automatically collects and analyzes public data about your competitors, providing you with customized reports on their digital presence, products, pricing, customer feedback, and market positioning. ",
    capabilities: [
      {
        heading: "Input options",
        options: ["Text", "URL"],
      },
      {
        heading: "Customisation options",
        options: ["Language", "Purpose", "Tone of voice"],
      },
      {
        heading: "Output",
        options: ["Competitor analysis", "Marketing strategies"],
      },
    ],
  },
  aiRiskAssessment: {
    heading: modulesNames.aiRiskAssessment,
    status: "idea",
    tags: ["Risk", "AI", "Assessment", "Text"],
    description: `Fyndr's AI risk assessment agent is a specialized tool designed to help SMEs navigate the complexities of AI implementation safely and ethically. This intelligent system analyzes the company's AI projects and applications, evaluating them against regulatory standards, ethical guidelines, and best practices in AI governance. It supports identifying potential risks such as bias in AI models, data privacy concerns, or unintended consequences of AI decisions. The agent provides comprehensive risk reports with clear explanations of identified issues and their potential impacts. It also offers actionable recommendations for risk mitigation, including suggestions for improving AI model transparency, enhancing data quality, and ensuring compliance with AI regulations. By helping SMEs implement AI responsibly, Fyndr's AI risk assessment agent promotes trust, mitigates legal and reputational risks, and ensures that AI deployment aligns with the company's values and ethical standards.`,
    shortDescription:
      "Evaluates your company's AI projects, identifying potential risks and providing mitigation strategies by analyzing your AI initiatives against regulatory standards and ethical guidelines.",
    capabilities: [
      {
        heading: "Input options",
        options: ["Text", "URL"],
      },
      {
        heading: "Customisation options",
        options: ["Language", "Purpose", "Tone of voice"],
      },
      {
        heading: "Output",
        options: ["Risk assessment", "Recommendations"],
      },
    ],
  },
  customerSupport: {
    heading: modulesNames.customerSupport,
    status: "idea",
    tags: ["Support", "Customer", "Text"],
    description: `Fyndr's customer support agent is an advanced solution designed to support customer service for SMEs. This intelligent system can handle a wide range of customer inquiries across multiple channels, including chat, email, and social media platforms. It utilizes natural language processing to understand customer intent, providing accurate and contextually relevant responses. The agent can handle routine queries, process simple requests, and escalate complex issues to human agents when necessary. It can be used by the customer support staff to find relevant documents and internal solutions easier. The system provides detailed analytics on customer interactions, helping businesses identify common issues, track customer satisfaction, and optimize their support processes. By offering 24/7 availability, rapid response times, and consistent service quality, Fyndr's customer support agent enhances customer satisfaction, reduces support costs, and frees up human agents to focus on more complex, high-value tasks.`,
    shortDescription:
      "Handles customer inquiries across multiple channels, providing accurate responses and assisting support staff with relevant information. The system uses natural language processing to understand customer intent and manages routine queries.",
    capabilities: [
      {
        heading: "Input options",
        options: ["Text", "URL"],
      },
      {
        heading: "Customisation options",
        options: ["Language", "Purpose", "Tone of voice"],
      },
      {
        heading: "Output",
        options: ["Customer support", "Automated responses"],
      },
    ],
  },
  customerFeedback: {
    heading: modulesNames.customerFeedback,
    status: "idea",
    tags: ["Feedback", "Customer", "Text"],
    description: `Fyndr's customer feedback analysis agent is designed to help companies gain deep insights from customer feedback across multiple channels. This intelligent system collects and analyzes feedback from various sources including surveys, social media, review sites, customer support interactions, and more. Using natural language processing and sentiment analysis, it interprets the emotional tone and key themes in customer comments. The agent provides comprehensive reports highlighting trends, recurring issues, and areas of customer satisfaction or dissatisfaction. It can identify emerging problems before they escalate and flag urgent issues for immediate attention. The system also offers actionable recommendations for improving products, services, and overall customer experience based on the analyzed feedback. It can match requests with already planned backlog items. By transforming raw customer feedback into strategic insights, Fyndr's agent enables you to make data-driven decisions, enhance customer satisfaction, and drive loyalty and retention.`,
    shortDescription:
      "Collects and analyzes feedback from multiple channels, providing insights on trends, issues, and customer satisfaction. It interprets comments using sentiment analysis, identifies emerging problems, and offers actionable recommendations. ",
    capabilities: [
      {
        heading: "Input options",
        options: ["Text", "URL"],
      },
      {
        heading: "Customisation options",
        options: ["Language", "Purpose", "Tone of voice"],
      },
      {
        heading: "Output",
        options: ["Customer feedback", "Insights"],
      },
    ],
  },
  onboarding: {
    heading: modulesNames.onboarding,
    status: "idea",
    tags: ["Onboarding", "Customer", "Text"],
    description: `Fyndr's employee onboarding assistant is a sophisticated solution designed to streamline and enhance the new employee integration process. This AI-driven system creates personalized onboarding experiences for each new hire, taking into account their role, department, and individual needs. It automates administrative tasks such as paperwork completion and system access setup, ensuring a smooth and efficient process. The agent functions as an onboarding buddy tailored to each role, guiding new employees through company policies, culture, and job-specific training. It tracks onboarding progress in real-time, alerting HR and managers to any delays or issues. The system also facilitates social integration by connecting new hires with team members and mentors. By collecting feedback throughout the onboarding journey, it continuously improves the process. Fyndr's onboarding agent helps companies reduce time-to-productivity for new hires, improve employee engagement from day one, and ensure consistent, high-quality onboarding experiences across the organization.`,
    shortDescription:
      "Creates personalized experiences for new hires, automating tasks and guiding them through company policies and training. It tracks progress, facilitates integration, and continuously improves, helping companies reduce time-to-productivity and enhance employee engagement from day one.",
    capabilities: [
      {
        heading: "Input options",
        options: ["Text", "URL"],
      },
      {
        heading: "Customisation options",
        options: ["Language", "Purpose", "Tone of voice"],
      },
      {
        heading: "Output",
        options: ["Onboarding support", "Recommendations"],
      },
    ],
  },
  corporateSearch: {
    heading: modulesNames.corporateSearch,
    status: "idea",
    tags: ["Search", "Corporate", "Text"],
    description: `Fyndr's Corporate Search Agent is a solution designed to ease how employees can find and interact with corporate information. This intelligent system can efficiently indexes and analyzes all types of corporate documents, including contracts, reports, emails, presentations, and internal communications. Using natural language processing and machine learning algorithms, it understands context and intent, enabling users to find relevant information quickly and accurately with simple, conversational queries. The agent provides instant, precise results, highlighting key information and suggesting related documents. It can understand complex relationships between documents, identify trends over time, and even flag potential inconsistencies or compliance issues. By dramatically reducing the time and effort required to find critical information, Fyndr's Search Agent enhances decision-making processes, improves productivity, and ensures that valuable corporate knowledge is always at employees' fingertips.`,
    shortDescription:
      "Streamlines employee access to corporate information through intelligent indexing, natural language processing, and machine learning, allowing natural language queries and optional integration with online search.",
    capabilities: [
      {
        heading: "Input options",
        options: ["Text", "URL"],
      },
      {
        heading: "Customisation options",
        options: ["Language", "Purpose", "Tone of voice"],
      },
      {
        heading: "Output",
        options: ["Company profiles", "Competitor analysis"],
      },
    ],
  },
};
