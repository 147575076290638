import { apiAxios } from "./axios";

const SERVICE_URL = "/services";

class OpenWebuiService {
  async startInstance(): Promise<any> {
    return (await apiAxios.post<any>(SERVICE_URL)).data;
  }

  async stopInstance(): Promise<any> {
    return (await apiAxios.delete<any>(SERVICE_URL)).data;
  }

  async getInstanceStatus(): Promise<any> {
    return (await apiAxios.get<any>(SERVICE_URL)).data;
  }
}

const openWebuiService = new OpenWebuiService();

export default openWebuiService;
