import { Stack } from "@mui/material";
import Loader from "../../../../../common/components/fundamentals/Loader/Loader";
import { usePollTask } from "../../../../../common/hooks/usePollTasks";
import { FullStack } from "../../../../../common/styles/Box.styles";
import {
  borderStyle,
  defaultGap,
  smallerGap,
} from "../../../../../styles/consts";
import {
  capitalizeFirstLetter,
  replaceUnderscoreForWhitespace,
} from "../../../../../utils";
import { useTask } from "../../context/TaskProvider";
import ResultSection, { Result } from "./ResultSection";
import ResultTypeHeading from "./ResultTypeHeading";
import { orderedContentTypes } from "../config/MarketingTextConfigPage";

export default function MarketingTextResultsPage() {
  const { task } = useTask();
  const marketingTextResults = task?.processStatuses.marketingText?.result;
  const marketingTextResultsList: Result[] = [];
  const translationsList = Object.entries(
    marketingTextResults?.translations || {}
  );
  const isOnlyEnglish = translationsList.length === 0;
  if (isOnlyEnglish) {
    const englishContent = marketingTextResults?.articles || {};
    const result = { label: "English", value: {} };
    Object.entries(englishContent).forEach(([key, value]) => {
      result.value[key] = value;
    });
    marketingTextResultsList.push(result);
  } else {
    translationsList.forEach(([lang, content]: any) => {
      const result = { label: lang, value: {} };
      Object.entries(content).forEach(([key, value]) => {
        result.value[key] = value;
      });
      marketingTextResultsList.push(result);
    });
  }

  usePollTask(!Boolean(marketingTextResults));

  return (
    <FullStack sx={{ gap: defaultGap }}>
      {marketingTextResults ? (
        orderedContentTypes
          .filter((result) =>
            Object.keys(marketingTextResults.articles).includes(result)
          )
          .map((result, index) => (
            <Stack
              key={index}
              sx={{
                gap: smallerGap,
              }}
            >
              <ResultTypeHeading
                type={capitalizeFirstLetter(
                  replaceUnderscoreForWhitespace(result)
                )}
              />
              <Stack
                sx={{
                  width: "100%",
                  border: borderStyle,
                }}
              >
                <ResultSection
                  type={"Metadata"}
                  label={capitalizeFirstLetter(
                    replaceUnderscoreForWhitespace(result)
                  )}
                  basePropPath="processStatuses.marketingMetadata.result.metadata.metadata"
                  results={
                    Object.entries(
                      task?.processStatuses.marketingMetadata?.result
                        ?.metadata || {}
                    )
                      .filter(([key, value]) =>
                        Array.isArray(value)
                          ? value.length !== 0
                          : Boolean(value)
                      ) // Filter the list if value is empty
                      .map(([key, value]) => ({
                        label: key,
                        value: Array.isArray(value) ? value.join(", ") : value,
                      })) ?? []
                  }
                  isShort
                />
                <ResultSection
                  type={"Social media"}
                  label={result}
                  basePropPath="processStatuses.marketingText.result.translations"
                  results={marketingTextResultsList.map((resultItem) => ({
                    label: resultItem.label,
                    value: resultItem.value[result],
                  }))}
                  isLast={true}
                  isOnlyEnglish={isOnlyEnglish}
                />
              </Stack>
            </Stack>
          ))
      ) : (
        <Loader label="Still processing..." />
      )}
    </FullStack>
  );
}
