import alqemiPostgresService from "../../api/PostgresService";
import DataLoadingWrapper from "../../common/components/utils/DataLoadingWrapper";
import AlqemiDashboard from "../Modules/Alqemi/Dashboard/AlqemiDashboard";
import AlqemiDashboardProvider from "./context/AlqemiDashboardProvider";

export default function AlqemiDashboardWrapper({
  basePath,
}: {
  basePath: string;
}) {
  const showOnlyTopOffers = localStorage.getItem("alqemiFilter");
  return (
    <DataLoadingWrapper
      ContextProvider={AlqemiDashboardProvider}
      fetchFunctions={[
        () => alqemiPostgresService.getOffers(showOnlyTopOffers),
        () => alqemiPostgresService.getCandidates(),
      ]}
    >
      <AlqemiDashboard basePath={basePath} />
    </DataLoadingWrapper>
  );
}
