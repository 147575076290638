import { apiAxios } from "./axios";

const FEEDBACK_URL = "/feedback";

const getURLWithCreatedTimestamp = (url: string, createdTimestamp: string) =>
  `${url}/${createdTimestamp}`;

class FeedbackService {
  async createFeedback(feedbackProperties: any): Promise<any> {
    return (await apiAxios.post<any>(FEEDBACK_URL, feedbackProperties)).data;
  }

  async updateFeedback(
    createdTimestamp: string,
    feedbackPropertiesToUpdate: any
  ): Promise<any> {
    return (
      await apiAxios.put<any>(
        getURLWithCreatedTimestamp(FEEDBACK_URL, createdTimestamp),
        feedbackPropertiesToUpdate
      )
    ).data;
  }
}

const feedbackService = new FeedbackService();
export default feedbackService;
