import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import OfferAutoUpdateTextField from "../../../../common/components/fundamentals/OfferAutoUpdateTextField";
import { FullStack } from "../../../../common/styles/Box.styles";
import CVPDF from "../../../../cv-generation/CVPDF";
import {
  borderStyle,
  defaultTransition,
  smallerGap,
  smallGap,
} from "../../../../styles/consts";
import { useOffer } from "../context/OfferProvider";
import ButtonSection from "./ButtonSection";
import Candidate from "./Candidate";
import OfferHeading from "./OfferHeading";

import alqemiPostgresService from "../../../../api/PostgresService";
import AlqemiFeedback from "./AlqemiFeedback";

export default function AlqemiMatchPage() {
  const { offer } = useOffer();

  const [displayedMatchIndex, setDisplayedMatchIndex] = useState(0);
  const displayedMatch = offer.matches[displayedMatchIndex];
  const [pdfDownloadBlob, setPdfDownloadBlob] = useState<Blob | null>(null);

  const handleCandidateClick = (index: number) => {
    setDisplayedMatchIndex(index);
  };

  useEffect(() => {
    if (
      displayedMatch.status === "viewed" ||
      displayedMatch.status === "sent"
    ) {
      return;
    } else {
      alqemiPostgresService.updateMatchStatus(
        displayedMatch.consultant_id,
        displayedMatch.job_id,
        "viewed"
      );
    }
  }, [displayedMatch]);

  const emailContent =
    displayedMatch && displayedMatch.email ? displayedMatch.email : "";

  const cvContent =
    displayedMatch && displayedMatch.cv ? displayedMatch.cv : {};

  return (
    <FullStack>
      <OfferHeading
        title={offer.title}
        companyName={offer.ansprechpartner}
        url={offer.url}
      />
      <Stack sx={{ padding: smallGap, gap: smallGap }}>
        <Stack sx={{ flexDirection: "row", gap: smallGap }}>
          {offer.matches.map((match, idx) => (
            <Candidate
              key={idx}
              name={match.candidate.name}
              isDisplayed={idx === displayedMatchIndex}
              onClick={() => handleCandidateClick(idx)}
            />
          ))}
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            gap: smallerGap,
          }}
        >
          <Stack
            sx={{
              transition: defaultTransition,
              border: borderStyle,
              height: "60vh",
              width: "50%",
            }}
          >
            <CVPDF
              cvContent={cvContent}
              setPdfDownloadBlob={setPdfDownloadBlob}
            />
          </Stack>

          <Stack sx={{ width: "50%", gap: smallGap }}>
            <OfferAutoUpdateTextField
              key={displayedMatchIndex}
              id="email-content"
              defaultValue={emailContent}
              propPath={`candidates[${displayedMatchIndex}].emailContent`}
              multiline
              rows={20}
            />

            <ButtonSection
              emailContent={emailContent}
              pdfDownloadBlob={pdfDownloadBlob}
              pdfFileName={`${displayedMatch.candidate.name}-${offer.title}`}
              displayedMatch={displayedMatch}
            />
            <AlqemiFeedback displayedMatchId={displayedMatch.id} />
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ padding: smallGap }}>
        <Typography>Explanation:</Typography>
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {displayedMatch && displayedMatch.explanation
            ? JSON.stringify(displayedMatch.explanation, null, 2)
            : "No explanation available"}
        </pre>
      </Stack>
    </FullStack>
  );
}
