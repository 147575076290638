import { updatePassword } from "@aws-amplify/auth";
import { Box, Collapse, Stack, Typography } from "@mui/material";
import { useState } from "react";
import ReactPasswordChecklist from "react-password-checklist";
import Icon from "../../../common/components/fundamentals/Icon";
import { useSnackbar } from "../../../common/context/SnackbarProvider";
import { useUser } from "../../../common/context/UserProvider";
import { PrimaryButton } from "../../../common/styles/Button.styles";
import { HeadingText } from "../../../common/styles/Text.styles";
import {
  borderStyle,
  defaultGap,
  iconGap,
  perelynPrimary,
  smallerGap,
} from "../../../styles/consts";
import {
  InternalSettingsAreaWithPadding,
  SettingsArea,
  SettingsButtonSection,
} from "../Settings.styles";
import PasswordField from "./PasswordField";

export default function ChangePassword() {
  const { user } = useUser();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnteredPassword, setReEnteredPassword] = useState("");
  const [isValid, setIsValid] = useState(false);
  const { showSnackbar } = useSnackbar();

  const resetFormFields = () => {
    setCurrentPassword("");
    setNewPassword("");
    setReEnteredPassword("");
  };

  const submitChangePassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const currentPassword = data.get("currentPassword")?.toString();
    const newPassword = data.get("newPassword")?.toString();
    const reEnteredPassword = data.get("reEnteredPassword")?.toString();
    if (
      !currentPassword ||
      !newPassword ||
      (!reEnteredPassword && newPassword !== reEnteredPassword)
    ) {
      showSnackbar("You must fill all fields correctly.", true);
      return;
    }
    try {
      await updatePassword({
        oldPassword: currentPassword,
        newPassword: newPassword,
      });
      showSnackbar("Password changed successfully!");
      resetFormFields();
    } catch (error) {
      showSnackbar(
        `An error occured while changing password. Try typing your credentials again.`,
        true
      );
      resetFormFields();
    }
  };

  const passwordFields = [
    {
      description: "Enter your new password here",
      name: "newPassword",
      placeholder: "New password (8-60 characters)",
      value: newPassword,
      setValue: setNewPassword,
    },
    {
      description: "Re-enter new password here",
      name: "reEnteredPassword",
      placeholder: "Repeat new password",
      value: reEnteredPassword,
      setValue: setReEnteredPassword,
    },
  ];
  return (
    <Box id="reset-form" component="form" onSubmit={submitChangePassword}>
      <SettingsArea>
        <InternalSettingsAreaWithPadding
          sx={{
            borderBottom: borderStyle,
          }}
        >
          <Stack sx={{ gap: iconGap }}>
            <Stack sx={{ flexDirection: "row", gap: iconGap }}>
              <HeadingText>Change Password</HeadingText>
              <Icon name="InfoCircle" />
            </Stack>

            {!user.external_user && (
              <PasswordField
                id="currentPassword"
                type="password"
                name={"currentPassword"}
                description={"Enter your current password here"}
                placeholder={"Current password"}
                value={currentPassword}
                setValue={setCurrentPassword}
              />
            )}
          </Stack>
        </InternalSettingsAreaWithPadding>
        <Stack
          sx={{
            padding:
              !!newPassword || !!reEnteredPassword
                ? `${defaultGap} ${smallerGap} `
                : `${defaultGap} ${smallerGap} 3.65rem`,
            gap: smallerGap,
          }}
        >
          {user.external_user ? (
            <Typography>
              In order to change password, you need to login using your
              credentials.
            </Typography>
          ) : (
            passwordFields.map((pf, idx) => (
              <PasswordField
                type="password"
                key={idx}
                name={pf.name}
                description={pf.description}
                placeholder={pf.placeholder}
                value={pf.value}
                setValue={pf.setValue}
              />
            ))
          )}

          <Collapse in={!isValid && (!!newPassword || !!reEnteredPassword)}>
            <ReactPasswordChecklist
              rules={[
                "minLength",
                "number",
                "specialChar",
                "capital",
                "lowercase",
                "match",
              ]}
              minLength={8}
              value={newPassword}
              valueAgain={reEnteredPassword}
              invalidColor={perelynPrimary}
              onChange={(isValid) => {
                setIsValid(isValid);
              }}
            />
          </Collapse>
        </Stack>

        <SettingsButtonSection>
          <PrimaryButton
            id="changePassword"
            disabled={!isValid || currentPassword.length < 8}
            sx={{
              "&&": {
                border: "none ",
                borderLeft: borderStyle,
                "&:disabled": {
                  border: "none",
                  borderLeft: borderStyle,
                },
              },
            }}
            type="submit"
          >
            Save changes
          </PrimaryButton>
        </SettingsButtonSection>
      </SettingsArea>
    </Box>
  );
}
