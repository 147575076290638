// Gaps
export const defaultGap = "2.25rem";
export const smallerGap = "1.5rem";
export const smallGap = "1rem";
export const checkboxGap = "0.75rem";
export const tinyGap = "0.625rem";
export const iconGap = "0.375rem";
export const miniGap = "0.25rem";

// Sizes
export const sidebarWidth = "15rem";
export const boxHeight = "4.5rem";
export const smallTextFieldWidth = "3.75rem";
export const mediumTextFieldHeight = "2.5rem";
export const smallTextFieldHeight = "1.5rem";
export const taskCheckbox = "1.25rem";
export const smallerCheckbox = "1.125rem";
export const iconSize = "0.875rem";
export const reviewsBoxWidth = "18.75rem";

// Borders
export const borderStyle = "1px solid #242424";
export const borderGrey = "1px solid #d8d8d6";
export const borderOrange = "1px solid #ff5950";
export const borderDashed = "1px dashed #242424"; //TODO style dashed border

// Colors
export const perelynPrimary = "#ff5950";
export const perelynGrey = "#d8d8d6";
export const perelynBlack = "#242424";
export const perelynSuccess = "#00DC6E";
export const perelynDarkBlue = "#3F1DAF";
export const perelynLightBlue = "#CEDAFF";
export const perelynWarning = "#FFB800";
export const perelynLightYellow = "#FFDD85";
export const perelynLightGreen = "#E4FAF0";
export const perelynMediumGreen = "#B0F1D2";
export const perelynError = "#FF6432";
export const perelynLightRed = "#FFEBEA";
export const tagBackgroundColor = "#E9E9E9";
export const perelynLightGrey = "#ECECEB";
export const perelynDarkGrey = "#9F9F9C";
export const perelynMediumRed = "#FFB0AB";

// Transitions
export const defaultTransition = "all .4s";

// Scrollbar
export const hideScrollbar = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};
