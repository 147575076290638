import { useState } from "react";
import { useTask } from "../../../pages/Modules/MarketingTextTool/context/TaskProvider";
import { parseIntOrZero } from "../../../utils";
import { CustomNumberInput } from "../../styles/Form.styles";

export default function AutoUpdateNumberInput({ defaultValue, propPath, id }) {
  const [inputValue, setInputValue] = useState(defaultValue);
  const { saveTask } = useTask();

  const onBlur = async (e) => {
    saveTask(
      typeof propPath === "string"
        ? {
            [propPath]: e.target.value,
          }
        : propPath.reduce((acc, path) => {
            acc[path] = e.target.value;
            return acc;
          }, {})
    );
  };
  return (
    <CustomNumberInput
      id={id}
      value={inputValue}
      onChange={(e) => setInputValue(parseIntOrZero(e.target.value))}
      onBlur={onBlur}
    />
  );
}
