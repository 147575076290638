import { useState } from "react";
import { useTask } from "../../../../context/TaskProvider";
import DropdownWithLabel from "../SocialMediaSettings/DropdownWithLabel";

export default function PromptPresetDropdown({
  type,
  zIndexModifier,
  id,
}: {
  type: string;
  zIndexModifier: number;
  id: string;
}) {
  const { task, saveTask } = useTask();
  const nodeName = `Write${type}PostNode`;

  const [promptPrest, setPromptPrest] = useState(
    task.configs.marketingText.nodes[nodeName].promptPresets.value
      ?.presetName ?? ""
  );

  return (
    <DropdownWithLabel
      id={id}
      zIndexModifier={999 - zIndexModifier}
      label={`${type} preset`}
      options={task.configs.marketingText.nodes[
        nodeName
      ].promptPresets.value_options.map((option) => ({
        value: option.presetName,
      }))}
      value={promptPrest}
      onChange={(value) => {
        const newSelectedPromptPreset = task.configs.marketingText.nodes[
          nodeName
        ].promptPresets.value_options.find(
          (option) => option.presetName === value
        );

        if (newSelectedPromptPreset) {
          const { default: _, ...rest } = newSelectedPromptPreset;
          saveTask({
            [`configs.marketingText.nodes.${nodeName}.promptPresets.value`]:
              rest,
          });
        }

        setPromptPrest(value);
      }}
    />
  );
}
