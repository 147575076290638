import { Collapse, Stack, Typography } from "@mui/material";
import { useState } from "react";
import Checkbox from "../../../../../../../common/components/fundamentals/Checkbox";
import { useUser } from "../../../../../../../common/context/UserProvider";
import { DisabledDropdown } from "../../../../../../../common/styles/Box.styles";
import {
  MediumText,
  SmallText,
} from "../../../../../../../common/styles/Text.styles";
import {
  borderGrey,
  borderStyle,
  perelynGrey,
  perelynPrimary,
  smallGap,
} from "../../../../../../../styles/consts";
import { capitalizeFirstLetter, flip } from "../../../../../../../utils";
import ModifyPromptPopup from "../../../../../../Settings/CustomPrompts/ModifyPromptPopup";
import { useTask } from "../../../../context/TaskProvider";
import PopUpBox from "../../../result/PopUpBox";
import ConfigBox from "../../ConfigBox";
import { orderedContentTypes } from "../../MarketingTextConfigPage";
import PromptCustomization from "../../PromptCustomization";
import CustomPromptPresetDropdown from "./CustomPromptPresetDropdown";
import PromptPresetDropdown from "./PromptPresetDropdown";

export default function PromptPresetSettings() {
  const { task, saveTask } = useTask();
  const { user } = useUser();
  const [iC, setIC] = useState(task.userPrompts?.marketingText?.use ?? false);

  const onCheckboxChange = async () => {
    flip(setIC)();
    const updatedTask = await saveTask({
      "userPrompts.marketingText.use": !task.userPrompts.marketingText?.use,
    });
    setIC(updatedTask.userPrompts.marketingText?.use ?? false);
  };

  return (
    <ConfigBox>
      <Stack>
        <MediumText>Prompt Preset settings</MediumText>
      </Stack>
      <Stack
        sx={{
          gap: smallGap,
        }}
      >
        {iC
          ? orderedContentTypes
              .filter((type) =>
                task.configs.marketingText.general.contentType.value?.includes(
                  type
                )
              )
              .map((type, idx) => (
                <Stack
                  key={idx}
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>{capitalizeFirstLetter(type)} preset</Typography>
                  <DisabledDropdown
                    sx={{ "&&": { border: borderGrey, color: perelynGrey } }}
                  >
                    {capitalizeFirstLetter(
                      task.configs.marketingText.nodes.WriteLinkedinPostNode
                        ?.promptPresets?.value?.presetName ?? "N/A"
                    )}
                  </DisabledDropdown>
                </Stack>
              ))
          : orderedContentTypes
              .filter((type) =>
                task.configs.marketingText.general.contentType.value?.includes(
                  type
                )
              )
              .map((type, idx) => (
                <PromptPresetDropdown
                  id={`${type}-prompt-preset`}
                  key={idx}
                  type={capitalizeFirstLetter(type)}
                  zIndexModifier={idx * 100}
                />
              ))}

        {user.prompts && Object.keys(user.prompts).length > 0 ? (
          <>
            <Stack sx={{ flexDirection: "row", gap: smallGap }}>
              <Checkbox
                iconName="CheckboxX"
                sx={{
                  border: borderStyle,
                }}
                isChecked={iC}
                onChange={onCheckboxChange}
              />
              Use my own custom prompt
            </Stack>
            <Collapse in={iC}>
              <CustomPromptPresetDropdown />
            </Collapse>
            <Collapse in={iC}>
              <Stack
                sx={{ flexDirection: "row", justifyContent: "space-between" }}
              >
                <PopUpBox
                  button={
                    <SmallText
                      sx={{
                        textDecoration: "underline",
                        cursor: "crosshair",
                        ":hover": {
                          color: perelynPrimary,
                        },
                      }}
                    >
                      Create new prompt
                    </SmallText>
                  }
                  heading={"Create new prompt"}
                >
                  {(setIsOpen) => <ModifyPromptPopup setIsOpen={setIsOpen} />}
                </PopUpBox>
                <PromptCustomization />
              </Stack>
            </Collapse>
          </>
        ) : (
          <Stack>
            <SmallText>You can use your own custom prompts</SmallText>
            <PopUpBox
              button={
                <SmallText
                  sx={{
                    textDecoration: "underline",
                    cursor: "crosshair",
                    ":hover": {
                      color: perelynPrimary,
                    },
                  }}
                >
                  Create one
                </SmallText>
              }
              heading={"Create new prompt"}
            >
              {(setIsOpen) => <ModifyPromptPopup setIsOpen={setIsOpen} />}
            </PopUpBox>
          </Stack>
        )}
      </Stack>
    </ConfigBox>
  );
}
