import { Stack } from "@mui/material";
import { FullStack } from "../../common/styles/Box.styles";
import { HeadingText } from "../../common/styles/Text.styles";
import { defaultGap } from "../../styles/consts";
import ChangelogBox from "./ChangelogBox";
import ChangelogPost from "./ChangelogPost";
import ChangelogStatus from "./ChangelogStatus";

const posts = [
  {
    date: "1 December 2024",
    statuses: [
      {
        type: "new",
        log: "Fyndr WebUI added!",
      },
      {
        type: "new",
        log: "Customize and save your own parameters in entity extraction tool",
      },
      {
        type: "improvement",
        log: "Settings split per usecase",
      },
      {
        type: "new",
        log: "File as input source implemented in marketing text tool",
      },
      {
        type: "new",
        log: "PDF preview added to the entity extraction tool",
      },
      {
        type: "improvement",
        log: "Config structure improved",
      },
    ],
  },

  {
    date: "30 September 2024",
    statuses: [
      {
        type: "new",
        log: "EvalLM module added",
      },
      {
        type: "new",
        log: "AI generated music player added",
      },
      {
        type: "improvement",
        log: "Customize and save your own prompts",
      },
    ],
  },
  {
    date: "3 September 2024",
    statuses: [
      {
        type: "new",
        log: "Arena function added to the platform",
      },
      {
        type: "new",
        log: "Self-registration possible",
      },
      {
        type: "new",
        log: "Assistants overview page added",
      },
      {
        type: "new",
        log: "User activation managment added",
      },
      {
        type: "improvement",
        log: "Filtering added to the modules dashboard",
      },
    ],
  },
  {
    date: "12 August 2024",
    statuses: [
      {
        type: "new",
        log: "PDF Data Extraction module added",
      },
      {
        type: "new",
        log: "Change password feature added",
      },
      {
        type: "new",
        log: "Forgot password feature added",
      },
      {
        type: "improvement",
        log: "View used prompt in results section",
      },
    ],
  },
  {
    date: "31 July 2024",
    statuses: [
      {
        type: "new",
        log: "Added comment field in task creation proccess",
      },
      {
        type: "new",
        log: "E2E tests implemented",
      },
      {
        type: "improvement",
        log: "Results page UI improved",
      },
    ],
  },
  {
    date: "19 July 2024",
    statuses: [
      { type: "new", log: "Added user settings page" },
      { type: "new", log: "Added changelog page" },
      { type: "new", log: "Added GitHub deployment pipelines" },

      {
        type: "improvement",
        log: "Fully functional tables with sorting and navigation",
      },
      {
        type: "bugfix",
        log: "Fixed polling Task status not updating",
      },
    ],
  },
  {
    date: "22 July 2024",
    statuses: [
      {
        type: "new",
        log: "Context customization enabled in task creation proccess",
      },
      {
        type: "new",
        log: '"Not implemented yet" information added',
      },
      {
        type: "new",
        log: "Marketing prompt metadata extraction added",
      },

      {
        type: "improvement",
        log: "UI experience improved in tasks dashboard",
      },
    ],
  },
];

export default function ChangelogPage() {
  const sortedByDatePosts = posts.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );
  return (
    <FullStack>
      <Stack
        sx={{
          padding: `${defaultGap} ${defaultGap} 0 ${defaultGap}`,
          justifyContent: "center",
        }}
      >
        <HeadingText>
          Find all the new features, improvements and bugfixes here.
        </HeadingText>
      </Stack>
      <Stack
        sx={{
          padding: defaultGap,
          boxSizing: "border-box",
        }}
      >
        <ChangelogBox>
          {sortedByDatePosts.map((post, index) => (
            <ChangelogPost key={index} date={post.date}>
              {post.statuses
                .sort((a, b) => (a.type > b.type ? -1 : 1))
                .map((status, idx) => (
                  <ChangelogStatus
                    key={idx}
                    type={status.type as "new" | "improvement" | "bugfix"}
                    log={status.log}
                  />
                ))}
            </ChangelogPost>
          ))}
        </ChangelogBox>
      </Stack>
    </FullStack>
  );
}
