import { Stack } from "@mui/material";

import { Route, Routes } from "react-router-dom";
import userService from "../../api/UserService";
import { routes } from "../../routes";
import { boxHeight } from "../../styles/consts";
import { useLayoutConfig } from "../context/LayoutConfigProvider";
import UserProvider from "../context/UserProvider";
import useData from "../hooks/useData";
import { User } from "../models/Models";
import { FullStack } from "../styles/Box.styles";
import ErrorBoundary from "./ErrorBoundary";
import Navbar from "./layout/Navbar";
import PrimarySidebar from "./layout/PrimarySidebar";
import SecondarySidebar from "./layout/SecondarySidebar";

export default function LoggedInView() {
  const { data: user } = useData<User>(userService.getUser);
  const { layoutConfig } = useLayoutConfig();
  return user ? (
    <UserProvider initialUser={user}>
      <ErrorBoundary showBackButton={true}>
        <Navbar />

        <Stack
          sx={{
            flexDirection: "row",
            width: "100%",
            height: `calc(100% - ${boxHeight})`,
            minHeight: `calc(100% - ${boxHeight})`,
            maxHeight: `calc(100% - ${boxHeight})`,
          }}
        >
          <PrimarySidebar />
          {layoutConfig.secondarySidebarVisible && <SecondarySidebar />}
          <FullStack
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.component}
                />
              ))}
            </Routes>
          </FullStack>
        </Stack>
      </ErrorBoundary>
    </UserProvider>
  ) : (
    <></>
  );
}
