import { Stack, Typography } from "@mui/material";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import { useEffect, useState, createContext } from "react";
import Doc from "./Doc";
import { CVTemplateFull } from "../common/models/Models";

export const CVContentContext = createContext(null);

export default function CVPDF({
  cvContent,
  setPdfDownloadBlob,
}: {
  cvContent: CVTemplateFull;
  setPdfDownloadBlob: (blob: Blob) => void;
}) {
  const [document, setDocument] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const getRenderBlob = async () => {
      const doc = <Doc cvContent={cvContent} />;
      setDocument(doc);
      const blob = await pdf(doc).toBlob();
      setPdfDownloadBlob(blob);
    };
    getRenderBlob();
  }, [cvContent, setPdfDownloadBlob]);

  return (
    <Stack
      sx={{
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        height: "100%",
      }}
    >
      {document && cvContent.jobTitle && cvContent.fullName ? (
        <PDFViewer
          style={{
            height: "100%",
          }}
          showToolbar={false}
        >
          {cvContent && document}
        </PDFViewer>
      ) : (
        <Stack
          sx={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Error displaying CV</Typography>
        </Stack>
      )}
    </Stack>
  );
}
