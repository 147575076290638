import { Collapse, Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import arenaService from "../../../../api/ArenaService";
import { useLayoutConfig } from "../../../../common/context/LayoutConfigProvider";
import { useLoading } from "../../../../common/context/LoadingProvider";
import {
  OutlinedButton,
  PrimaryButton,
} from "../../../../common/styles/Button.styles";
import { basePath, MARKETING_TEXT } from "../../../../routes";
import {
  borderStyle,
  smallerGap,
  smallGap,
  tinyGap,
} from "../../../../styles/consts";
import ArenaAutoUpdateTextField from "./ArenaAutoUpdateTextField";
import ArenaOutputResults from "./ArenaOutputResults";
import { useArena } from "./ArenaProvider";

export default function ArenaOutputSection({
  contentType,
  language,
}: {
  contentType: string;
  language: string;
}) {
  const navigate = useNavigate();
  const [iCA, setICA] = useState(false);
  const [iCB, setICB] = useState(false);
  const { config } = useArena();
  const { withLoading } = useLoading();
  const { moduleName } = useLayoutConfig();

  const handleTryAgain = () => {
    withLoading(async () => {
      const arena = await arenaService.createArena(moduleName);

      navigate(`${basePath(moduleName)}/arena/${arena.createdTimestamp}`);
      window.location.reload();
    });
  };

  const getResultValue = (version) => {
    const result = config?.processStatuses?.[`marketingText${version}`]?.result;
    if (result?.translations) {
      return result.translations[language]?.[contentType] ?? "";
    }
    return result?.articles?.[contentType] ?? "";
  };

  return (
    <Stack sx={{ gap: smallerGap }}>
      <Stack
        sx={{
          gap: smallGap,
          border: borderStyle,
          padding: smallerGap,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            gap: smallerGap,
          }}
        >
          <ArenaOutputResults
            version="A"
            value={getResultValue("A")}
            iC={iCA}
            setIC={setICA}
            setOtherIC={setICB}
          />
          <ArenaOutputResults
            version="B"
            value={getResultValue("B")}
            iC={iCB}
            setIC={setICB}
            setOtherIC={setICA}
          />
        </Stack>
        <Collapse in={iCA || iCB}>
          <ArenaAutoUpdateTextField
            id="feedback"
            propPath={"feedback.comment"}
            defaultValue={config.feedback.comment}
            fullWidth
            multiline
            maxRows={5}
            placeholder={"Explain your vote in more detail"}
          />
        </Collapse>
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "right",
          gap: tinyGap,
        }}
      >
        <OutlinedButton onClick={() => navigate(basePath(MARKETING_TEXT))}>
          Quit
        </OutlinedButton>
        <PrimaryButton id="try-again" onClick={handleTryAgain}>
          Try again
        </PrimaryButton>
      </Stack>
    </Stack>
  );
}
