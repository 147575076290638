import { createContext, useContext, useState } from "react";

import offerService from "../../../../api/OfferService";
import { Offer } from "../../../../common/models/Models";

type OfferState = {
  offer: Offer;
  setOffer: (offer: Offer) => void;
  saveOffer: (offer: any) => Promise<Offer>;
};

export const useOffer = (): OfferState => {
  const offerState = useContext(OfferContext);
  if (!offerState) {
    throw new Error("useOffer must be used within a OfferProvider");
  }
  return offerState;
};

const OfferContext = createContext<OfferState | null>(null);

type OfferProviderProps = {
  children: JSX.Element;
  preFetchedValues: [Offer];
};

const OfferProvider = ({ children, preFetchedValues }: OfferProviderProps) => {
  const [offer, setOffer] = useState<Offer>(preFetchedValues[0]);

  const saveOffer = async (offerParams: any) => {
    const t = await offerService.updateOffer(
      offer.url,
      offer.eingestellt,
      offerParams
    );
    setOffer(t);
    return t;
  };

  return (
    <OfferContext.Provider
      value={{
        offer,
        setOffer,
        saveOffer,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};

export default OfferProvider;
