import { apiAxios } from "./axios";

class EvallmService {
  async getEvallm(): Promise<string> {
    return (await apiAxios.get<string>("/evallm")).data;
  }
}

const evallmService = new EvallmService();

export default evallmService;
