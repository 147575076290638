import { postgresApiAxios } from "./axios";

class AlqemiPostgresService {
  async getOffers(filter?: string | null): Promise<any> {
    return (
      await postgresApiAxios.get<any>(
        `/offers${filter ? "?filter=" + filter : ""}`
      )
    ).data;
  }

  async getOffer(id: string): Promise<any> {
    return (await postgresApiAxios.get<any>("/offer/" + id)).data;
  }

  async getCandidates(): Promise<any> {
    return (await postgresApiAxios.get<any>("/candidates")).data;
  }

  async updateCandidateStatus(
    id: string,
    status: string,
    fromDate?: string
  ): Promise<any> {
    const payload: any = { id, status };
    if (fromDate) {
      payload.from_date = fromDate;
    }
    return (
      await postgresApiAxios.post<any>("/update-candidate-status", payload)
    ).data;
  }

  async updateMatchStatus(
    consultantId: string,
    jobId: string,
    status: string
  ): Promise<any> {
    const payload: any = { consultantId, jobId, status };
    return (await postgresApiAxios.post<any>("/update-match-status", payload))
      .data;
  }
}

const alqemiPostgresService = new AlqemiPostgresService();

export default alqemiPostgresService;
