import { useState } from "react";
import { useTask } from "../../../../context/TaskProvider";
import DropdownWithLabel from "../SocialMediaSettings/DropdownWithLabel";

export default function TargetGroupDropdown({
  type,
  zIndexModifier,
  id,
}: {
  type: string;
  zIndexModifier: number;
  id: string;
}) {
  const { task, saveTask } = useTask();
  const nodeName = `Write${type}PostNode`;

  const targetGroupOption = task.configs.marketingText.nodes[
    nodeName
  ].targetGroup.value_options.find(
    (option) =>
      option.value ===
      task.configs.marketingText.nodes[nodeName].targetGroup.value
  );

  const [targetGroup, setTargetGroup] = useState(
    targetGroupOption ? targetGroupOption.name : "N/A"
  );

  return (
    <DropdownWithLabel
      id={id}
      zIndexModifier={999 - zIndexModifier}
      label={`${type}`}
      options={task.configs.marketingText.nodes[
        nodeName
      ].targetGroup.value_options.map((option) => ({
        name: option.name,
        value: option.value,
      }))}
      value={targetGroup || ""}
      onChange={(value) => {
        const newSelectedTargetGroup = task.configs.marketingText.nodes[
          nodeName
        ].targetGroup.value_options.find((option) => option.value === value);

        if (newSelectedTargetGroup) {
          const { value } = newSelectedTargetGroup;
          saveTask({
            [`configs.marketingText.nodes.${nodeName}.targetGroup.value`]:
              value,
          });
        }

        setTargetGroup(value);
      }}
    />
  );
}
