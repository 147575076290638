import { Stack } from "@mui/material";
import { borderStyle, checkboxGap, smallGap } from "../../../styles/consts";
import { FullStack, GreyOnHoverStack } from "../../styles/Box.styles";
import { OrangeButton, OutlinedButton } from "../../styles/Button.styles";
import { HeadingText } from "../../styles/Text.styles";
import { BiggerIcon } from "../fundamentals/Icon";

export default function CustomPopUp({
  isPopUpOpen,
  content,
  setPopUpOpen,
  heading,
  button1 = "Cancel",
  button2,
  action,
}: {
  isPopUpOpen: boolean;
  content: string;
  setPopUpOpen: (value: boolean) => void;
  heading: string;
  button1?: string;
  button2: string;
  action: () => void;
}) {
  const handleClose = () => {
    setPopUpOpen(false);
  };

  const handleActionAndClose = () => {
    action();
    handleClose();
  };

  const popUpBackgroundClass = "popUpBackground";

  return (
    <FullStack
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "9999",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        justifyContent: "center",
        alignItems: "center",
        cursor: "crosshair",
        display: isPopUpOpen ? "flex" : "none",
      }}
      className={popUpBackgroundClass}
      onClick={(e: any) => {
        if (e.target.classList.contains(popUpBackgroundClass)) {
          handleClose();
        }
      }}
    >
      <Stack
        sx={{
          minWidth: "35%",
          maxWidth: "40%",
          minHeight: "25%",
          backgroundColor: "white",
          zIndex: "99999",
          cursor: "default",
          border: borderStyle,
        }}
      >
        <Stack
          sx={{
            paddingLeft: checkboxGap,
            borderBottom: borderStyle,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <HeadingText
            sx={{
              cursor: "text",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {heading}
          </HeadingText>
          <GreyOnHoverStack>
            <BiggerIcon
              sx={{
                height: "3rem",
                width: "3rem",
              }}
              name="BigXMark"
              onClick={handleClose}
            />
          </GreyOnHoverStack>
        </Stack>
        <Stack
          sx={{
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          <Stack sx={{ padding: smallGap }}>{content}</Stack>
          <Stack sx={{ flexDirection: "row", width: "100%" }}>
            <OutlinedButton
              onClick={handleClose}
              sx={{
                "&&": {
                  width: "50%",
                  borderLeft: "none",
                  borderBottom: "none",
                },
              }}
            >
              {button1}
            </OutlinedButton>
            <OrangeButton
              onClick={handleActionAndClose}
              sx={{ width: "50%", borderTop: borderStyle }}
            >
              {button2}
            </OrangeButton>
          </Stack>
        </Stack>
      </Stack>
    </FullStack>
  );
}
