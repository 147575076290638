import { Amplify } from "aws-amplify";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import Layout from "./common/components/layout/Layout";
import LoadingProvider from "./common/context/LoadingProvider";
import translations from "./translations.json";
import { addFederatedToUrl } from "./utils";

const callback_url_local = "http://localhost:3000";
const callback_url_dev = "https://d11r7krqa0nqey.cloudfront.net";
const callback_url_stage = "https://d3cswhcjrmq6s1.cloudfront.net";
const callback_url_prod = "https://fyndr.perelyn.com";

const DOMAIN_ENV =
  process.env.REACT_APP_ENV === "dev" ? "jku" : process.env.REACT_APP_ENV;

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USERPOOLID || "",
      userPoolClientId: process.env.REACT_APP_USERPOOLAPPCLIENTID || "",
      loginWith: {
        oauth: {
          domain: DOMAIN_ENV + "-domain.auth.eu-central-1.amazoncognito.com",
          scopes: ["email", "openid", "profile"],
          redirectSignIn: [
            addFederatedToUrl(callback_url_local),
            addFederatedToUrl(callback_url_dev),
            addFederatedToUrl(callback_url_stage),
            addFederatedToUrl(callback_url_prod),
          ],
          redirectSignOut: [
            callback_url_local,
            callback_url_dev,
            callback_url_stage,
            callback_url_prod,
          ],
          responseType: "code",
          providers: [{ custom: "AzureAD" }],
        },
      },
    },
  },
});

export default function App() {
  i18next.use(initReactI18next).init({
    resources: translations,
    lng: localStorage.getItem("language") || "de",
    interpolation: {
      escapeValue: false,
    },
  });
  return (
    <BrowserRouter>
      <LoadingProvider>
        <Layout />
      </LoadingProvider>
    </BrowserRouter>
  );
}
