import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EvallmService from "../../../api/EvallmService";
import openWebuiService from "../../../api/OpenWebuiService";
import {
  borderStyle,
  boxHeight,
  defaultGap,
  iconGap,
  miniGap,
  perelynGrey,
  perelynPrimary,
  smallGap,
  smallerGap,
  tinyGap,
} from "../../../styles/consts";
import {
  capitalizeFirstLetter,
  getFormattedName,
  getUserInitials,
  splitMail,
} from "../../../utils";
import { usePopUp } from "../../context/PopUpProvider";
import { useUser } from "../../context/UserProvider";
import { OrangeOnHoverStack } from "../../styles/Box.styles";
import { OutlinedButton, PrimaryButton } from "../../styles/Button.styles";
import {
  MainSidebarComponent,
  MiddleSidebarComponent,
} from "../../styles/Sidebar.styles";
import { HeadingText } from "../../styles/Text.styles";
import Icon from "../fundamentals/Icon";
import CircularLoader from "../fundamentals/Loader/CircularLoader";
import Logout from "../user/Logout";

export default function PrimarySidebar() {
  const { user } = useUser();
  const { showPopUpWindow } = usePopUp();
  const navigate = useNavigate();
  const location = useLocation();
  const [apiKey, setApiKey] = useState("");
  const [instanceStatus, setInstanceStatus] = useState("");

  useEffect(() => {
    async function fetchApiKey() {
      const key = await EvallmService.getEvallm();
      setApiKey(key);
    }
    fetchApiKey();
  }, []);

  useEffect(() => {
    async function fetchInstanceStatus() {
      const status = await openWebuiService.getInstanceStatus();
      setInstanceStatus(status);
    }

    fetchInstanceStatus();

    const interval = setInterval(() => {
      if (instanceStatus !== "running" && instanceStatus !== "stopped") {
        fetchInstanceStatus();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [instanceStatus]);

  const handleUserSettings = () => {
    navigate("/user-settings");
  };
  const handleChangelog = () => {
    navigate("/changelog");
  };

  const handleAdminSettings = async () => {
    navigate("/admin-settings");
  };

  const handleGoToEvalLM = async () => {
    if (!apiKey) {
      const key = await EvallmService.getEvallm();
      setApiKey(key);
    }
    window.open(`https://d39rdckp9uuo1v.cloudfront.net/?apiKey=${apiKey}`);
  };

  const handleOpenWebUI = async () => {
    navigate("/open-webui");
  };

  const handleStartStopInstance = async () => {
    if (instanceStatus === "running") {
      await openWebuiService.stopInstance();
      setInstanceStatus("stopping");
    } else if (instanceStatus === "stopped") {
      await openWebuiService.startInstance();
      setInstanceStatus("pending");
    }
  };

  const handleStartStopInstanceClick = () => {
    showPopUpWindow(
      () => handleStartStopInstance(),
      `${
        instanceStatus === "stopped"
          ? "Start"
          : instanceStatus === "running" && "Stop"
      } WebUI instance?`,
      `Are you sure you want to ${
        instanceStatus === "stopped"
          ? "start"
          : instanceStatus === "running" && "stop"
      } this instance?`,
      "Cancel",
      "Continue"
    );
  };

  return (
    <MainSidebarComponent sx={{ borderRight: borderStyle }}>
      <MiddleSidebarComponent>
        <HeadingText>Main menu</HeadingText>
        <Stack sx={{ gap: smallerGap }}>
          <Stack gap={tinyGap}>
            <Typography>Started tasks</Typography>

            {/* <Stack flexDirection="row" alignItems="center" gap={iconGap}>
              <Icon name="RedFile" />
              <OrangeSidebarStack>Task 1</OrangeSidebarStack>
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={iconGap}>
              <Icon name="RedFile" />
              <OrangeSidebarStack>Task 2</OrangeSidebarStack>
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={iconGap}>
              <Icon name="RedFile" />
              <OrangeSidebarStack>Task 3</OrangeSidebarStack>
            </Stack> */}
            <Typography sx={{ color: perelynGrey }}>See all</Typography>
          </Stack>
          <Stack gap={tinyGap}>
            <Typography>Favorite tools</Typography>
            <Stack
              sx={{
                gap: tinyGap,
              }}
            >
              {/* <Typography>Tool 1</Typography>
              <Typography>Tool 2</Typography>
              <GreySidebarStack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: iconGap,
                }}
              >
                <Icon name="Folder" />
                Folder open
                <Icon name="ChevronUp" />
                <Icon name="DragComponent" />
              </GreySidebarStack>
              <Stack gap={tinyGap}>
                <Typography>Tool 3</Typography>
                <Typography>Tool 4</Typography>
              </Stack> */}
              <Typography sx={{ color: perelynGrey }}>+Add folder</Typography>
            </Stack>
          </Stack>

          <Typography>Submit Idea</Typography>
          {location.pathname === "/open-webui" && user.profile === "admin" && (
            <Stack
              sx={{
                gap: tinyGap,
              }}
            >
              <Stack sx={{ flexDirection: "row", gap: iconGap }}>
                <Typography>Instance is </Typography>
                <Typography sx={{ color: perelynPrimary }}>
                  {capitalizeFirstLetter(instanceStatus)}
                </Typography>
              </Stack>

              {instanceStatus === "running" ? (
                <OutlinedButton onClick={handleStartStopInstanceClick}>
                  Stop EC2
                </OutlinedButton>
              ) : instanceStatus === "stopped" ? (
                <PrimaryButton onClick={handleStartStopInstanceClick}>
                  Start EC2
                </PrimaryButton>
              ) : (
                <Stack
                  sx={{
                    width: "5.05rem",
                    border: borderStyle,
                    padding: iconGap,
                  }}
                >
                  <CircularLoader size="1.4rem" />
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
        <Stack>
          <Stack sx={{ gap: miniGap, padding: `${defaultGap} 0` }}>
            <OrangeOnHoverStack id="open-webui" onClick={handleOpenWebUI}>
              Go to Fyndr WebUI
            </OrangeOnHoverStack>
            <OrangeOnHoverStack id="evallm" onClick={handleGoToEvalLM}>
              Go to EvalLM
            </OrangeOnHoverStack>
            <Stack>Feedback</Stack>
            <OrangeOnHoverStack id="changelog" onClick={handleChangelog}>
              Changelog
            </OrangeOnHoverStack>
          </Stack>
          <Stack sx={{ gap: miniGap }}>
            <OrangeOnHoverStack id="settings" onClick={handleUserSettings}>
              <Icon name="Settings" />
              Settings
            </OrangeOnHoverStack>
            {user.profile === "admin" && (
              <OrangeOnHoverStack
                id="admin-settings"
                onClick={handleAdminSettings}
              >
                <Icon name="Settings" />
                Admin Settings
              </OrangeOnHoverStack>
            )}
          </Stack>
        </Stack>
      </MiddleSidebarComponent>

      <Stack
        sx={{
          borderTop: borderStyle,
          flexDirection: "row",
          justifyContent: "space-between",
          height: boxHeight,
          boxSizing: "border-box",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            gap: "0.75rem",
            boxSizing: "border-box",
            padding: `0.75rem 0rem 0.75rem ${smallGap}`,
          }}
        >
          <Stack
            sx={{
              height: "2.5rem",
              width: "2.5rem",
              border: `1px solid ${perelynPrimary}`,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: perelynPrimary, fontSize: smallGap }}>
              {getUserInitials(user)}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontSize: "1.125rem" }}>
              {getFormattedName(user.name)}
            </Typography>
            <Typography>{splitMail(user.email)}</Typography>
          </Stack>
        </Stack>
        <Logout />
      </Stack>
    </MainSidebarComponent>
  );
}
