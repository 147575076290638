import { Stack } from "@mui/material";
import { MediumText } from "../../../../../../../common/styles/Text.styles";
import { smallGap } from "../../../../../../../styles/consts";
import { capitalizeFirstLetter } from "../../../../../../../utils";
import { useTask } from "../../../../context/TaskProvider";
import ConfigBox from "../../ConfigBox";
import TargetGroupDropdown from "./TargetGroupDropdown";
import { orderedContentTypes } from "../../MarketingTextConfigPage";

export default function TargetGroupSettings() {
  const { task } = useTask();

  return (
    <ConfigBox>
      <Stack>
        <MediumText>Target Group settings</MediumText>
      </Stack>
      <Stack
        sx={{
          gap: smallGap,
        }}
      >
        {orderedContentTypes
          .filter((type) =>
            task.configs.marketingText.general.contentType.value?.includes(type)
          )
          .map((type, idx) => (
            <TargetGroupDropdown
              id={`${type}-target-group`}
              key={idx}
              type={capitalizeFirstLetter(type)}
              zIndexModifier={idx * 100}
            />
          ))}
      </Stack>
    </ConfigBox>
  );
}
