import { Stack } from "@mui/material";
import { useState } from "react";
import { useTask } from "../../../pages/Modules/MarketingTextTool/context/TaskProvider";
import {
  borderStyle,
  defaultGap,
  defaultTransition,
  perelynBlack,
  perelynPrimary,
} from "../../../styles/consts";
import TaskAutoUpdateTextField from "../fundamentals/TaskAutoUpdateTextField";

export default function TaskNameField() {
  const { task } = useTask();
  const [textColor, setTextColor] = useState(perelynBlack);

  const handleFocus = () => {
    setTextColor(perelynPrimary);
  };

  const handleBlur = () => {
    setTextColor(perelynBlack);
  };

  return (
    <Stack
      sx={{
        borderBottom: borderStyle,
        width: "100%",
        padding: defaultGap,
        boxSizing: "border-box",
      }}
    >
      <TaskAutoUpdateTextField
        id="task-name"
        defaultValue={task.name}
        propPath={"name"}
        sx={{
          fontSize: "1.875rem",
          "& fieldset": {
            border: "none !important",
          },
          "& .MuiInputBase-input::placeholder": {
            color: perelynPrimary,
          },
        }}
        inputProps={{
          style: {
            transition: defaultTransition,
            fontSize: "1.875rem",
            color: textColor,
          },
          onFocus: handleFocus,
          onBlur: handleBlur,
        }}
        InputLabelProps={{
          style: {
            fontSize: "1.875rem",
          },
        }}
        placeholder={"Enter task name*"}
      />
    </Stack>
  );
}
