import { Offer, PartialOfferParams } from "../common/models/Models";
import { apiAxios } from "./axios";

const OFFER_ROUTE = "/offer";
const OFFERS_ROUTE = "/offers";

const getURLWithCreatedTimestampAndURLQueryParam = (
  taskRoute: string,
  url: string,
  createdTimestamp: string
) => `${taskRoute}/${createdTimestamp}?url=${encodeURIComponent(url)}`;

class OfferService {
  async getOffer(url: string, createdTimestamp: string): Promise<Offer> {
    const requestUrl = getURLWithCreatedTimestampAndURLQueryParam(
      OFFER_ROUTE,
      url,
      createdTimestamp
    );
    return (await apiAxios.get<Offer>(requestUrl)).data;
  }

  async getOffers(): Promise<Offer[]> {
    return (await apiAxios.get<Offer[]>(OFFERS_ROUTE)).data;
  }

  async updateOffer(
    url: string,
    createdTimestamp: string,
    offerPropertiesToUpdate: PartialOfferParams
  ): Promise<Offer> {
    return (
      await apiAxios.put<Offer>(
        getURLWithCreatedTimestampAndURLQueryParam(
          OFFER_ROUTE,
          url,
          createdTimestamp
        ),
        offerPropertiesToUpdate
      )
    ).data;
  }

  async deleteOffer(url: string, createdTimestamp: string): Promise<void> {
    return (
      await apiAxios.delete<any>(
        getURLWithCreatedTimestampAndURLQueryParam(
          OFFER_ROUTE,
          url,
          createdTimestamp
        )
      )
    ).data;
  }

  async deleteOffers(
    offers: { url: string; createdTimestamp: string }[]
  ): Promise<void> {
    return (
      await apiAxios.post<any>(OFFERS_ROUTE, {
        offers: offers.map((offer) => ({
          url: offer.url,
          createdTimestamp: offer.createdTimestamp,
        })),
      })
    ).data;
  }
}

const offerService = new OfferService();
export default offerService;
