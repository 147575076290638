import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import openWebuiService from "../api/OpenWebuiService";
import Loader from "../common/components/fundamentals/Loader/Loader";
import { FullStack } from "../common/styles/Box.styles";
import { HeadingText } from "../common/styles/Text.styles";
import { perelynPrimary } from "../styles/consts";

export default function OpenWebUI() {
  const [instanceStatus, setInstanceStatus] = useState("");

  useEffect(() => {
    async function fetchInstanceStatus() {
      const status = await openWebuiService.getInstanceStatus();
      setInstanceStatus(status);
    }

    fetchInstanceStatus();

    const interval = setInterval(() => {
      if (instanceStatus !== "running" && instanceStatus !== "stopped") {
        fetchInstanceStatus();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [instanceStatus]);

  return (
    //Uncomment this block when the WebUI is under construction

    // <FullStack
    //   sx={{
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    // >
    //   <HeadingText sx={{ color: perelynPrimary }}>
    //     Fyndr WebUI is under construction
    //   </HeadingText>
    //   <Typography>In order to use it, please contact administrator</Typography>
    // </FullStack>
    <>
      {instanceStatus === "running" ? (
        <iframe
          title="WebUI"
          style={{
            height: "100%",
            width: "100%",
            border: "none",
          }}
          src={`https://t8i0hge9gd.execute-api.eu-central-1.amazonaws.com/`}
        />
      ) : instanceStatus === "stopped" ? (
        <FullStack
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HeadingText sx={{ color: perelynPrimary }}>
            The WebUI instance is turned off
          </HeadingText>
          <Typography>
            In order to use it, please contact administrator
          </Typography>
        </FullStack>
      ) : (
        <FullStack sx={{ justifyContent: "center", alignItems: "center" }}>
          <Loader />
        </FullStack>
      )}
    </>
  );
}
