import { Stack } from "@mui/material";
import { defaultTransition, miniGap } from "../../../styles/consts";
import { StyledRowDataBox } from "../../styles/Table.styles";
import { SmallText } from "../../styles/Text.styles";
import Checkbox from "../fundamentals/Checkbox";
import Icon from "../fundamentals/Icon";
import { ItemRow } from "./Row";
import { Sort } from "./Table";

export default function LabelRow({
  labels,
  handleAllDisplayedSelect,
  isChecked,
  changeSort,
  currentSort,
}: {
  labels: ItemRow[];
  handleAllDisplayedSelect: () => void;
  isChecked: boolean;
  changeSort?: (param?: string) => void;
  currentSort?: Sort;
}) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        width: "100%",
      }}
    >
      {labels.map((label: ItemRow, idx) => (
        <StyledRowDataBox
          key={idx}
          isLast={idx === labels.length - 1}
          width={label.width}
        >
          {idx === 0 && (
            <Checkbox
              id={"select-all-checkbox"}
              iconName="Minus"
              onChange={handleAllDisplayedSelect}
              isChecked={isChecked}
            />
          )}
          {label.label && (
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: miniGap,
                cursor: label.sortParam ? "crosshair" : "default",
                width: "100%",
              }}
              onClick={() => changeSort && changeSort(label.sortParam)}
            >
              <SmallText>{label.label}</SmallText>
              {currentSort?.param === label.sortParam && (
                <Icon
                  name="ArrowDown"
                  sx={{
                    width: "1rem",
                    height: "1rem",
                    transition: defaultTransition,
                    transform:
                      currentSort?.order === "asc" ? "scaleY(-1)" : "none",
                  }}
                />
              )}
            </Stack>
          )}
        </StyledRowDataBox>
      ))}
    </Stack>
  );
}
