import { Stack } from "@mui/material";
import { useState } from "react";
import Dropdown from "../../../../../common/components/fundamentals/Dropdown";
import TaskAutoUpdateTextField from "../../../../../common/components/fundamentals/TaskAutoUpdateTextField";
import {
  borderOrange,
  defaultGap,
  smallGap,
} from "../../../../../styles/consts";
import UploadBox from "../../../PdfDataExtractionTool/components/config/UploadBox";
import { useTask } from "../../context/TaskProvider";
import ContextCustomization from "./section/ContextCustomization";

export default function UserPrompt({
  isInputEmpty,
  setIsInputEmpty,
}: {
  isInputEmpty: boolean;
  setIsInputEmpty: (value: boolean) => void;
}) {
  const { task, saveTask } = useTask();
  const [inputSourceType, setInputSourceType] = useState(
    task.configs.marketingText.general.inputSource.value?.at(0)?.name ?? "N/A"
  );

  const textFieldProps = {
    Text: {
      placeholder: "Enter user prompt*",
      multiline: true,
      rows: 10,
    },
    URL: {
      placeholder: "Enter website URL*",
    },
    File: {
      placeholder: "Enter website URL*",
    },
  };

  return (
    <Stack
      sx={{
        width: "100%",
        gap: smallGap,
      }}
    >
      <Stack
        sx={{
          width: "100%",
          gap: defaultGap,
        }}
      >
        <Dropdown
          id={"prompt-type-dropdown"}
          initialSelected={inputSourceType}
          options={task.configs.marketingText.general.inputSource.value_options.map(
            (o) => {
              return {
                name: o.name,
                type: o.type,
                value: o.value,
              };
            }
          )}
          onChange={async (name, type, value) => {
            setInputSourceType(name);
            const t = await saveTask({
              "configs.marketingText.general.inputSource.value[0]": {
                name,
                type,
                value,
              },
              "configs.marketingMetadata.general.inputSource.value[0]": {
                name,
                type,
                value,
              },
            });
            setInputSourceType(
              t.configs.marketingText.general.inputSource.value?.at(0)?.name ??
                ""
            );
          }}
        />
        {inputSourceType !== "File" ? (
          <TaskAutoUpdateTextField
            id="user-prompt"
            defaultValue={
              task.configs.marketingText.general.inputSource.value?.at(0)?.value
            }
            propPath={[
              "configs.marketingText.general.inputSource.value[0].value",
              "configs.marketingMetadata.general.inputSource.value[0].value",
            ]}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": isInputEmpty && {
                "& fieldset": {
                  transition: "all 100ms linear",
                  border: borderOrange,
                },
              },
            }}
            {...textFieldProps[inputSourceType]}
            onClick={() => setIsInputEmpty(false)}
          />
        ) : (
          <UploadBox
            value={
              task.configs.marketingText.general.inputSource.value?.at(0)
                ?.value ?? ""
            }
            propertyPath="configs.marketingText.general.inputSource.value[0].value"
            module="marketingText"
            isInputEmpty={isInputEmpty}
            setIsInputEmpty={setIsInputEmpty}
          />
        )}
      </Stack>
      <Stack
        sx={{
          justifyContent: "space-between",
          gap: smallGap,
        }}
      >
        <TaskAutoUpdateTextField
          id="comment"
          placeholder="Add a comment*"
          defaultValue={
            task.configs.marketingText.nodes.WriteLinkedinPostNode.comments
              .value === null
              ? ""
              : task.configs.marketingText.nodes.WriteLinkedinPostNode.comments
                  .value
          }
          propPath={[
            "configs.marketingText.nodes.WriteLinkedinPostNode.comments.value",
            "configs.marketingText.nodes.WriteFacebookPostNode.comments.value",
            "configs.marketingText.nodes.WriteXPostNode.comments.value",
            "configs.marketingText.general.comments.value",
          ]}
          multiline
          maxRows="3"
          sx={{
            width: "100%",
          }}
        />
        <ContextCustomization />
      </Stack>
    </Stack>
  );
}
