import { View } from "@react-pdf/renderer";
import { tableBorder, tableHeaderBackground } from "./const";
import { SmallTextPDF, TableHeader } from "./Texts";

export function HeaderRow({ label }: { label: string }) {
  return (
    <Row
      style={{
        backgroundColor: tableHeaderBackground,
      }}
    >
      <TableHeader>{label}</TableHeader>
    </Row>
  );
}

export function Row({
  children,
  last = false,
  style,
}: {
  children: React.ReactNode;
  last?: boolean;
  style?: any;
}) {
  return (
    <View
      style={{
        padding: "4px",
        display: "flex",
        width: "100%",
        flexDirection: "row",
        borderBottom: last ? "none" : tableBorder,
        ...style,
      }}
    >
      {children}
    </View>
  );
}

export function Table({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: any;
}) {
  return (
    <View
      style={{
        border: tableBorder,
        ...style,
      }}
      wrap={false}
    >
      {children}
    </View>
  );
}

export function ContentRow({
  label,
  content,
  last = false,
}: {
  label: any;
  content?: any;
  last?: boolean;
}) {
  return (
    <Row
      last={last}
      style={{
        padding: "0 4px",
      }}
    >
      <SmallTextPDF
        style={{
          width: "30%",
        }}
      >
        {label}:
      </SmallTextPDF>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "70%",
        }}
      >
        {content}
      </View>
    </Row>
  );
}
