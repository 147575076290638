import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import agentOneService from "../../../../../api/AgentOneService";
import Choice from "../../../../../common/components/fundamentals/Choice";
import Dropdown from "../../../../../common/components/fundamentals/Dropdown";
import { BiggerIcon } from "../../../../../common/components/fundamentals/Icon";
import { usePopUp } from "../../../../../common/context/PopUpProvider";
import { useSnackbar } from "../../../../../common/context/SnackbarProvider";
import {
  FullStack,
  OrangeOnHoverStack,
} from "../../../../../common/styles/Box.styles";
import { OrangeButton } from "../../../../../common/styles/Button.styles";
import { CustomFilterBox } from "../../../../../common/styles/Form.styles";
import {
  HeadingText,
  MediumText,
  SmallText,
} from "../../../../../common/styles/Text.styles";
import { dashboardPath, PDF_DATA_EXTRACTION } from "../../../../../routes";
import {
  borderOrange,
  borderStyle,
  checkboxGap,
  defaultGap,
  defaultTransition,
  iconGap,
  iconSize,
  perelynBlack,
  perelynPrimary,
  smallerGap,
  smallGap,
  tinyGap,
} from "../../../../../styles/consts";
import { capitalizeFirstLetter } from "../../../../../utils";
import PopUpBox from "../../../MarketingTextTool/components/result/PopUpBox";
import { useTask } from "../../../MarketingTextTool/context/TaskProvider";
import ModifyParameterPopupTask from "./ModifyParameterPopupTask";
import UploadBox from "./UploadBox";

export default function PdfDataExtractionConfigPage() {
  const { task, saveTask } = useTask();
  const { showSnackbar } = useSnackbar();
  const { showPopUpWindow } = usePopUp();
  const navigate = useNavigate();

  let filterGroups = [
    ...new Set(
      task.configs.entityExtraction.nodes.EntityExtractionNode.entities.value_options.map(
        (option) => option.group
      )
    ),
  ];

  if (filterGroups.length > 5) {
    filterGroups = ["all", ...filterGroups];
  }

  const [filteredParameters, setFilteredParameters] = useState(filterGroups);
  const [currentFilter, setCurrentFilter] = useState("");

  const handleGenerate = async () => {
    await agentOneService.runEntityExtraction(task);
    navigate(dashboardPath(PDF_DATA_EXTRACTION));
  };

  const clearFilter = () => {
    setCurrentFilter("");
    setFilteredParameters(filterGroups);
  };

  const filter = (filterTag) => {
    if (filterTag === "all") {
      setCurrentFilter("all");
      setFilteredParameters(filterGroups);
    } else if (currentFilter === filterTag) {
      clearFilter();
    } else {
      setCurrentFilter(filterTag);
      const filtered = filterGroups.filter((group) => {
        if (filterTag === "") return true;
        return group === filterTag;
      });
      setFilteredParameters(filtered);
    }
  };

  const handleDeleteClick = (parameterName: string) => {
    showPopUpWindow(
      () => handleDeleteParameter(parameterName),
      `Delete parameter ${parameterName}?`,
      `Are you sure you want to delete this parameter? This action will delete the parameter from current task. You can permanetly delete it in tools settings.`,
      "Cancel",
      "Delete"
    );
  };

  const handleDeleteParameter = async (parameterName: string) => {
    const updatedValueOptions =
      task.configs.entityExtraction.nodes.EntityExtractionNode.entities.value_options.filter(
        (option) => option.name !== parameterName
      );
    const optionToDelete =
      task.configs.entityExtraction.nodes.EntityExtractionNode.entities.value_options.find(
        (option) => option.name === parameterName
      );
    const updatedValues =
      task.configs.entityExtraction.nodes.EntityExtractionNode.entities.value.filter(
        (value) => value !== optionToDelete?.value[0]
      );
    const updatedEntities = {
      ...task.configs.entityExtraction.nodes.EntityExtractionNode.entities,
      value_options: updatedValueOptions,
      value: updatedValues,
    };
    saveTask({
      "configs.entityExtraction.nodes.EntityExtractionNode.entities":
        updatedEntities,
    });
    showSnackbar(
      "Parameter deleted successfully. You can permanently delete it in tools settings.",
      false,
      true
    );
  };

  return (
    <FullStack>
      <Stack
        sx={{
          paddingBottom: defaultGap,
        }}
      >
        <HeadingText>Let's get started</HeadingText>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          gap: "3rem",
          paddingBottom: defaultGap,
          boxSizing: "border-box",
        }}
      >
        <Stack
          sx={{
            gap: iconSize,
            width: "12rem",
          }}
        >
          <HeadingText>Add file/s*</HeadingText>
          <SmallText>
            Drag and drop a file to the box on the right or click on it to
            upload a file.
          </SmallText>
        </Stack>

        <UploadBox
          value={
            task.configs.entityExtraction.general.inputSource.value?.at(0)
              ?.value || ""
          }
          propertyPath="configs.entityExtraction.general.inputSource.value[0].value"
          module="entityExtraction"
        />
      </Stack>
      <Stack
        sx={{
          height: "fit-content",
          border: borderStyle,
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            padding: smallerGap,
          }}
        >
          <HeadingText>Select parameters</HeadingText>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: tinyGap }}
          >
            {/* <Typography>Configuration</Typography>
            <Dropdown
              sx={{
                whiteSpace: "nowrap",
              }}
              options={[{ value: task.configs.entityExtraction.id }]}
              initialSelected={task.configs.entityExtraction.id}
            /> */}
            <Typography>Prompt preset</Typography>
            <Dropdown
              id="prompt-presets"
              options={task.configs.entityExtraction.nodes.EntityExtractionNode.promptPresets.value_options.map(
                (option) => ({
                  value: option.name,
                })
              )}
              initialSelected={
                task.configs.entityExtraction.nodes.EntityExtractionNode
                  .promptPresets.value?.name || ""
              }
              onChange={(value) => {
                const selectedPromptPreset =
                  task.configs.entityExtraction.nodes.EntityExtractionNode.promptPresets.value_options.find(
                    (option) => option.name === value
                  );
                saveTask({
                  "configs.entityExtraction.nodes.EntityExtractionNode.promptPresets.value":
                    selectedPromptPreset,
                });
              }}
            />
          </Stack>
        </Stack>

        <Stack
          sx={{
            padding: `${smallerGap} ${smallerGap} ${smallerGap}`,
            gap: smallGap,

            width: "fit-content",
            height: "30rem",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              gap: checkboxGap,
              whiteSpace: "nowrap",
              alignItems: "center",
              boxSizing: "border-box",
              maxHeight: "2rem",
              paddingBottom: smallerGap,
            }}
          >
            <MediumText paddingRight={checkboxGap}>Filter</MediumText>
            {filterGroups.length > 5 ? (
              <Dropdown
                initialSelected={currentFilter || "all"}
                options={filterGroups.map((group) => ({ value: group }))}
                onChange={(value) => filter(value)}
              />
            ) : (
              filterGroups.map((option) => (
                <CustomFilterBox
                  sx={{
                    "&&": {
                      padding: "0.5rem",
                      border:
                        currentFilter === option ? borderOrange : borderStyle,
                    },
                  }}
                  onClick={() => filter(option)}
                  key={option}
                >
                  <SmallText
                    sx={{
                      transition: defaultTransition,
                      color:
                        currentFilter === option
                          ? perelynPrimary
                          : perelynBlack,
                    }}
                  >
                    {capitalizeFirstLetter(option)}
                  </SmallText>
                </CustomFilterBox>
              ))
            )}
            <OrangeOnHoverStack sx={{ transition: defaultTransition }}>
              <BiggerIcon
                onClick={clearFilter}
                sx={{ height: "1.75rem" }}
                name="XMark"
              />
            </OrangeOnHoverStack>
          </Stack>
          <Stack sx={{ overflowY: "auto", flex: "1", height: "100%" }}>
            {task.configs.entityExtraction.nodes.EntityExtractionNode.entities.value_options
              .filter((option) => filteredParameters.includes(option.group))
              .map((option, idx) => (
                <Stack
                  key={idx}
                  sx={{
                    flexDirection: "row",
                    gap: iconGap,
                    width: "25rem",
                    justifyContent: "space-between",
                  }}
                >
                  <Choice
                    choice={{ name: option.name, value: option.value[0] }}
                    isLast={
                      idx ===
                      task.configs.entityExtraction.nodes.EntityExtractionNode
                        .entities.value_options.length -
                        1
                    }
                    valueList={
                      task.configs.entityExtraction.nodes.EntityExtractionNode
                        .entities.value
                    }
                    checkIsCurrentlySelected={(choice, t) =>
                      t.configs.entityExtraction.nodes.EntityExtractionNode.entities.value.includes(
                        choice
                      )
                    }
                    valueParamPath="configs.entityExtraction.nodes.EntityExtractionNode.entities.value"
                  />
                  {option.group !== "business" &&
                    option.group !== "chemical" && (
                      <OrangeOnHoverStack
                        sx={{
                          flexDirection: "row",
                          height: "fit-content",
                          paddingRight: smallerGap,
                        }}
                        onClick={() => handleDeleteClick(option.name)}
                      >
                        <BiggerIcon name="Trash" />
                      </OrangeOnHoverStack>
                    )}
                </Stack>
              ))}
          </Stack>

          <PopUpBox
            button={
              <OrangeOnHoverStack
                sx={{
                  flexDirection: "row",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                <BiggerIcon
                  name="Plus"
                  sx={{ height: smallerGap, width: smallerGap }}
                />
                <Typography>Add custom parameter</Typography>
              </OrangeOnHoverStack>
            }
            heading={"Create custom parameter"}
          >
            {(setIsOpen) => <ModifyParameterPopupTask setIsOpen={setIsOpen} />}
          </PopUpBox>
        </Stack>

        <Stack
          sx={{
            borderTop: borderStyle,
            flexDirection: "row",
            justifyContent: "right",
          }}
        >
          <OrangeButton
            id="continue-process"
            onClick={handleGenerate}
            sx={{ borderLeft: borderStyle }}
          >
            Generate
          </OrangeButton>
        </Stack>
      </Stack>
    </FullStack>
  );
}
