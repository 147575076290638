import { fetchAuthSession } from "@aws-amplify/auth";
import axios from "axios";

const createAxios = (url?: string) => {
  const api = axios.create({
    baseURL: url,
    headers: {
      "Content-Type": "application/json",
    },
  });
  api.interceptors.request.use(
    async function (config) {
      const session = await fetchAuthSession();
      config.headers["Content-Type"] = "application/json";
      config.headers["Authorization"] = session.tokens?.idToken?.toString();
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  return api;
};

export const apiAxios = createAxios(process.env.REACT_APP_APIURL);
export const postgresApiAxios = createAxios(
  process.env.REACT_APP_POSTGRESAPIURL
);

