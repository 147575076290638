import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTask } from "../../../pages/Modules/MarketingTextTool/context/TaskProvider";
import {
  borderStyle,
  perelynPrimary,
  smallerGap,
  tinyGap,
} from "../../../styles/consts";
import { capitalizeFirstLetter, flip } from "../../../utils";
import { useSnackbar } from "../../context/SnackbarProvider";
import { Task } from "../../models/Models";
import Checkbox from "./Checkbox";

export default function Choice({
  choice,
  isLast = false,
  checkIsCurrentlySelected,
  valueParamPath,
  valueList,
  horizontal = false,
}: {
  choice: { name: string; value: string };
  isLast: boolean;
  checkIsCurrentlySelected: (choice: string, t: Task) => boolean;
  valueParamPath: string;
  valueList: string[];
  horizontal?: boolean;
}) {
  const { task, saveTask } = useTask();
  const { showSnackbar } = useSnackbar();
  const isCurrentlySelected = checkIsCurrentlySelected(choice.value, task);
  const [iC, setIC] = useState(isCurrentlySelected);

  useEffect(() => {
    setIC(isCurrentlySelected);
  }, [isCurrentlySelected]);

  const onCheckboxChange = async () => {
    if (iC && valueList.length === 1) {
      showSnackbar("At least one option must be selected", true, true);
      return;
    }

    let prevValue = iC;
    flip(setIC)();
    const value = prevValue
      ? valueList.filter((v) => v !== choice.value)
      : [...valueList, choice.value];
    const updatedTask = await saveTask({
      [valueParamPath]: value,
    });

    setIC(checkIsCurrentlySelected(choice.value, updatedTask));
  };

  return (
    <Stack
      sx={{
        paddingBottom: horizontal ? 0 : isLast ? 0 : smallerGap,
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: tinyGap,
          paddingBottom: horizontal ? "0" : "0.75rem",
          boxSizing: "border-box",
          cursor: "crosshair",
          width: "fit-content",
        }}
        onClick={onCheckboxChange}
      >
        <Checkbox
          id={`${choice.name}-checkbox`}
          iconName="CheckboxX"
          sx={{
            border: borderStyle,
          }}
          isChecked={iC}
          onChange={onCheckboxChange}
        />

        <Typography color={iC ? perelynPrimary : ""}>
          {capitalizeFirstLetter(choice.name)}
        </Typography>
      </Stack>
    </Stack>
  );
}
