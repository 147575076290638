import { Stack, Typography } from "@mui/material";
import Icon, {
  BiggerIcon,
} from "../../../../../common/components/fundamentals/Icon";
import TaskAutoUpdateTextField from "../../../../../common/components/fundamentals/TaskAutoUpdateTextField";
import NotImplemented from "../../../../../common/components/utils/NotImplemented";
import { useLayoutConfig } from "../../../../../common/context/LayoutConfigProvider";
import { useSnackbar } from "../../../../../common/context/SnackbarProvider";
import { DisabledMultilineTextField } from "../../../../../common/styles/Box.styles";
import {
  OrangeButton,
  OutlinedButton,
  TransparentBigButton,
} from "../../../../../common/styles/Button.styles";
import { HeadingText } from "../../../../../common/styles/Text.styles";
import {
  borderDashed,
  borderStyle,
  checkboxGap,
  defaultGap,
  iconGap,
  miniGap,
  perelynGrey,
  perelynPrimary,
  smallerGap,
  smallGap,
  tinyGap,
} from "../../../../../styles/consts";
import { capitalizeFirstLetter, smallFirstLetter } from "../../../../../utils";
import { useTask } from "../../context/TaskProvider";
import PromptSettingsTag from "./PromptSettingsTag";

export default function TaskOutput({
  type,
  variant,
  value,
  basePropPath,
  isLast = false,
  isShort = false,
  isOnlyEnglish = false,
  isArray = false,
}: {
  type: string;
  variant: string;
  value: string | object;
  basePropPath: string;
  isLast?: boolean;
  isShort?: boolean;
  isOnlyEnglish?: boolean;
  isArray?: boolean;
}) {
  const { moduleName } = useLayoutConfig();
  const { showSnackbar } = useSnackbar();
  const { task } = useTask();
  const pathToUpdate = isOnlyEnglish
    ? `${basePropPath.replace("translations", "articles")}.${smallFirstLetter(
        type
      )}`
    : `${basePropPath}.${
        variant.includes(" ") ? variant : smallFirstLetter(variant)
      }.${smallFirstLetter(type)}`;
  const buttons = {
    "pdf-data-extraction": (
      <Stack
        sx={{
          flexDirection: "row",
          gap: checkboxGap,
        }}
      >
        <OutlinedButton
          onClick={() => {
            navigator.clipboard.writeText(value.toString());
            showSnackbar("Copied to clipboard!");
          }}
        >
          <BiggerIcon name="Copy" />
        </OutlinedButton>
      </Stack>
    ),
    "marketing-text": (
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "end",
          width: "100%",
        }}
      >
        <Stack sx={{ flexDirection: "row", gap: checkboxGap }}>
          <OutlinedButton
            onClick={() => {
              navigator.clipboard.writeText(value.toString());
              showSnackbar("Copied to clipboard!");
            }}
          >
            <BiggerIcon name="Copy" />
          </OutlinedButton>
          <NotImplemented>
            <OutlinedButton>Forward</OutlinedButton>
          </NotImplemented>
          <NotImplemented>
            <OutlinedButton>Check later</OutlinedButton>
          </NotImplemented>
          <NotImplemented popupLeft>
            <OrangeButton>Mark as done</OrangeButton>
          </NotImplemented>
        </Stack>
      </Stack>
    ),
  };
  const nameDisplayed =
    task.configs?.entityExtraction?.nodes?.EntityExtractionNode?.entities?.value_options.find(
      (option) => option.value[0].includes(variant)
    )?.name || variant;

  return (
    <Stack
      sx={{
        padding: `${defaultGap} ${smallerGap} ${smallerGap}`,
        gap: defaultGap,
        borderBottom: isLast ? "none" : borderDashed,
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            gap: iconGap,
          }}
        >
          <HeadingText>{type}</HeadingText>
          <HeadingText
            sx={{
              color: perelynPrimary,
            }}
          >
            {capitalizeFirstLetter(nameDisplayed)}
          </HeadingText>
        </Stack>
        <Icon
          sx={{
            height: defaultGap,
            width: defaultGap,
          }}
          name="MinusCircle"
        />
      </Stack>
      <Stack>
        {typeof value === "object" ? (
          Array.isArray(value) &&
          value.every((item) => typeof item === "object" && item !== null) ? (
            value.map((obj, objIdx) => (
              <Stack
                key={objIdx}
                sx={{
                  border: borderStyle,
                  padding: checkboxGap,
                  marginBottom: defaultGap,
                }}
              >
                <Stack
                  sx={{
                    border: borderStyle,
                  }}
                >
                  {Object.entries(obj || {}).map(([key, v], idx) => {
                    const isLast = Object.entries(obj || {}).length - 1 === idx;
                    return (
                      <Stack
                        key={idx}
                        sx={{
                          flexDirection: "row",
                        }}
                      >
                        <Stack
                          sx={{
                            width: "40%",
                            borderBottom: isLast ? "none" : borderStyle,
                            borderRight: borderStyle,
                            padding: `0 ${iconGap}`,
                            backgroundColor: perelynGrey,
                          }}
                        >
                          <Typography>{key}</Typography>
                        </Stack>
                        <Stack
                          sx={{
                            borderBottom: isLast ? "none" : borderStyle,
                            width: "60%",
                            padding: `0 ${iconGap}`,
                          }}
                        >
                          <Typography>{v as string}</Typography>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            ))
          ) : (
            <Stack
              sx={{
                border: borderStyle,
                padding: checkboxGap,
              }}
            >
              <Stack
                sx={{
                  border: borderStyle,
                }}
              >
                {Object.entries(value || {}).map(([key, v], idx) => {
                  const isLast = Object.entries(value || {}).length - 1 === idx;
                  return (
                    <Stack
                      key={idx}
                      sx={{
                        flexDirection: "row",
                      }}
                    >
                      <Stack
                        sx={{
                          width: "40%",
                          borderBottom: isLast ? "none" : borderStyle,
                          borderRight: borderStyle,
                          padding: `0 ${iconGap}`,
                          backgroundColor: perelynGrey,
                        }}
                      >
                        <Typography>{key}</Typography>
                      </Stack>
                      <Stack
                        sx={{
                          borderBottom: isLast ? "none" : borderStyle,
                          width: "60%",
                          padding: `0 ${iconGap}`,
                        }}
                      >
                        <Typography>{v as string}</Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          )
        ) : isArray ? (
          <DisabledMultilineTextField>{value}</DisabledMultilineTextField>
        ) : pathToUpdate.includes(" ") ? (
          <DisabledMultilineTextField>{value}</DisabledMultilineTextField>
        ) : (
          <TaskAutoUpdateTextField
            // sx={{ padding: "10rem" }}
            id={variant}
            multiline={!isShort}
            maxRows={20}
            defaultValue={value}
            propPath={pathToUpdate}
          />
        )}
      </Stack>
      {moduleName === "marketing-text" && (
        <Stack
          sx={{
            gap: smallGap,
          }}
        >
          <Stack sx={{ gap: "0.5rem" }}>
            <Typography>Prompt settings</Typography>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: smallerGap,
                }}
              >
                <TransparentBigButton
                  sx={{
                    border: borderStyle,
                    width: defaultGap,
                    height: defaultGap,
                    maxHeight: defaultGap,
                  }}
                  disabled
                >
                  <BiggerIcon name="Plus" />
                </TransparentBigButton>
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: miniGap,
                  }}
                >
                  <PromptSettingsTag type={"Translation"} />
                  <PromptSettingsTag type={"English"} />
                  <PromptSettingsTag type={`"style"`} />
                </Stack>
                <NotImplemented>
                  <OutlinedButton
                    sx={{
                      padding: `${tinyGap} ${checkboxGap}`,
                      gap: "0.5rem",
                    }}
                  >
                    <BiggerIcon name="OrangeRefresh" />
                    Retry
                  </OutlinedButton>
                </NotImplemented>
              </Stack>
              <NotImplemented popupLeft>
                <OutlinedButton
                  sx={{
                    borderColor: perelynGrey,
                    padding: `${tinyGap} 0rem ${tinyGap} ${checkboxGap}`,
                    color: perelynGrey,
                  }}
                >
                  Expert mode
                  <Icon
                    sx={{
                      height: "2rem",
                      width: "2rem",
                    }}
                    name="BigChevronDown"
                  />
                </OutlinedButton>
              </NotImplemented>
            </Stack>
          </Stack>
        </Stack>
      )}

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        {typeof value === "object" ? (
          <Stack
            sx={{
              flexDirection: "row",
              gap: checkboxGap,
            }}
          >
            <OutlinedButton
              sx={{
                borderColor: perelynGrey,
                padding: tinyGap,
                color: perelynGrey,
                gap: "0.5rem",
              }}
            >
              Download table
              <Icon
                sx={{
                  height: "1.5rem",
                  width: "1.5rem",
                }}
                name="Download"
              />
            </OutlinedButton>
            <OutlinedButton
              sx={{
                borderColor: perelynGrey,
                padding: tinyGap,
                color: perelynGrey,
                gap: "0.5rem",
              }}
            >
              Expand table
            </OutlinedButton>
          </Stack>
        ) : (
          buttons[moduleName]
        )}
      </Stack>
    </Stack>
  );
}
