import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Candidate, Offer } from "../../../common/models/Models";

type AlqemiDashboardState = {
  offers: Offer[];
  setOffers: (offers: Offer[]) => void;
  candidates: Candidate[];
  setCandidates: (candidates: Candidate[]) => void;
  showOnlyTopOffers: boolean;
  setShowOnlyTopOffers: Dispatch<SetStateAction<boolean>>;
};

const AlqemiDashboardContext = createContext<AlqemiDashboardState | undefined>(
  undefined
);

export const useAlqemiDashboard = (): AlqemiDashboardState => {
  const alqemiDashboardState = useContext(AlqemiDashboardContext);
  if (!alqemiDashboardState) {
    throw new Error("useOffersDashboard must be used within an OffersProvider");
  }
  return alqemiDashboardState;
};

export default function AlqemiDashboardProvider({
  children,
  preFetchedValues,
}: {
  children: ReactNode;
  preFetchedValues: any[][];
}) {
  const [offers, setOffers] = useState<Offer[]>(preFetchedValues[0] as Offer[]);
  const [showOnlyTopOffers, setShowOnlyTopOffers] = useState<boolean>(
    localStorage.getItem("alqemiFilter") === "best"
  );
  const [candidates, setCandidates] = useState<Candidate[]>(
    preFetchedValues[1] as Candidate[]
  );

  const alqemiDashboardState: AlqemiDashboardState = {
    offers,
    setOffers,
    candidates,
    setCandidates,
    showOnlyTopOffers,
    setShowOnlyTopOffers,
  };

  return (
    <AlqemiDashboardContext.Provider value={alqemiDashboardState}>
      {children}
    </AlqemiDashboardContext.Provider>
  );
}
