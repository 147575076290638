import { Collapse, Slider, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import backgroundMusicService from "../../../api/BackgroundMusicService";
import {
  borderStyle,
  boxHeight,
  defaultTransition,
  iconGap,
  smallGap,
  tinyGap,
} from "../../../styles/consts";
import { flip } from "../../../utils";
import { MusicIcon } from "../fundamentals/Icon";

export default function MusicPlayer() {
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [audioSrc, setAudioSrc] = useState<string | null>(null);
  const [userInteracted, setUserInteracted] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(50);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    async function fetchPresignedURL() {
      const url = await backgroundMusicService.getPresignedDownloadURL();
      setAudioSrc(url);
    }
    fetchPresignedURL();
  }, []);

  useEffect(() => {
    const handleEnded = async () => {
      const url = await backgroundMusicService.getPresignedDownloadURL();
      setAudioSrc(url);
    };

    const handleCanPlay = () => {
      if (userInteracted && audioRef.current) {
        audioRef.current.play();
      }
    };

    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.addEventListener("ended", handleEnded);
      audioElement.addEventListener("canplay", handleCanPlay);
      audioElement.volume = volume / 100;
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener("ended", handleEnded);
        audioElement.removeEventListener("canplay", handleCanPlay);
      }
    };
  }, [userInteracted, volume]);

  const handlePlayPauseClick = () => {
    setUserInteracted(true);
    flip(setIsMusicPlaying)();
    if (audioRef.current) {
      if (isMusicPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }
  };

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    const newVolume = Array.isArray(newValue) ? newValue[0] : newValue;
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume / 100;
    }
  };

  const handleSkipClick = async () => {
    if (audioRef.current) {
      audioRef.current.pause();
      const url = await backgroundMusicService.getPresignedDownloadURL();
      setAudioSrc(url);
      audioRef.current.load();
      if (isMusicPlaying) {
        audioRef.current.play();
      }
    }
  };

  return (
    <Stack
      sx={{
        height: "100%",
        minWidth: boxHeight,
        justifyContent: "center",
        alignItems: "center",
        borderLeft: borderStyle,
        transition: "all 0.3s",
        flexDirection: "row",
        padding: smallGap,
        boxSizing: "border-box",
      }}
    >
      <audio ref={audioRef} src={audioSrc || ""} />

      <MusicIcon
        name={isMusicPlaying ? "Pause" : "Play"}
        onClick={handlePlayPauseClick}
        sx={{
          "&:hover": {
            transition: defaultTransition,
            cursor: "crosshair",
            filter:
              "brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(1354%) hue-rotate(315deg) brightness(98%) contrast(107%)",
          },
        }}
      />
      <Collapse
        in={isMusicPlaying}
        orientation="horizontal"
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            height: "100%",
            alignItems: "center",
            gap: iconGap,
            marginLeft: tinyGap,
          }}
        >
          <MusicIcon
            sx={{
              height: "1.75rem",
              transform: "rotate(180deg)",
              "&:hover": {
                transition: defaultTransition,
                cursor: "crosshair",
                filter:
                  "brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(1354%) hue-rotate(315deg) brightness(98%) contrast(107%)",
              },
            }}
            name="Skip"
            onClick={handleSkipClick}
          />
          <Slider
            value={volume}
            size="small"
            onChange={handleVolumeChange}
            aria-labelledby="volume-slider"
            orientation="vertical"
            color="secondary"
            sx={{
              transition: defaultTransition,
              height: "100%",
              "& .MuiSlider-thumb": {
                width: "0.5rem",
                height: "0.5rem",
                boxShadow: "none",
                "&:hover, &:active, &.Mui-focusVisible": {
                  boxShadow: "none",
                },
              },
            }}
          />
        </Stack>
      </Collapse>
    </Stack>
  );
}
