import { Stack } from "@mui/material";
import { useState } from "react";
import Dropdown from "../../../../common/components/fundamentals/Dropdown";
import { DisabledDropdown } from "../../../../common/styles/Box.styles";
import { defaultGap, smallGap } from "../../../../styles/consts";
import {
  capitalizeFirstLetter,
  replaceUnderscoreForWhitespace,
} from "../../../../utils";
import ArenaAutoUpdateTextField from "./ArenaAutoUpdateTextField";
import ArenaContextCustomization from "./ArenaContextCustomization";
import { useArena } from "./ArenaProvider";

export default function ArenaUserPrompt({ isDisabled }) {
  const { config, saveArena } = useArena();
  const [inputSourceType, setInputSourceType] = useState(
    config.configs.marketingTextA.general.inputSource.value?.at(0)?.name ?? ""
  );

  const textFieldProps = {
    Text: {
      placeholder: "Enter user prompt*",
      multiline: true,
      rows: 10,
    },
    URL: {
      placeholder: "Enter website URL*",
    },
    File: {
      placeholder: "NOT WORKING YET",
      disabled: true,
    },
  };
  return (
    <Stack
      sx={{
        width: "100%",
        gap: smallGap,
      }}
    >
      <Stack
        sx={{
          width: "100%",
          gap: defaultGap,
        }}
      >
        {isDisabled ||
        !!config.processStatuses?.marketingTextA?.result ||
        !!config.processStatuses?.marketingTextB?.result ? (
          <DisabledDropdown>
            {capitalizeFirstLetter(
              replaceUnderscoreForWhitespace(
                config.configs.marketingTextA.general.inputSource.value?.at(0)
                  ?.name ?? "N/A"
              )
            )}
          </DisabledDropdown>
        ) : (
          <Dropdown
            initialSelected={inputSourceType}
            options={config.configs.marketingTextA.general.inputSource.value_options.map(
              (o) => {
                return {
                  name: o.name,
                  type: o.type,
                  value: o.value,
                };
              }
            )}
            onChange={async (name, type, value) => {
              setInputSourceType(name);
              const c = await saveArena({
                "configs.marketingTextA.general.inputSource.value[0]": {
                  name,
                  type,
                  value,
                },
                "configs.marketingTextB.general.inputSource.value[0]": {
                  name,
                  type,
                  value,
                },
              });
              setInputSourceType(
                c.configs.marketingTextA.general.inputSource.value?.at(0)
                  ?.name ?? ""
              );
            }}
          />
        )}

        <ArenaAutoUpdateTextField
          disabled={
            isDisabled ||
            !!config.processStatuses?.marketingTextA?.result ||
            !!config.processStatuses?.marketingTextB?.result
          }
          id="user-prompt"
          defaultValue={
            config.configs.marketingTextA.general.inputSource.value?.at(0)
              ?.value
          }
          propPath={[
            "configs.marketingTextA.general.inputSource.value[0].value",
            "configs.marketingTextB.general.inputSource.value[0].value",
          ]}
          sx={{
            width: "100%",
          }}
          {...textFieldProps[inputSourceType]}
        />
      </Stack>
      <Stack
        sx={{
          justifyContent: "space-between",
          gap: smallGap,
        }}
      >
        <ArenaAutoUpdateTextField
          disabled={
            isDisabled ||
            !!config.processStatuses?.marketingTextA?.result ||
            !!config.processStatuses?.marketingTextB?.result
          }
          id="comment"
          placeholder="Add a comment*"
          defaultValue={
            config.configs.marketingTextA.nodes.WriteLinkedinPostNode.comments
              .value === null
              ? ""
              : config.configs.marketingTextA.nodes.WriteLinkedinPostNode
                  .comments.value
          }
          propPath={[
            "configs.marketingTextA.nodes.WriteLinkedinPostNode.comments.value",
            "configs.marketingTextB.nodes.WriteFacebookPostNode.comments.value",
            "configs.marketingTextA.nodes.WriteFacebookPostNode.comments.value",
            "configs.marketingTextB.nodes.WriteXPostNode.comments.value",
            "configs.marketingTextA.nodes.WriteXPostNode.comments.value",
            "configs.marketingTextB.nodes.WriteLinkedinPostNode.comments.value",
            "configs.marketingTextA.general.comments.value",
            "configs.marketingTextB.general.comments.value",
          ]}
          multiline
          maxRows="3"
          sx={{
            width: "100%",
          }}
        />
        <ArenaContextCustomization isDisabled={isDisabled} />
      </Stack>
    </Stack>
  );
}
