import { Stack } from "@mui/material";
import Choice from "../../../../../../../common/components/fundamentals/Choice";
import { MediumText } from "../../../../../../../common/styles/Text.styles";
import { useTask } from "../../../../context/TaskProvider";
import ConfigBox from "../../ConfigBox";

export default function SocialMediaSettings() {
  const { task } = useTask();

  return (
    <ConfigBox>
      <Stack>
        <MediumText>Social media settings</MediumText>
      </Stack>

      <Stack sx={{ flexDirection: "row", gap: "2.75rem" }}>
        {task.configs.marketingText.general.contentType.value_options.map(
          (socialMedia, idx) => (
            <Choice
              choice={{
                name: socialMedia.value[0],
                value: socialMedia.value[0],
              }}
              key={idx}
              isLast={
                idx ===
                task.configs.marketingText.general.contentType.value_options
                  .length -
                  1
              }
              valueList={
                task.configs.marketingText.general.contentType.value || []
              }
              checkIsCurrentlySelected={(choice, t) =>
                t.configs.marketingText.general.contentType.value?.includes(
                  choice
                ) ?? false
              }
              valueParamPath="configs.marketingText.general.contentType.value"
              horizontal
            />
          )
        )}
      </Stack>

      <Stack>
        {task.configs.marketingText.general.languages.value_options.map(
          (language, idx) => (
            <Choice
              choice={{ name: language.value[0], value: language.value[0] }}
              key={idx}
              isLast={
                idx ===
                task.configs.marketingText.general.languages.value_options
                  .length -
                  1
              }
              valueList={
                task.configs.marketingText.general.languages.value || []
              }
              checkIsCurrentlySelected={(choice, t) =>
                t.configs.marketingText.general.languages.value?.includes(
                  choice
                ) ?? false
              }
              valueParamPath="configs.marketingText.general.languages.value"
            />
          )
        )}
      </Stack>
      {/* <Stack gap={smallerGap}>
        {task.configs.marketingText.general.contentType.value?.map(
          (type, idx) => (
            <TargetGroupDropdown
              key={idx}
              type={capitalizeFirstLetter(type)}
              zIndexModifier={idx * 100}
            />
          )
        )}
      </Stack> */}
    </ConfigBox>
  );
}
