import { createContext, useContext, useState } from "react";

import taskService from "../../../../api/TaskService";
import { Task } from "../../../../common/models/Models";

type TaskState = {
  task: Task;
  setTask: (task: Task) => void;
  saveTask: (task: any) => Promise<Task>;
  selectedParam: string;
};

export const useTask = (): TaskState => {
  const taskState = useContext(TaskContext);
  if (!taskState) {
    throw new Error("useTask must be used within a TaskProvider");
  }
  return taskState;
};

const TaskContext = createContext<TaskState | null>(null);

type TaskProviderProps = {
  children: JSX.Element;
  preFetchedValues: [Task];
};

const TaskProvider = ({ children, preFetchedValues }: TaskProviderProps) => {
  const [task, setTask] = useState<Task>(preFetchedValues[0]);

  const saveTask = async (taskParams: any) => {
    const t = await taskService.updateTask(task.createdTimestamp, taskParams);
    setTask(t);
    return t;
  };

  const selectedParam =
    task.configs?.marketingText?.general?.contentType?.value?.at(0) ?? "";
  return (
    <TaskContext.Provider
      value={{
        task,
        setTask,
        saveTask,
        selectedParam,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export default TaskProvider;
