import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import taskService from "../../../../../api/TaskService";
import { FullStack } from "../../../../../common/styles/Box.styles";
import {
  borderStyle,
  defaultTransition,
  smallerGap,
} from "../../../../../styles/consts";
import ResultSection from "../../../MarketingTextTool/components/result/ResultSection";
import ResultTypeHeading from "../../../MarketingTextTool/components/result/ResultTypeHeading";
import { useTask } from "../../../MarketingTextTool/context/TaskProvider";

export default function PdfDataExtractionResultsPage() {
  const { task, selectedParam } = useTask();
  const [srcUrl, setSrcUrl] = useState<String | undefined>(undefined);

  const fetchPresignedURL = useCallback(async () => {
    const url = await taskService.getFileFromTask(task.createdTimestamp);
    setSrcUrl(url);
  }, [task.createdTimestamp]);

  useEffect(() => {
    fetchPresignedURL();
  }, [fetchPresignedURL]);

  return (
    <FullStack>
      <Stack
        sx={{
          gap: smallerGap,
        }}
      >
        <ResultTypeHeading type="Data set" />
        <Stack
          sx={{
            flexDirection: "row",
            gap: smallerGap,
          }}
        >
          <Stack
            sx={{
              transition: defaultTransition,
              border: borderStyle,
              "@media (min-width: 1500px) and (max-width: 2499px)": {
                width: "50%",
              },
              "@media (min-width: 2500px)": {
                width: "33%",
              },
            }}
          >
            <embed
              src={srcUrl + "#toolbar=0"}
              style={{
                height: "100%",
                width: "100%",
                border: "none",
              }}
            />
          </Stack>
          <Stack
            sx={{
              transition: defaultTransition,
              border: borderStyle,
              "@media (min-width: 1500px) and (max-width: 2499px)": {
                width: "50%",
              },
              "@media (min-width: 2500px)": {
                width: "67%",
              },
            }}
          >
            <ResultSection
              type={"Data set"}
              label={selectedParam}
              basePropPath="processStatuses.entityExtraction.result.extracted_entities"
              results={
                Object.entries(
                  task.processStatuses.entityExtraction.result
                    ?.extracted_entities || {}
                )
                  .sort((a, b) => {
                    return a[0] < b[0] ? 1 : -1;
                  })
                  .map(([key, value]) => {
                    const isArray = Array.isArray(value);
                    let transformedValue = value;

                    if (isArray) {
                      const isArrayOfDicts = value.every(
                        (item: any) => typeof item === "object" && item !== null
                      );
                      if (isArrayOfDicts) {
                        transformedValue = value;
                      } else {
                        transformedValue = value.join(",\n");
                      }
                    }

                    return {
                      label: key,
                      value: transformedValue,
                      isArray: isArray,
                    };
                  }) ?? []
              }
              isShort
              isLast
            />
          </Stack>
        </Stack>
      </Stack>
    </FullStack>
  );
}
