import { useOffer } from "../../../pages/Modules/Alqemi/context/OfferProvider";
import AutoUpdateTextField from "./AutoUpdateTextField";

export default function OfferAutoUpdateTextField({
  defaultValue,
  propPath,
  ...props
}: {
  defaultValue: string;
  propPath: string | string[];
  [key: string]: any;
}) {
  const { saveOffer } = useOffer();

  return (
    <AutoUpdateTextField
      defaultValue={defaultValue}
      propPath={propPath}
      saveFunction={saveOffer}
      {...props}
    />
  );
}
