import { Config } from "@testing-library/react";
import { apiAxios } from "./axios";

const CONFIG_URL = "/configuration";

const getURLWithConfigId = (url: string, configId: string) =>
  `${url}/${configId}`;

class ConfigService {
  async getConfiguration(config_id: string): Promise<Config> {
    return (
      await apiAxios.get<Config>(getURLWithConfigId(CONFIG_URL, config_id))
    ).data;
  }

  async updateConfigItem(key: object, updateProperties: object): Promise<any> {
    return (
      await apiAxios.post(CONFIG_URL, {
        key,
        update_properties: updateProperties,
      })
    ).data;
  }
}

const configService = new ConfigService();
export default configService;
