import { confirmResetPassword, resetPassword, signIn } from "@aws-amplify/auth";
import { Box, Collapse, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ReactPasswordChecklist from "react-password-checklist";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../common/context/SnackbarProvider";
import {
  OutlinedButton,
  PrimaryButton,
} from "../../../common/styles/Button.styles";
import { HeadingText, SmallText } from "../../../common/styles/Text.styles";
import { defaultGap, perelynPrimary, smallGap } from "../../../styles/consts";
import ForgotPasswordField from "./ForgotPasswordField";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [step, setStep] = useState(0);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnteredPassword, setReEnteredPassword] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    let isDisabled = false;
    if (step === 0) {
      isDisabled = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    } else if (step === 1) {
      isDisabled = !/^\d{6}$/.test(code);
    } else if (step === 2) {
      isDisabled = !isValid;
    }
    setIsButtonDisabled(isDisabled);
  }, [step, email, code, newPassword, reEnteredPassword, isValid]);

  const steps = [
    {
      description:
        "We will send you an email with instructions on how to change your password on the email address you provided",
      buttonText: "Send email",
      fields: [
        <TextField
          fullWidth
          placeholder="Enter your email"
          autoComplete="email"
          name="email"
          id="email"
          type={"email"}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setIsButtonDisabled(
              !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)
            );
          }}
        />,
      ],
      function: async (event: any) => {
        const data = new FormData(event.currentTarget);
        const email = data.get("email")?.toString();
        if (email) {
          setEmail(email);
          try {
            await resetPassword({
              username: email,
            });
            (document.getElementById("reset-form") as HTMLFormElement)?.reset();
          } catch (error) {
            console.error("An error occurred:", error);
            showSnackbar(
              "Enter an email corresponding to your account.",
              true,
              false
            );
            return false;
          }
        }
      },
    },
    {
      description: "Enter the 6-digit code we sent to your email",
      buttonText: "Verify code",
      fields: [
        <TextField
          fullWidth
          placeholder="Enter received code"
          autoComplete="code"
          name="code"
          id="code"
          type={"text"}
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
            setIsButtonDisabled(!/^\d{6}$/.test(e.target.value));
          }}
        />,
      ],
      function: async (event: any) => {
        const data = new FormData(event.currentTarget);
        const c = data.get("code")?.toString();
        if (c && /^\d{6}$/.test(c)) {
          setCode(c);
          return true;
        } else {
          console.error("Invalid code. Please enter a 6-digit number.");
          showSnackbar("Invalid code. Please enter a 6-digit number.", true);
          return false;
        }
      },
    },
    {
      description: "Create new password",
      buttonText: "Change password",
      fields: [
        <ForgotPasswordField
          id="newPassword"
          name="newPassword"
          placeholder="Enter new password"
          value={newPassword}
          setValue={setNewPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
            setIsButtonDisabled(!isValid);
          }}
        />,
        <ForgotPasswordField
          id="reEnteredPassword"
          name="reEnteredPassword"
          placeholder="Re-enter new password"
          value={reEnteredPassword}
          setValue={setReEnteredPassword}
          onChange={(e) => {
            setReEnteredPassword(e.target.value);
            setIsButtonDisabled(!isValid);
          }}
        />,

        <Collapse in={!isValid && (!!newPassword || !!reEnteredPassword)}>
          <ReactPasswordChecklist
            rules={[
              "minLength",
              "number",
              "specialChar",
              "capital",
              "lowercase",
              "match",
            ]}
            minLength={8}
            value={newPassword}
            valueAgain={reEnteredPassword}
            invalidColor={perelynPrimary}
            onChange={(isValid) => {
              setIsValid(isValid);
              setIsButtonDisabled(!isValid);
            }}
          />
        </Collapse>,
      ],
      function: async (event: any) => {
        const data = new FormData(event.currentTarget);
        const np = data.get("newPassword")?.toString();
        const rp = data.get("reEnteredPassword")?.toString();
        if (np && rp && np === rp && isValid) {
          await confirmResetPassword({
            username: email,
            confirmationCode: code,
            newPassword: np,
          });
          await signIn({
            username: email,
            password: np,
          });
          window.location.href = "/";
          window.location.reload();
        }
      },
    },
  ];

  const submitForgotPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    try {
      const result = await steps[step].function(event as any);
      if (result === false) {
        if (step === 2) {
          setStep(1);
        }
        return;
      }
      if (step === 2) {
        navigate("/");
        showSnackbar("Password changed successfuly!", false);
      } else {
        setStep((prev) => prev + 1);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      if (step === 2) {
        setStep(1);
        showSnackbar(
          "Something went wrong. Try typing your validation code again.",
          true,
          false
        );
      }
    }
  };
  return (
    <Box>
      <Stack
        id="reset-form"
        component="form"
        onSubmit={submitForgotPassword}
        sx={{
          width: "22.5rem",
          padding: defaultGap,
          gap: "2rem",
        }}
      >
        <Stack sx={{ alignItems: "center" }}>
          <HeadingText>Reset Password</HeadingText>
          <SmallText sx={{ textAlign: "center" }}>
            {steps[step].description}
          </SmallText>
        </Stack>
        {steps[step].fields.map((field, idx) => (
          <Stack key={idx}>{field}</Stack>
        ))}

        <Stack sx={{ width: "100%" }}>
          <Stack
            sx={{
              flexDirection: "row",
              gap: smallGap,
              justifyContent: "right",
            }}
          >
            <OutlinedButton onClick={() => navigate("/")}>
              Cancel
            </OutlinedButton>
            <PrimaryButton
              id="next-step"
              type="submit"
              sx={{ fontSize: "1rem" }}
              disabled={isButtonDisabled}
            >
              {steps[step].buttonText}
            </PrimaryButton>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
