// ErrorBoundary.js
import { Component } from "react";
import { defaultGap } from "../../styles/consts";
import { FullStack } from "../styles/Box.styles";
import { PrimaryButton } from "../styles/Button.styles";
import { HeadingText } from "../styles/Text.styles";

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<
  { showBackButton: boolean; children: React.ReactNode },
  ErrorBoundaryState
> {
  constructor(props: { children: React.ReactNode; showBackButton: boolean }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <FullStack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: defaultGap,
          }}
        >
          <HeadingText>{"Sorry, we've encountered an error"}</HeadingText>
          {this.props.showBackButton && (
            <PrimaryButton
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Back to the main page
            </PrimaryButton>
          )}
        </FullStack>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
