import { useLocation } from "react-router-dom";
import taskService from "../../../api/TaskService";
import DataLoadingWrapper from "../../../common/components/utils/DataLoadingWrapper";
import StepWrapper from "./StepWrapper";
import TaskProvider from "./context/TaskProvider";

export default function Wrapper() {
  const location = useLocation();

  const getTaskFromLocation = async () => {
    const createdTimestamp = location.pathname.split("/").at(-1) || "";
    if (createdTimestamp) {
      return taskService.getTask(createdTimestamp);
    }
  };
  return (
    <DataLoadingWrapper
      ContextProvider={TaskProvider}
      fetchFunctions={[getTaskFromLocation]}
      observeParam={location.pathname}
    >
      <StepWrapper />
    </DataLoadingWrapper>
  );
}
