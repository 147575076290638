import { Line, Path, Svg, Text, View } from "@react-pdf/renderer";
import { horizontalPadding, perelynRed, verticalPadding } from "./const";

export function BreakLine() {
  return (
    <Svg height="1" width="1000">
      <Line
        x1="0"
        y1="0"
        x2="1000"
        y2="0"
        strokeWidth={1}
        stroke="rgb(0,0,0)"
      />
    </Svg>
  );
}

export function RedBulletPointSquare({ style = {} }: { style?: any }) {
  return (
    <Svg viewBox="0 0 1 1" width={4} height={4} style={style}>
      <Path d="M0,0h1v1H0" fill={perelynRed} />
    </Svg>
  );
}

export function RedSlash({ size }: { size?: number }) {
  return <Text style={{ color: "red", fontSize: size }}> / </Text>;
}

export function DefaultPage({ children }: { children: React.ReactNode }) {
  return (
    <View
      style={{
        width: "100%",
        padding: `${verticalPadding} ${horizontalPadding}`,
      }}
      break
    >
      {children}
    </View>
  );
}
