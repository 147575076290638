import axios from "axios";
import { S3PresignedUpload } from "../common/models/Models";
import { apiAxios } from "./axios";

const S3_URL = "/s3";

class S3Service {
  async getPresignedUploadURL(): Promise<S3PresignedUpload> {
    return (
      await apiAxios.get<S3PresignedUpload>(
        S3_URL
      )
    ).data;
  }
  async uploadPDF(uploadURL: string, file: File): Promise<void> {
    await axios.put(uploadURL, file, {
      headers: {
        "Content-Type": "application/pdf",
      },
    });
  }
}

const s3Service = new S3Service();
export default s3Service;
