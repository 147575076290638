import { TextField } from "@mui/material";
import { useRef } from "react";

export default function AutoUpdateTextField({
  defaultValue,
  propPath,
  saveFunction,
  ...props
}: {
  defaultValue: string;
  propPath: string | string[];
  saveFunction: any;
  [key: string]: any;
}) {
  const textFieldRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && textFieldRef.current) {
      textFieldRef.current.blur();
    }
  };

  const onBlur = async (e) => {
    saveFunction(
      typeof propPath === "string"
        ? {
            [propPath]: e.target.value,
          }
        : propPath.reduce((acc, path) => {
            acc[path] = e.target.value;
            return acc;
          }, {})
    );
  };
  return (
    <TextField
      autoComplete="off"
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
      inputRef={textFieldRef}
      defaultValue={defaultValue}
      {...props}
    />
  );
}
