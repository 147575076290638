import { Collapse, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import Icon, {
  BiggerIcon,
} from "../../../../../common/components/fundamentals/Icon";
import { useSnackbar } from "../../../../../common/context/SnackbarProvider";
import { GreyOnHoverStack } from "../../../../../common/styles/Box.styles";
import { OutlinedButton } from "../../../../../common/styles/Button.styles";
import { HeadingText } from "../../../../../common/styles/Text.styles";
import {
  borderDashed,
  borderStyle,
  checkboxGap,
  defaultGap,
  defaultTransition,
  iconGap,
  smallerGap,
  smallGap,
} from "../../../../../styles/consts";
import {
  capitalizeFirstLetter,
  replaceUnderscoreForWhitespace,
} from "../../../../../utils";
import { useTask } from "../../context/TaskProvider";
import PopUpBox from "./PopUpBox";
import TaskOutput from "./TaskOutput";

export type Result = {
  label: string;
  value: string | object;
  isArray?: boolean;
};

export default function ResultSection({
  type,
  label,
  results,
  basePropPath,
  isLast = false,
  isShort = false,
  isOnlyEnglish = false,
}: {
  type: string;
  label: string;
  results: Result[];
  basePropPath: string;
  isLast?: boolean;
  isShort?: boolean;
  isOnlyEnglish?: boolean;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { task, saveTask } = useTask();
  const { showSnackbar } = useSnackbar();
  const [feedbackMessage, setFeedbackMessage] = useState<string>("");
  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedbackMessage(event.target.value);
  };

  const submitFeedback = async (event: React.FormEvent) => {
    event.preventDefault();
    if (feedbackMessage.trim() === "") {
      showSnackbar("Feedback message cannot be empty!", true);
      return;
    }
    try {
      const existingFeedback = task.feedback || {};
      const updatedFeedback = {
        ...existingFeedback,
        [label]: feedbackMessage,
      };
      await saveTask({ feedback: updatedFeedback });
      showSnackbar("Feedback submitted successfully!", false);
      setFeedbackMessage("");
    } catch (error) {
      showSnackbar("Failed to submit feedback. Please try again.", true);
    }
  };

  return (
    <Stack>
      <Stack
        sx={{
          padding: smallerGap,
          boxSizing: "border-box",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: isLast && !isOpen ? "none" : borderDashed,
        }}
      >
        <Stack
          sx={{
            alignItems: "center",
            flexDirection: "row",
            gap: checkboxGap,
          }}
        >
          <HeadingText>{type}</HeadingText>
          <BiggerIcon
            sx={{
              height: "1.5rem",
              width: "1.5rem",
            }}
            name="MinusCircle"
          />
          <BiggerIcon
            sx={{
              height: "1.5rem",
              width: "1.5rem",
            }}
            name="MinusCircle"
          />
        </Stack>
        <GreyOnHoverStack onClick={() => setIsOpen(!isOpen)}>
          <Icon
            sx={{
              height: defaultGap,
              width: defaultGap,
              transition: defaultTransition,
              transform: isOpen ? "none" : "scaleY(-1)",
            }}
            name="BigChevronUp"
          />
        </GreyOnHoverStack>
      </Stack>

      <Collapse in={isOpen}>
        {results.map((result, idx) => (
          <TaskOutput
            key={idx}
            type={capitalizeFirstLetter(replaceUnderscoreForWhitespace(label))}
            variant={result.label}
            value={result.value}
            isLast={idx === results.length - 1}
            isShort={isShort}
            basePropPath={basePropPath}
            isOnlyEnglish={isOnlyEnglish}
            isArray={result.isArray}
          />
        ))}
        {(type === "Social media" || type === "Data set") && (
          <Stack
            component="form"
            id="feedback-form"
            onSubmit={submitFeedback}
            sx={{
              padding: smallerGap,
              paddingTop: 0,
              gap: iconGap,
              transition: defaultTransition,
            }}
          >
            <Stack sx={{ gap: smallerGap }}>
              <PopUpBox
                button={<OutlinedButton>Your prompt</OutlinedButton>}
                heading="Your prompt"
              >
                <Stack
                  sx={{
                    boxSizing: "border-box",
                    padding: smallGap,
                    width: "100%",
                    height: "100%",
                    border: borderStyle,
                    overflow: "auto",
                    fontSize: smallGap,
                  }}
                >
                  {
                    task.configs.marketingText?.nodes.WriteLinkedinPostNode
                      ?.promptPresets?.value?.prompts?.systemPrompt1
                  }
                </Stack>
              </PopUpBox>
              {!task.feedback[label] ? (
                <Stack
                  sx={{
                    gap: iconGap,
                  }}
                >
                  <Typography>
                    Share your feedback about the results with us!
                  </Typography>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: smallGap,
                    }}
                  >
                    <TextField
                      id={`${label}-feedback`}
                      name="feedback"
                      sx={{
                        width: "20rem",
                        "&: focus-within": {
                          width: "100%",
                        },
                        transition: defaultTransition,
                      }}
                      placeholder="Write a feedback..."
                      autoComplete="off"
                      onChange={handleFeedbackChange}
                    />
                    <OutlinedButton
                      id={`${label}-submit-feedback`}
                      sx={{
                        width: "fit-content",
                      }}
                      type="submit"
                    >
                      <BiggerIcon name="SendRight" />
                    </OutlinedButton>
                  </Stack>
                </Stack>
              ) : (
                <Typography>Thank you for your feedback!</Typography>
              )}
            </Stack>
          </Stack>
        )}
      </Collapse>
    </Stack>
  );
}
