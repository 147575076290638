import { Text } from "@react-pdf/renderer";

import { ReactNode } from "react";
import { perelynRed } from "./const";

export function TinyTextPDF({
  children,
  style,
  ...props
}: {
  children?: ReactNode;
  style?: any;
  [key: string]: any;
}) {
  return (
    <Text
      style={{
        fontSize: "8px",
        fontFamily: "ABCWhyte",
        ...style,
      }}
      {...props}
    >
      {children}
    </Text>
  );
}

export function SmallTextPDF({
  children,
  style,
  ...props
}: {
  children?: ReactNode;
  style?: any;
  [key: string]: any;
}) {
  return (
    <Text
      style={{
        fontSize: "10px",
        fontFamily: "ABCWhyte",
        ...style,
      }}
      {...props}
    >
      {children}
    </Text>
  );
}

export function TableHeader({
  children,
  style,
}: {
  children: ReactNode;
  style?: any;
}) {
  return (
    <Text
      style={{
        fontSize: "12px",
        fontFamily: "ABCWhyte",
        color: perelynRed,
        ...style,
      }}
    >
      {children}
    </Text>
  );
}

export function MediumTextPDF({
  children,
  style,
}: {
  children: ReactNode;
  style?: any;
}) {
  return (
    <Text
      style={{
        fontSize: "16px",
        fontFamily: "ABCWhyte",
        ...style,
      }}
    >
      {children}
    </Text>
  );
}

export function LargeTextPDF({
  children,
  style,
}: {
  children: ReactNode;
  style?: any;
}) {
  return (
    <Text
      style={{
        fontSize: "36px",
        fontFamily: "ABCWhyte",
        ...style,
      }}
    >
      {children}
    </Text>
  );
}
