import { Route, Routes } from "react-router-dom";
import Navbar from "../../common/components/layout/Navbar";
import { FullStack } from "../../common/styles/Box.styles";
import Register from "../Register/Register";
import VerifyEmail from "../Register/VerifyEmail";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import LoginForm from "./LoginForm";

export default function LoginPage() {
  const routes = [
    { path: "/", component: <LoginForm /> },
    { path: "/register", component: <Register /> },
    { path: "/forgot-password", component: <ForgotPassword /> },
    { path: "/verify-email", component: <VerifyEmail /> },
  ];

  return (
    <FullStack>
      <Navbar loginPage />
      <FullStack sx={{ justifyContent: "center", alignItems: "center" }}>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.component}
            />
          ))}
        </Routes>
      </FullStack>
    </FullStack>
  );
}
