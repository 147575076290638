import { View } from "@react-pdf/renderer";
import { BreakLine } from "./Components";
import { horizontalPadding, verticalPadding } from "./const";
import PerelynLogo from "./PerelynLogo";

export default function Header() {
  return (
    <View fixed>
      <View
        style={{
          paddingRight: horizontalPadding,
          paddingTop: verticalPadding,
          paddingBottom: 15,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <PerelynLogo />
      </View>
      <BreakLine />
    </View>
  );
}
