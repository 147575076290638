import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DisabledDropdown } from "../../../../common/styles/Box.styles";
import { MediumText } from "../../../../common/styles/Text.styles";
import { defaultGap, tinyGap } from "../../../../styles/consts";
import { capitalizeFirstLetter } from "../../../../utils";
import ConfigBox from "../components/config/ConfigBox";
import DropdownWithLabel from "../components/config/section/SocialMediaSettings/DropdownWithLabel";
import { useArena } from "./ArenaProvider";
import ArenaDisabledPromptPresetSection from "./PromptSection/ArenaDisabledPromptPresetSection";
import ArenaPromptPresetSection from "./PromptSection/ArenaPromptPresetSection";

export default function ArenaConfigBox({
  isDisabled,
  contentType,
  setContentType,
  language,
  setLanguage,
}: {
  isDisabled: boolean;
  contentType: string;
  setContentType: (value: string) => void;
  language: string[];
  setLanguage: (value: string[]) => void;
}) {
  const { config, saveArena } = useArena();

  const [newPromptPreset, setNewPromptPreset] = useState("");

  const [targetGroup, setTargetGroup] = useState("");

  const nodeType = `Write${capitalizeFirstLetter(contentType)}PostNode`;

  useEffect(() => {
    if (language.length > 1) {
      const updatedLanguage = language.filter((lang) => lang !== "german");
      if (updatedLanguage.length !== language.length) {
        saveArena({
          "configs.marketingTextA.general.languages.value": updatedLanguage,
          "configs.marketingTextB.general.languages.value": updatedLanguage,
        });
        setLanguage(updatedLanguage);
      }
    }
  }, [language, saveArena, setLanguage]);

  useEffect(() => {
    const targetGroupOption = config.configs.marketingTextA.nodes[
      nodeType
    ]?.targetGroup.value_options.find(
      (option) =>
        option.value ===
        config.configs.marketingTextA.nodes[nodeType]?.targetGroup.value
    );
    setTargetGroup(targetGroupOption ? targetGroupOption.name : "N/A");
  }, [config, nodeType]);

  const handleContentTypeChange = async (newContentType) => {
    const updatedContentType = newContentType;
    const updatedNodeType = `Write${capitalizeFirstLetter(
      updatedContentType
    )}PostNode`;

    const defaultTargetGroupOption = config.configs.marketingTextA.nodes[
      updatedNodeType
    ].targetGroup.value_options.find((option) => option.default);

    const newTargetGroup = defaultTargetGroupOption
      ? defaultTargetGroupOption.name
      : "N/A";

    const defaultPromptPresetOption = config.configs.marketingTextA.nodes[
      updatedNodeType
    ].promptPresets.value_options.find((option) => option.default);

    const newPromptPreset = defaultPromptPresetOption
      ? defaultPromptPresetOption.presetName
      : "";

    await saveArena({
      "configs.marketingTextA.general.contentType.value[0]": updatedContentType,
      "configs.marketingTextB.general.contentType.value[0]": updatedContentType,
      [`configs.marketingTextA.nodes.${updatedNodeType}.targetGroup.value`]:
        defaultTargetGroupOption ? defaultTargetGroupOption.value : "",
      [`configs.marketingTextB.nodes.${updatedNodeType}.targetGroup.value`]:
        defaultTargetGroupOption ? defaultTargetGroupOption.value : "",
      [`configs.marketingTextA.nodes.${updatedNodeType}.promptPresets.value`]:
        defaultPromptPresetOption,
      [`configs.marketingTextB.nodes.${updatedNodeType}.promptPresets.value`]:
        defaultPromptPresetOption,
    });

    setContentType(updatedContentType);
    setTargetGroup(newTargetGroup);
    setNewPromptPreset(newPromptPreset);
  };

  return (
    <Stack sx={{ height: "100%", width: "fit-content" }}>
      {isDisabled ||
      !!config.processStatuses?.marketingTextA?.result ||
      !!config.processStatuses?.marketingTextB?.result ? (
        <ConfigBox>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography>Social media:</Typography>
            <DisabledDropdown>
              {capitalizeFirstLetter(
                config.configs.marketingTextA.general.contentType.value?.[0] ??
                  ""
              )}
            </DisabledDropdown>
          </Stack>
          <Stack sx={{ gap: tinyGap }}>
            <MediumText>Prompt preset settings</MediumText>
            <Stack
              sx={{
                flexDirection: "row",
                gap: defaultGap,
              }}
            >
              <ArenaDisabledPromptPresetSection
                model={"A"}
                configModel={"marketingTextA"}
                nodeType={nodeType}
              />
              <ArenaDisabledPromptPresetSection
                model={"B"}
                configModel={"marketingTextB"}
                nodeType={nodeType}
              />
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography>Language:</Typography>
            <DisabledDropdown>
              {capitalizeFirstLetter(language[0] ?? "")}
            </DisabledDropdown>
          </Stack>

          <Stack>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography>Target group:</Typography>
              <DisabledDropdown>{targetGroup}</DisabledDropdown>
            </Stack>
          </Stack>
        </ConfigBox>
      ) : (
        <ConfigBox>
          <DropdownWithLabel
            id={"social-media"}
            zIndexModifier={100}
            label="Social Media"
            options={config.configs.marketingTextA.general.contentType.value_options.map(
              (o) => ({ value: o.value[0] })
            )}
            value={capitalizeFirstLetter(contentType) ?? ""}
            onChange={handleContentTypeChange}
          />
          <Stack sx={{ gap: tinyGap }}>
            <MediumText>Prompt preset settings</MediumText>
            <Stack
              sx={{
                flexDirection: "row",
                gap: defaultGap,
              }}
            >
              <ArenaPromptPresetSection
                model="A"
                configModel="marketingTextA"
                id="dropdown-a"
                nodeType={nodeType}
                newPromptPreset={newPromptPreset}
                setNewPromptPreset={setNewPromptPreset}
              />
              <ArenaPromptPresetSection
                model="B"
                configModel="marketingTextB"
                id="dropdown-b"
                nodeType={nodeType}
                newPromptPreset={newPromptPreset}
                setNewPromptPreset={setNewPromptPreset}
              />
            </Stack>
          </Stack>

          <DropdownWithLabel
            id={"language"}
            zIndexModifier={100}
            label="Language"
            options={config.configs.marketingTextA.general.languages.value_options.map(
              (o) => ({ value: o.value[0] })
            )}
            value={language[0] ?? ""}
            onChange={async (newLanguage) => {
              const updatedLanguage = [newLanguage];
              await saveArena({
                "configs.marketingTextA.general.languages.value":
                  updatedLanguage,
                "configs.marketingTextB.general.languages.value":
                  updatedLanguage,
              });
              setLanguage(updatedLanguage);
            }}
          />

          <DropdownWithLabel
            id={"target-group"}
            label="Target Group"
            options={config.configs.marketingTextA.nodes[
              nodeType
            ].targetGroup.value_options.map((o) => ({
              value: o.value,
              name: o.name,
            }))}
            value={targetGroup || ""}
            onChange={(value) => {
              const selectedOption = config.configs.marketingTextA.nodes[
                nodeType
              ].targetGroup.value_options.find((o) => o.value === value);
              saveArena({
                [`configs.marketingTextA.nodes.${nodeType}.targetGroup.value`]:
                  value,
                [`configs.marketingTextB.nodes.${nodeType}.targetGroup.value`]:
                  value,
              });
              setTargetGroup(selectedOption ? selectedOption.name : "N/A");
            }}
          />
        </ConfigBox>
      )}
    </Stack>
  );
}
