import { Offer } from "../../../common/models/Models";
import { TaskStatusIcon } from "../../../common/styles/Form.styles";
import { SmallText } from "../../../common/styles/Text.styles";
import {
  perelynDarkBlue,
  perelynError,
  perelynLightBlue,
  perelynLightYellow,
  perelynMediumGreen,
  perelynMediumRed,
  perelynSuccess,
  perelynWarning,
} from "../../../styles/consts";
import { capitalizeFirstLetter } from "../../../utils";

const colorLookup = {
  green: {
    background: perelynMediumGreen,
    text: perelynSuccess,
  },
  yellow: {
    background: perelynLightYellow,
    text: perelynWarning,
  },
  blue: {
    background: perelynLightBlue,
    text: perelynDarkBlue,
  },
  red: {
    background: perelynMediumRed,
    text: perelynError,
  },
};

const statusColorMapping = {
  completed: "green",
  viewed: "red",
  sent: "blue",
};

export default function OfferStatus({ offer }: { offer: Offer }) {
  const status = offer.matches.find((match) => match.status)?.status || "N/A";
  const ts = {
    label:
      status === "completed"
        ? "New"
        : capitalizeFirstLetter(status.toLowerCase().replaceAll("_", " ")),
    color: statusColorMapping[status] || "yellow",
  };

  return (
    <TaskStatusIcon
      sx={{
        backgroundColor: colorLookup[ts.color].background,
        opacity: 0.9,
      }}
    >
      <SmallText
        sx={{
          whiteSpace: "nowrap",
          color: colorLookup[ts.color].text,
        }}
      >
        {ts.label}
      </SmallText>
    </TaskStatusIcon>
  );
}
