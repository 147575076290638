import { PartialTaskParams, Task } from "../common/models/Models";
import { apiAxios } from "./axios";

const TASK_URL = "/task";
const TASKS_URL = "/tasks";

const getURLWithCreatedTimestamp = (url: string, createdTimestamp: string) =>
  `${url}/${createdTimestamp}`;

class TaskService {
  async createTask(type: string): Promise<Task> {
    return (await apiAxios.post<Task>(TASK_URL, { type })).data;
  }

  async deleteTask(createdTimestamp: string): Promise<void> {
    return (await apiAxios.delete<any>(
      getURLWithCreatedTimestamp(TASK_URL, createdTimestamp)
    )).data;
  }

  async deleteTasks(createdTimestamps: string[]): Promise<void> {
    return (await apiAxios.delete<any>(
      TASKS_URL + "?createdTimestamps=" + createdTimestamps.join(",")
    )).data;
  }

  async getTask(createdTimestamp: string): Promise<Task> {
    return (await apiAxios.get<Task>(
      getURLWithCreatedTimestamp(TASK_URL, createdTimestamp)
    )).data;
  }

  async updateTask(
    createdTimestamp: string,
    taskPropertiesToUpdate: PartialTaskParams
  ): Promise<Task> {
    return (await apiAxios.put<Task>(
      getURLWithCreatedTimestamp(TASK_URL, createdTimestamp),
      taskPropertiesToUpdate
    )).data;
  }

  async getTasks(type: string): Promise<Task[]> {
    return (await apiAxios.get<Task[]>(TASKS_URL + (type ? ("?type=" + type) : ""))).data;
  }

  async deleteFile(module: string, createdTimestamp: string): Promise<void> {
    return (await apiAxios.delete<any>(
      getURLWithCreatedTimestamp(`/file/${module}`, createdTimestamp)
    )).data;
  }

  async getFileFromTask(createdTimestamp: string): Promise<string> {
    return (await apiAxios.get<string>(`/get-file-from-task/${createdTimestamp}`)).data;
  }
}

const taskService = new TaskService();
export default taskService;
