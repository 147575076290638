import { Stack } from "@mui/material";

import Choice from "../../../../../../common/components/fundamentals/Choice";
import { MediumText } from "../../../../../../common/styles/Text.styles";
import { useTask } from "../../../context/TaskProvider";
import ConfigBox from "../ConfigBox";

export default function MetaTextSettings() {
  const { task } = useTask();

  return (
    <ConfigBox>
      <Stack>
        <MediumText>Meta text settings</MediumText>
      </Stack>
      <Stack>
        {task.configs.marketingMetadata.nodes.MetadataNode.metadata.value_options.map(
          (o, idx) => (
            <Choice
              choice={{ value: o.value, name: o.value }}
              key={idx}
              isLast={
                idx ===
                task.configs.marketingMetadata.nodes.MetadataNode.metadata
                  .value_options.length -
                  1
              }
              checkIsCurrentlySelected={(choice, t) =>
                t.configs.marketingMetadata.nodes.MetadataNode.metadata.value?.includes(
                  choice
                ) ?? false
              }
              valueParamPath="configs.marketingMetadata.nodes.MetadataNode.metadata.value"
              valueList={
                task.configs.marketingMetadata.nodes.MetadataNode.metadata
                  .value || []
              }
            />
          )
        )}
      </Stack>
    </ConfigBox>
  );
}
