import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import userService from "../../../api/UserService";
import Dropdown from "../../../common/components/fundamentals/Dropdown";
import { BiggerIcon } from "../../../common/components/fundamentals/Icon";
import { usePopUp } from "../../../common/context/PopUpProvider";
import { useSnackbar } from "../../../common/context/SnackbarProvider";
import { useUser } from "../../../common/context/UserProvider";
import { OrangeOnHoverStack } from "../../../common/styles/Box.styles";
import {
  HeadingText,
  SmallText,
  UnderlinedOnHoverText,
} from "../../../common/styles/Text.styles";
import {
  iconGap,
  perelynGrey,
  smallerGap,
  smallGap,
  tinyGap,
} from "../../../styles/consts";
import PopUpBox from "../../Modules/MarketingTextTool/components/result/PopUpBox";
import { InternalSettingsAreaWithPadding } from "../Settings.styles";
import ModifyParameterPopupUser from "./ModifyParameterPopupUser";

export default function ParametersList() {
  const { showPopUpWindow } = usePopUp();
  const { showSnackbar } = useSnackbar();
  const { user, setUser } = useUser();

  const handleDeleteClick = (param: any) => {
    showPopUpWindow(
      () => handleParameterDelete(param),
      `Delete ${param.name}?`,
      `Are you sure you want to delete this parameter? This action cannot be reversed.`,
      "Cancel",
      "Delete"
    );
  };

  const handleParameterDelete = (param: any) => {
    userService.deleteUserParameter(param, setUser);
    showSnackbar("Parameter successfully deleted!", false);
  };

  let filterGroups = [
    ...new Set(
      user.parameters?.value_options.map((option) => option.group) || []
    ),
  ];
  filterGroups = ["all", ...filterGroups];

  const [filteredParameters, setFilteredParameters] = useState(filterGroups);
  const [currentFilter, setCurrentFilter] = useState("all");

  const clearFilter = () => {
    setCurrentFilter("all");
    setFilteredParameters(filterGroups);
  };

  const filter = (filterTag) => {
    if (filterTag === "all") {
      setCurrentFilter("all");
      setFilteredParameters(filterGroups);
    } else if (currentFilter === filterTag) {
      clearFilter();
    } else {
      setCurrentFilter(filterTag);
      const filtered = filterGroups.filter((group) => group === filterTag);
      setFilteredParameters(filtered);
    }
  };

  const parametersArray = Object.entries(
    user.parameters?.value_options.map((option) => ({
      name: option.name,
      value: option.value,
      group: option.group,
    })) || {}
  );

  return (
    <InternalSettingsAreaWithPadding sx={{ height: "100%" }}>
      <Stack sx={{ gap: smallGap, height: "16rem" }}>
        <Stack>
          <HeadingText>Your parameters</HeadingText>
          <SmallText color={perelynGrey}>
            {!user.prompts
              ? "To create new parameter, click on Add new button"
              : "In order to edit parameter, click on its name"}
          </SmallText>
        </Stack>
        <Dropdown
          initialSelected={currentFilter}
          options={filterGroups.map((group) => ({ value: group }))}
          onChange={(value) => filter(value)}
          sx={{ "&&": { minWidth: "10rem", maxWidth: "10rem" } }}
        />

        <Stack
          sx={{
            gap: tinyGap,
            paddingRight: smallerGap,
            height: "100%",
            overflowY: "auto",
            flex: "1",
          }}
        >
          {!user.parameters ? (
            <Typography>Create your first custom parameter!</Typography>
          ) : (
            <>
              {parametersArray
                .filter((param) =>
                  currentFilter === "all"
                    ? true
                    : filteredParameters.includes(param[1].group)
                )
                .map((param) => (
                  <Stack
                    key={param[1].name}
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: iconGap,
                      justifyContent: "space-between",
                    }}
                  >
                    <PopUpBox
                      button={
                        <UnderlinedOnHoverText
                          sx={{
                            width: "12rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {param[1].name}
                        </UnderlinedOnHoverText>
                      }
                      heading={"Edit parameter"}
                    >
                      {(setIsOpen) => (
                        <ModifyParameterPopupUser
                          parameterName={param[1].name}
                          parameterValue={param[1].value[0]}
                          parameterGroup={param[1].group}
                          setIsOpen={setIsOpen}
                        />
                      )}
                    </PopUpBox>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        gap: tinyGap,
                      }}
                    >
                      <OrangeOnHoverStack
                        onClick={() => handleDeleteClick(param[1])}
                      >
                        <BiggerIcon name="Trash" />
                      </OrangeOnHoverStack>
                      <PopUpBox
                        button={
                          <OrangeOnHoverStack>
                            <BiggerIcon name="Edit" />
                          </OrangeOnHoverStack>
                        }
                        heading={"Edit parameter"}
                      >
                        {(setIsOpen) => (
                          <ModifyParameterPopupUser
                            parameterName={param[1].name}
                            parameterValue={param[1].value[0]}
                            parameterGroup={param[1].group}
                            setIsOpen={setIsOpen}
                          />
                        )}
                      </PopUpBox>
                    </Stack>
                  </Stack>
                ))}
            </>
          )}
        </Stack>
      </Stack>
    </InternalSettingsAreaWithPadding>
  );
}
