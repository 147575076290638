import { confirmSignUp, resendSignUpCode, signIn } from "@aws-amplify/auth";
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import userService from "../../api/UserService";
import { useSnackbar } from "../../common/context/SnackbarProvider";
import {
  OutlinedButton,
  PrimaryButton,
} from "../../common/styles/Button.styles";
import { SmallText, TitleText } from "../../common/styles/Text.styles";
import {
  defaultGap,
  perelynBlack,
  perelynPrimary,
  smallerGap,
  tinyGap,
} from "../../styles/consts";

export default function VerifyEmail() {
  const { showSnackbar } = useSnackbar();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [verificationCode, setVerificationCode] = useState("");
  const { state } = useLocation();
  const { email, name, password } = state;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsButtonDisabled(!/^\d{6}$/.test(verificationCode));
  }, [verificationCode]);

  const submitCode = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const { nextStep: confirmSignUpNextStep } = await confirmSignUp({
        username: email,
        confirmationCode: verificationCode,
      });
      if (confirmSignUpNextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN") {
        const { nextStep } = await signIn({
          username: email.toLowerCase(),
          password: password,
        });

        if (nextStep.signInStep === "DONE") {
          await userService.registerUser(email, name);
          showSnackbar("Email verified successfully!", false);
          window.location.reload();
          window.location.href = "/";
        }
      }
    } catch (error) {
      showSnackbar("Invalid code. Try typing again", true);
      setIsLoading(false);
    }
  };

  const handleResend = async () => {
    try {
      await resendSignUpCode({
        username: email,
      });
      showSnackbar("Verification code sent successfully!", false);
    } catch (error) {
      showSnackbar("Something went wrong. Try again", true);
    }
  };

  const isLoadingHandler = () => {
    setIsLoading(true);
  };

  return (
    <Box component="form" onSubmit={submitCode}>
      <Stack
        sx={{
          width: "22.5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "0.75rem",
            paddingBottom: "2rem",
          }}
        >
          <TitleText
            sx={{
              textAlign: "center",
              lineHeight: defaultGap,
            }}
          >
            Verify your email
          </TitleText>
          <Typography>Enter the 6-digit code we sent to your email</Typography>
        </Stack>
        <Stack sx={{ width: "100%", gap: "1.25rem" }}>
          <Stack>
            <SmallText>Verification code</SmallText>
            <TextField
              name="verificationCode"
              id="verificationCode"
              type="text"
              fullWidth
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </Stack>
        </Stack>

        <Stack sx={{ padding: smallerGap, flexDirection: "row", gap: tinyGap }}>
          <OutlinedButton
            sx={{
              fontSize: "1rem",
              "&&": {
                height: "2.75rem",
              },
            }}
            onClick={handleResend}
          >
            Resend
          </OutlinedButton>
          <PrimaryButton
            disabled={isButtonDisabled}
            type="submit"
            sx={{
              fontSize: "1rem",
              "&&": {
                width: "7.25rem",
                height: "2.75rem",
              },
              ":hover": {
                "& .MuiCircularProgress-root": {
                  color: perelynPrimary,
                },
              },
            }}
            onClick={isLoadingHandler}
          >
            {isLoading ? (
              <CircularProgress
                sx={{ color: perelynBlack }}
                size={smallerGap}
              />
            ) : (
              "Verify code"
            )}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Box>
  );
}
