import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import s3Service from "../../../../../api/S3Service";
import taskService from "../../../../../api/TaskService";
import { BiggerIcon } from "../../../../../common/components/fundamentals/Icon";
import CircularLoader from "../../../../../common/components/fundamentals/Loader/CircularLoader";
import { OrangeOnHoverStack } from "../../../../../common/styles/Box.styles";
import { HeadingText } from "../../../../../common/styles/Text.styles";
import {
  borderOrange,
  borderStyle,
  defaultGap,
  iconGap,
  tinyGap,
} from "../../../../../styles/consts";
import { useTask } from "../../../MarketingTextTool/context/TaskProvider";

export default function UploadBox({
  value,
  propertyPath,
  module,
  isInputEmpty,
  setIsInputEmpty,
}: {
  value: string;
  propertyPath: string;
  module: string;
  isInputEmpty?: boolean;
  setIsInputEmpty?: (value: boolean) => void;
}) {
  const { saveTask, task } = useTask();
  const [dragActive, setDragActive] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(
    value !== "s3://" && value !== "input" ? value.split("/").pop() : undefined
  );
  const [isCurrentlyUploading, setIsCurrentlyUploading] = useState(false);
  const [srcUrl, setSrcUrl] = useState<String | undefined>(undefined);
  const [previousInputSourceValue, setPreviousInputSourceValue] = useState();

  const input = task.configs[module].general.inputSource.value;

  useEffect(() => {
    const inputSourceValue =
      task.configs[module]?.general.inputSource.value?.at(0)?.value;
    if (inputSourceValue === previousInputSourceValue) {
      return;
    }

    const fetchPresignedURL = async () => {
      const url = await taskService.getFileFromTask(task.createdTimestamp);
      setSrcUrl(url);
    };

    if (inputSourceValue) {
      setPreviousInputSourceValue(inputSourceValue);
      fetchPresignedURL();
    }
  }, [
    input,
    module,
    previousInputSourceValue,
    task.createdTimestamp,
    task.configs,
  ]);

  const uploadFile = async (file: File) => {
    setIsCurrentlyUploading(true);
    const presigned = await s3Service.getPresignedUploadURL();
    await s3Service.uploadPDF(presigned.uploadURL, file);
    saveTask({
      [propertyPath]:
        "s3://" + presigned.uploadURL.split("?")[0].split(".com/")[1],
    });
    setUploadedFileName(presigned.fileName);
    setIsCurrentlyUploading(false);
  };

  const handleClick = async (e) => {
    await uploadFile(e.target.files[0]);
  };

  const handleDrop = async (e: any) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      uploadFile(e.dataTransfer.files[0]);
      setDragActive(false);
    }
  };

  const handleDrag = (e: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    }
  };

  const deleteFile = async () => {
    if (uploadedFileName) {
      await taskService.deleteFile(module, task.createdTimestamp);
    }
    saveTask({
      [propertyPath]: "",
    });
    setUploadedFileName(undefined);
    setSrcUrl(undefined);
  };
  const [isHover, setIsHover] = useState(false);

  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  return (
    <Box
      id="upload-box"
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      component="label"
      sx={{
        width: "100%",
      }}
    >
      {uploadedFileName ? (
        <Stack
          sx={{
            flexDirection: "row",
            gap: defaultGap,
          }}
        >
          <Stack
            sx={{
              height: "100%",
              gap: tinyGap,
            }}
          >
            <HeadingText>Uploaded files:</HeadingText>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: iconGap,
                justifyContent: "space-between",
              }}
            >
              <Stack sx={{ flexDirection: "row", gap: iconGap }}>
                <BiggerIcon name="RedFile" />
                <Typography>{uploadedFileName}</Typography>
              </Stack>

              {/* <div>
              <Document
                file={"/e2eTest.pdf"}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <p>
                Page {pageNumber} of {numPages}
              </p>
            </div> */}

              <OrangeOnHoverStack id="clear" onClick={deleteFile}>
                <BiggerIcon
                  sx={{
                    "&:hover": {
                      cursor: "crosshair",
                    },
                  }}
                  name="Trash"
                />
              </OrangeOnHoverStack>
            </Stack>
          </Stack>
          {srcUrl && (
            <embed
              src={srcUrl + "#zoom=fitH"}
              style={{
                height: "6.5rem",
                width: "5.6rem",
                border: "none",
              }}
            />
          )}
        </Stack>
      ) : (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            border: isInputEmpty || dragActive ? borderOrange : borderStyle,
            "&:hover": {
              border: borderOrange,
              cursor: "crosshair",
            },
            justifyContent: "center",
            alignItems: "center",
            minHeight: "6.5rem",
          }}
          onMouseEnter={() => {
            setIsHover(true);
            setIsInputEmpty && setIsInputEmpty(false);
          }}
          onMouseLeave={() => setIsHover(false)}
        >
          {isCurrentlyUploading ? (
            <CircularLoader size={defaultGap} />
          ) : (
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: iconGap,
                filter:
                  dragActive || isHover
                    ? "brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(1354%) hue-rotate(315deg) brightness(98%) contrast(107%)"
                    : "none",
              }}
            >
              <Typography>Upload file</Typography>
              <BiggerIcon name="Folder" />
              <Box
                component="input"
                accept={"application/pdf"}
                type="file"
                hidden
                onChange={handleClick}
              />
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
}
