import { Stack, Typography } from "@mui/material";

import {
  MediumText,
  SmallText,
} from "../../../../../../common/styles/Text.styles";
import {
  iconGap,
  perelynGrey,
  smallGap,
} from "../../../../../../styles/consts";
import { capitalizeFirstLetter } from "../../../../../../utils";
import { useTask } from "../../../context/TaskProvider";
import ConfigBox from "../ConfigBox";
import WordsLimit from "./SocialMediaSettings/WordsLimit";
import { orderedContentTypes } from "../MarketingTextConfigPage";

export default function LengthSettings() {
  const { task } = useTask();

  return (
    <ConfigBox>
      <Stack
        sx={{
          gap: iconGap,
        }}
      >
        <MediumText>Length settings</MediumText>
        <SmallText>Specify the length of the generated output*</SmallText>
      </Stack>

      {orderedContentTypes
        .filter((type) =>
          task.configs.marketingText.general.contentType.value?.includes(type)
        )
        .map((type, idx) => {
          if (type === "x") {
            return (
              <Stack
                key={idx}
                sx={{
                  flexDirection: "row",
                  gap: "2.75rem",
                }}
              >
                <Typography>The length for X is set by default</Typography>
              </Stack>
            );
          } else {
            return (
              <Stack
                key={idx}
                sx={{
                  gap: smallGap,
                }}
              >
                <Typography>{capitalizeFirstLetter(type)}</Typography>
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "2.75rem",
                  }}
                >
                  <WordsLimit
                    id={`min-${type}`}
                    contentType={capitalizeFirstLetter(type)}
                    type={"min"}
                    color={perelynGrey}
                  />
                  <WordsLimit
                    id={`max-${type}`}
                    contentType={capitalizeFirstLetter(type)}
                    type={"max"}
                  />
                </Stack>
              </Stack>
            );
          }
        })}
    </ConfigBox>
  );
}
