import { Stack } from "@mui/material";
import { SmallText } from "../../common/styles/Text.styles";
import { checkboxGap } from "../../styles/consts";
import { CustomTagsBox } from "../../common/styles/Form.styles";

export default function TagsOverview({ tags }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: checkboxGap,
        whiteSpace: "nowrap",
        alignItems: "center",
      }}
    >
      {tags.map((option) => (
        <CustomTagsBox key={option}>
          <SmallText>{option}</SmallText>
        </CustomTagsBox>
      ))}
    </Stack>
  );
}
