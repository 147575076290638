import { Stack } from "@mui/material";
import Dropdown from "../../../../../common/components/fundamentals/Dropdown";
import TaskAutoUpdateTextField from "../../../../../common/components/fundamentals/TaskAutoUpdateTextField";
import { FullStack } from "../../../../../common/styles/Box.styles";
import {
  HeadingText,
  SmallText,
} from "../../../../../common/styles/Text.styles";
import {
  borderStyle,
  defaultGap,
  iconSize,
  perelynPrimary,
  smallGap,
} from "../../../../../styles/consts";

export default function ImageGenerationConfigPage() {
  return (
    <FullStack>
      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          gap: "3rem",
          paddingBottom: defaultGap,
          boxSizing: "border-box",
        }}
      >
        <Stack
          sx={{
            gap: iconSize,
          }}
        >
          <HeadingText>Let's get started</HeadingText>
          <HeadingText>Add text*</HeadingText>
          <SmallText>
            Enter the prompts you want to use to generate the image.
          </SmallText>
        </Stack>
        <Stack
          sx={{
            width: "100%",
            gap: smallGap,
          }}
        >
          <Stack
            sx={{
              width: "100%",
              gap: defaultGap,
            }}
          >
            <Dropdown initialSelected={""} options={[]} />
            <TaskAutoUpdateTextField
              id="user-prompt"
              defaultValue={""}
              propPath={""}
              sx={{
                width: "100%",
              }}
              placeholder="Enter user prompt*"
              multiline
              rows="10"
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          height: "35rem",
          border: borderStyle,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HeadingText color={perelynPrimary}>
          Some Configurations here?
        </HeadingText>
      </Stack>
    </FullStack>
  );
}
