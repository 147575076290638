import { Button, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import configService from "../../../../api/ConfigService";
import feedbackService from "../../../../api/FeedbackService";
import alqemiPostgresService from "../../../../api/PostgresService";
import Icon, {
  BiggerIcon,
} from "../../../../common/components/fundamentals/Icon";
import { useSnackbar } from "../../../../common/context/SnackbarProvider";
import {
  OutlinedButton,
  PrimaryButton,
} from "../../../../common/styles/Button.styles";
import { TaskStatusIcon } from "../../../../common/styles/Form.styles";
import { MediumText, SmallText } from "../../../../common/styles/Text.styles";
import {
  borderOrange,
  borderStyle,
  defaultTransition,
  iconGap,
  perelynGrey,
  perelynMediumGreen,
  perelynSuccess,
  smallGap,
  tinyGap,
} from "../../../../styles/consts";
import PopUpBox from "../../MarketingTextTool/components/result/PopUpBox";

const alqemiMatchingConfigId = {
  dev: "3b026335-d1a8-4f1e-ac5a-8dd914441d1f",
  prod: "e9644ebe-a3f1-4112-92b2-44b718661bf5",
};

const getCurrentEnv = () => {
  return process.env.REACT_APP_ENV;
};

export default function ButtonSection({
  emailContent,
  pdfDownloadBlob,
  pdfFileName,
  displayedMatch,
}: {
  emailContent: string;
  pdfDownloadBlob: Blob | null;
  pdfFileName: string;
  displayedMatch: any;
}) {
  const { showSnackbar } = useSnackbar();
  const [alqemiMatchingConfig, setAlqemiMatchingConfig] = useState<any>(null);
  const [emailTemplate, setEmailTemplate] = useState("");
  const [emailPrompt, setEmailPrompt] = useState("");
  const [closePopUp, setClosePopUp] = useState(false);

  const currentEnv = getCurrentEnv() || "prod";

  const handleSendEmail = () => {
    alqemiPostgresService.updateMatchStatus(
      displayedMatch.consultant_id,
      displayedMatch.job_id,
      "sent"
    );
    showSnackbar(`Offer marked as "Sent!"`);
    // showSnackbar("Email successfully sent!");
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(emailContent.toString());
    showSnackbar("Copied to clipboard!");
  };
  const handleDownloadCV = () => {
    if (pdfDownloadBlob) {
      const aTag = document.createElement("a");
      aTag.href = URL.createObjectURL(pdfDownloadBlob);
      aTag.style.display = "none";
      aTag.download = pdfFileName + ".pdf";
      document.body.appendChild(aTag);
      aTag.click();
    }
  };

  const handleMarkAsNew = () => {
    alqemiPostgresService.updateMatchStatus(
      displayedMatch.consultant_id,
      displayedMatch.job_id,
      "completed"
    );
    showSnackbar(`Offer marked as "New"!`);
  };

  const handleReRunEmail = async () => {
    await feedbackService.createFeedback({
      matchId: displayedMatch.id,
      feedback: `Email template: ${emailTemplate},\n\nEmail prompt: ${emailPrompt}`,
    });
    setClosePopUp(true);
    showSnackbar("Re-running email generation", false, true);
  };

  const getAlqemiMatchingConfig = async () => {
    const config = await configService.getConfiguration(
      alqemiMatchingConfigId[currentEnv]
    );
    setAlqemiMatchingConfig(config);
    return config;
  };

  useEffect(() => {
    getAlqemiMatchingConfig();
  }, []);

  useEffect(() => {
    if (alqemiMatchingConfig) {
      setEmailTemplate(
        alqemiMatchingConfig?.nodes?.WriteEmailNode?.email_template?.value[0]
          .email_template
      );
      setEmailPrompt(
        alqemiMatchingConfig?.nodes?.WriteEmailNode?.promptPresets.value.prompts
          .systemPrompt1
      );
    }
  }, [alqemiMatchingConfig]);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: smallGap,
      }}
    >
      <PopUpBox
        heading="Adjust email prompts"
        button={<OutlinedButton>Re-run email</OutlinedButton>}
        width="60%"
        closePopUp={closePopUp}
        setClosePopUp={setClosePopUp}
      >
        <Stack sx={{ border: borderStyle, padding: smallGap, gap: smallGap }}>
          <Stack
            sx={{
              flexDirection: "row",
              width: "100%",
              gap: smallGap,
              boxSizing: "border-box",
            }}
          >
            <Stack sx={{ width: "100%" }}>
              <MediumText>Template</MediumText>
              <TextField
                value={emailTemplate}
                multiline
                maxRows={25}
                onChange={(e) => setEmailTemplate(e.target.value)}
              />
            </Stack>

            <Stack sx={{ width: "100%" }}>
              <MediumText>Prompt</MediumText>
              <TextField
                value={emailPrompt}
                multiline
                maxRows={25}
                onChange={(e) => setEmailPrompt(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
            <PrimaryButton onClick={handleReRunEmail}>Generate</PrimaryButton>
          </Stack>
        </Stack>
      </PopUpBox>

      <Button
        onClick={handleMarkAsNew}
        sx={{
          gap: iconGap,
          border: borderStyle,
          "&:hover": {
            backgroundColor: "white",
            border: borderOrange,
          },
        }}
      >
        <Typography>Mark as</Typography>
        <TaskStatusIcon
          sx={{
            backgroundColor: perelynMediumGreen,
            opacity: 0.9,
          }}
        >
          <SmallText sx={{ color: perelynSuccess }}>New</SmallText>
        </TaskStatusIcon>
      </Button>
      <OutlinedButton
        onClick={handleDownloadCV}
        sx={{
          borderColor: perelynGrey,
          padding: tinyGap,
          color: perelynGrey,
          gap: "0.5rem",
        }}
      >
        Download CV
        <Icon
          sx={{
            height: "1.5rem",
            width: "1.5rem",
          }}
          name="Download"
        />
      </OutlinedButton>
      <OutlinedButton onClick={handleCopyToClipboard}>
        <BiggerIcon name="Copy" />
      </OutlinedButton>
      <PrimaryButton
        sx={{
          gap: tinyGap,

          "&:hover": {
            "& > *": {
              transition: defaultTransition,
              filter:
                "brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(1354%) hue-rotate(315deg) brightness(98%) contrast(107%)",
            },
          },
          "&:disabled": {
            "& > *": {
              filter:
                "brightness(0) saturate(100%) invert(70%) sepia(9%) saturate(77%) hue-rotate(22deg) brightness(91%) contrast(86%)",
            },
          },
        }}
        // disabled={offer.matches.at(index)?.status === "sent"}
        onClick={handleSendEmail}
      >
        {/* Send Email */}Mark as Sent
        <BiggerIcon name="SendRight" />
      </PrimaryButton>
    </Stack>
  );
}
