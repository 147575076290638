import { Stack } from "@mui/material";
import { useState } from "react";
import {
  default as alqemiPostgresService,
  default as PostgresService,
} from "../../../../api/PostgresService";
import Dropdown from "../../../../common/components/fundamentals/Dropdown";
import { BiggerIcon } from "../../../../common/components/fundamentals/Icon";
import { useSnackbar } from "../../../../common/context/SnackbarProvider";
import { OrangeOnHoverStack } from "../../../../common/styles/Box.styles";
import { PrimaryButton } from "../../../../common/styles/Button.styles";
import {
  borderStyle,
  iconGap,
  smallerGap,
  smallGap,
} from "../../../../styles/consts";
import { formatDateFromIsoToIsoWithTimezone } from "../../../../utils";
import { useAlqemiDashboard } from "../../../Offer/context/AlqemiDashboardProvider";
import PopUpBox from "../../MarketingTextTool/components/result/PopUpBox";
import CandidateCard from "./CandidateCard";

export default function CandidatesList() {
  const { candidates, setCandidates } = useAlqemiDashboard();
  const { showSnackbar } = useSnackbar();
  const [isAdding, setIsAdding] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<string | null>(
    null
  );
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleSave = async () => {
    if (!selectedCandidate) {
      showSnackbar("Please select a candidate from the dropdown", true, true);
      return;
    }
    if (!selectedDate) {
      showSnackbar("Please pick a date", true, true);
      return;
    }

    try {
      await alqemiPostgresService.updateCandidateStatus(
        selectedCandidate,
        "active",
        formatDateFromIsoToIsoWithTimezone(selectedDate)
      );
      showSnackbar("Candidate's status updated successfully.", false, true);
      setCandidates(
        candidates.map((candidate) =>
          candidate.id === selectedCandidate
            ? { ...candidate, status: "active", from_date: selectedDate }
            : candidate
        )
      );
      setIsAdding(false);
      setSelectedCandidate(null);
      setSelectedDate(null);
    } catch (error) {
      showSnackbar("Failed to update candidate's status", true, true);
    }
  };

  const handleEditDate = async (id: string, newDate: string) => {
    if (!newDate) {
      showSnackbar("Please pick a date", true, true);
      return;
    }

    try {
      await alqemiPostgresService.updateCandidateStatus(
        id,
        "active",
        formatDateFromIsoToIsoWithTimezone(newDate)
      );
      showSnackbar(
        "Candidate's availability updated successfully!",
        false,
        true
      );
      setCandidates(
        candidates.map((candidate) =>
          candidate.id === id ? { ...candidate, from_date: newDate } : candidate
        )
      );
      setSelectedCandidate(null);
      setSelectedDate(null);
    } catch (error) {
      showSnackbar("Failed to update candidate's availability", true, true);
    }
  };

  const handleDelete = async (id: string) => {
    await PostgresService.updateCandidateStatus(id, "inactive");
    showSnackbar("Candidate's status updated to inactive", false, true);
    setCandidates(
      candidates.map((candidate) =>
        candidate.id === id ? { ...candidate, status: "inactive" } : candidate
      )
    );
  };

  return (
    <PopUpBox
      button={<PrimaryButton>Scouting candidates</PrimaryButton>}
      heading="Scouting candidates"
      height="50%"
      onClose={() => setIsAdding(false)}
    >
      <Stack
        sx={{
          height: "100%",
          border: borderStyle,
          padding: smallerGap,
          gap: smallGap,
        }}
      >
        {candidates
          .filter((candidate) => candidate.status === "active")
          .map((candidate, idx) => (
            <CandidateCard
              key={idx}
              candidate={candidate}
              handleDelete={handleDelete}
              handleDateChange={handleDateChange}
              handleEditDate={handleEditDate}
              selectedDate={selectedDate || ""}
            />
          ))}
        {isAdding && (
          <Stack
            sx={{
              border: borderStyle,
              width: "20rem",
              padding: iconGap,
              flexDirection: "row",
              gap: smallGap,
            }}
          >
            <Stack
              component={"img"}
              src={selectedCandidate ? "/pfpTest.jpg" : "/pfpTest.jpg"}
              sx={{ border: borderStyle, width: "4.5rem", height: "4.5rem" }}
            />
            <Stack
              sx={{
                flexDirection: "column",
                gap: iconGap,
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Dropdown
                minWidth="100%"
                id="candidate-dropdown"
                initialSelected=""
                options={candidates
                  .filter(
                    (candidate) =>
                      candidate.status === "inactive" || !candidate.status
                  )
                  .map((candidate) => ({
                    value: candidate.id,
                    name: candidate.name,
                  }))}
                onChange={(value: string) => {
                  setSelectedCandidate(value);
                }}
              />
              <Stack
                sx={{
                  width: "100%",
                  height: "fit-content",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "end",
                }}
              >
                <Stack
                  component={"input"}
                  type="date"
                  onChange={handleDateChange}
                  sx={{
                    border: borderStyle,
                    height: "1.25rem",
                    justifyContent: "center",
                  }}
                />

                <PrimaryButton sx={{ height: "1.25rem" }} onClick={handleSave}>
                  Save
                </PrimaryButton>
              </Stack>
            </Stack>
          </Stack>
        )}
        <OrangeOnHoverStack
          sx={{
            "&&": {
              height: "4.20rem",
              width: "4.20rem",
            },
            border: borderStyle,
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            setIsAdding(true);
          }}
        >
          <BiggerIcon
            name="Plus"
            sx={{
              height: "2rem",
              width: "2rem",
            }}
          />
        </OrangeOnHoverStack>
      </Stack>
    </PopUpBox>
  );
}
