import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getLayoutConfig, LayoutConfig, ModuleName } from "../../routes";
import { removeTrailingSlash } from "../../utils";

export const defaultLayoutConfig: LayoutConfig = {
  heading: "",
  secondarySidebarVisible: true,
  addToFavoriteVisible: false,
  backToVisible: true,
  useToolVisible: false,
};

type LayoutConfigState = {
  layoutConfig: LayoutConfig;
  setLayoutConfig: (layoutConfig: LayoutConfig) => void;
  moduleName: ModuleName;
};

const LayoutConfigContext = createContext<LayoutConfigState>({
  layoutConfig: defaultLayoutConfig,
  setLayoutConfig: () => {},
  moduleName: "",
});

export const useLayoutConfig = (): LayoutConfigState => {
  const layoutConfigState = useContext(LayoutConfigContext);
  if (!layoutConfigState) {
    throw new Error("useLoading must be used within a LayoutConfigProvider");
  }
  return layoutConfigState;
};

export default function LayoutConfigProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [layoutConfig, setLayoutConfig] = useState(defaultLayoutConfig);
  const [moduleName, setModuleName] = useState<ModuleName>("");
  const location = useLocation();

  useEffect(() => {
    setModuleName(location.pathname.split("/")[1] as ModuleName);
    const path =
      location.pathname === "/"
        ? location.pathname
        : removeTrailingSlash(location.pathname);
    setLayoutConfig(getLayoutConfig(path));
  }, [location.pathname]);

  const layoutConfigState: LayoutConfigState = {
    layoutConfig,
    setLayoutConfig,
    moduleName,
  };

  return (
    <LayoutConfigContext.Provider value={layoutConfigState}>
      <>{children}</>
    </LayoutConfigContext.Provider>
  );
}
