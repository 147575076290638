import { Document, Font, Page } from "@react-pdf/renderer";
import font from "../fonts/ABCWhyte.ttf";
import FirstPageContent from "./FirstPageContent";
import Footer from "./Footer";
import Header from "./Header";
import ProfessionalCareer from "./ProfessionalCareer";
import Tech from "./Tech";
import Projects from "./Projects";
import { CVContentContext } from "./CVPDF";

export default function Doc({ cvContent }) {
  Font.register({
    family: "ABCWhyte",
    format: "truetype",
    fonts: [{ src: font }, { src: font, fontWeight: "bold" }],
  });

  return (
    <CVContentContext.Provider value={cvContent}>
      <Document>
        <Page
          style={{
            paddingBottom: "100px",
          }}
        >
          <Header />
          <FirstPageContent />
          <Footer />
          <Tech />
          <ProfessionalCareer />
          <Projects />
        </Page>
      </Document>
    </CVContentContext.Provider>
  );
}
