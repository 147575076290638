import { signInWithRedirect } from "@aws-amplify/auth";
import { checkboxGap, perelynLightRed, smallGap } from "../../../styles/consts";
import { OutlinedButton } from "../../styles/Button.styles";
import { BiggerIcon } from "./Icon";

export default function MicrosoftButton() {
  const provider = { custom: "AzureAD" };

  function handleSignInClick() {
    signInWithRedirect({ provider });
  }

  return (
    <OutlinedButton
      // disabled
      onClick={() => {
        handleSignInClick();
      }}
      sx={{
        "&&": {
          width: "100%",
        },
        fontSize: "1rem",
        gap: checkboxGap,
        padding: `1.35rem ${smallGap}`,
        ":hover": {
          "&&": {
            backgroundColor: perelynLightRed,
            "& > *": { filter: "none" },
          },
        },
      }}
    >
      <BiggerIcon name="Microsoft" />
      Sign in with Microsoft
    </OutlinedButton>
  );
}
