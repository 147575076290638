import {
  Organization,
  PartialOrganizationParams,
  User,
} from "../common/models/Models";
import { apiAxios } from "./axios";

const ORGANIZATION_URL = "/organization";
const ORGANIZATION_USERS_URL = "/organization/users";
const ORGANIZATIONS_URL = "/organizations";

const getURLWithName = (url: string, name: string) => `${url}/${name}`;

class OrganizationService {
  async createOrganization(name: string): Promise<Organization> {
    return (await apiAxios.post<Organization>(ORGANIZATION_URL, { name })).data;
  }

  async deleteOrganization(name: string): Promise<void> {
    return (await apiAxios.delete<any>(getURLWithName(ORGANIZATION_URL, name)))
      .data;
  }

  async getOrganization(name: string): Promise<Organization> {
    return (
      await apiAxios.get<Organization>(getURLWithName(ORGANIZATION_URL, name))
    ).data;
  }

  async updateOrganization(
    name: string,
    organizationPropertiesToUpdate: PartialOrganizationParams
  ): Promise<Organization> {
    return (
      await apiAxios.put<Organization>(
        getURLWithName(ORGANIZATION_URL, name),
        organizationPropertiesToUpdate
      )
    ).data;
  }

  async getOrganizationUsers(name: string): Promise<User[]> {
    return (
      await apiAxios.get<User[]>(getURLWithName(ORGANIZATION_USERS_URL, name))
    ).data;
  }

  async joinOrganization(name: string): Promise<Organization> {
    return (
      await apiAxios.post<Organization>(getURLWithName(ORGANIZATION_URL, name))
    ).data;
  }

  async getOrganizations(): Promise<Organization[]> {
    return (await apiAxios.get<Organization[]>(ORGANIZATIONS_URL)).data;
  }

  async deleteFile(name: string): Promise<void> {
    return (await apiAxios.delete<any>(getURLWithName("/file", name))).data;
  }
}

const organizationService = new OrganizationService();
export default organizationService;
