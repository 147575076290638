import { getCurrentUser } from "@aws-amplify/auth";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../../api/UserService";
import LoginPage from "../../../pages/Login/LoginPage";
import LayoutConfigProvider from "../../context/LayoutConfigProvider";
import { useLoading } from "../../context/LoadingProvider";
import PopUpProvider from "../../context/PopUpProvider";
import SnackbarProvider from "../../context/SnackbarProvider";
import { CognitoUser } from "../../models/Models";
import LoggedInView from "../LoggedInView";

export default function Layout() {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const { setIsLoading, withLoading } = useLoading();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleFederatedUser = async () => {
      const params = new URLSearchParams(location.search);
      if (params.get("federated") === "true") {
        setIsLoading(true);
        await UserService.handleFederatedUser();
        params.delete("federated");
        params.delete("code");
        params.delete("state");
        navigate({ search: params.toString() }, { replace: true });
        setIsLoading(false);
      }
    };

    const getUser = async () => {
      // if (!user) {
      //   setIsLoading(true);
      // }
      try {
        const user = await getCurrentUser();
        setUser(user);
      } catch (error) {
        localStorage.clear();
      }
      // setIsLoading(false);
    };
    const init = async () => {
      await handleFederatedUser();
      await getUser();
    };

    init();
  }, [location.search, navigate]);

  return (
    <PopUpProvider>
      <SnackbarProvider>
        <LayoutConfigProvider>
          {user ? <LoggedInView /> : <LoginPage />}
        </LayoutConfigProvider>
      </SnackbarProvider>
    </PopUpProvider>
  );
}
