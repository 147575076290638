import { Box, Stack, Typography } from "@mui/material";
import userService from "../../../api/UserService";
import {
  ActiveIcon,
  BiggerIcon,
} from "../../../common/components/fundamentals/Icon";
import SearchBar from "../../../common/components/layout/SearchBar";
import Table from "../../../common/components/table/Table";
import { usePopUp } from "../../../common/context/PopUpProvider";
import { useSnackbar } from "../../../common/context/SnackbarProvider";
import { User } from "../../../common/models/Models";
import { HeadingText } from "../../../common/styles/Text.styles";
import { defaultGap, smallerGap } from "../../../styles/consts";
import { roundToTwoDecimals } from "../../../utils";
import { useUsers } from "./UsersProvider";

export default function AdminSettings() {
  const { users, setUsers } = useUsers();
  const { showSnackbar } = useSnackbar();
  const { showPopUpWindow } = usePopUp();

  const handleActivationClick = (user: User) => {
    if (user.active) {
      showPopUpWindow(
        () => handleConfirmation(user),
        `Deactivate user ${user.name}`,
        `Are you sure you want to deactivate user with this email: ${user.email}? This user will no longer be able to use any modules.`,
        "Cancel",
        "Deactivate"
      );
      return;
    } else {
      showPopUpWindow(
        () => handleConfirmation(user),
        `Activate user ${user.name}`,
        `Are you sure you want to activate user with this email: ${user.email}?`,
        "Cancel",
        "Activate"
      );
    }
  };

  const handleConfirmation = async (user: User) => {
    try {
      const id = user.id;
      const active = !user.active;
      await userService.activateUser(id, active);
      const newUsers = users.map((u) => {
        if (u.id === id) {
          return { ...u, active };
        }
        return u;
      });
      setUsers(newUsers);
      if (active) {
        showSnackbar(`User ${user.name} activated successfully`, false);
      } else {
        showSnackbar(`User ${user.name} deactivated`, true);
      }
    } catch (error) {
      showSnackbar("Failed to update user", true);
    }
  };

  const getUserRow = (user: User) => {
    return [
      {
        sortParam: "email",
        label: "Email",
        component: <Typography>{user.email || "Email"}</Typography>,
        width: "45%",
        minWidth: "7rem",
      },
      {
        sortParam: "active",
        label: "Active",
        component: (
          <Box>
            {user.active ? (
              <ActiveIcon
                name="CheckSquare"
                active
                onClick={() => handleActivationClick(user)}
              />
            ) : (
              <ActiveIcon onClick={() => handleActivationClick(user)} />
            )}
          </Box>
        ),
        width: "15%",
        minWidth: "7rem",
      },
      {
        sortParam: "name",
        label: "Name",
        component: <Typography>{user.name || "Name"}</Typography>,
        width: "25%",
        minWidth: "7rem",
      },
      {
        sortParam: "cost",
        label: "Total Cost",
        component: (
          <Typography>${roundToTwoDecimals(user.totalCost || 0)}</Typography>
        ),
        width: "15%",
        minWidth: "7rem",
      },
      {
        component: (
          <Stack
            sx={{
              flexDirection: "row",
              gap: smallerGap,
            }}
          >
            <BiggerIcon
              name="UserCircle"
              sx={{
                cursor: "crosshair",
              }}
              onClick={() => showSnackbar("Not implemented", true)}
            />
          </Stack>
        ),
        width: "10%",
        minWidth: "4rem",
      },
    ];
  };

  return (
    <Box>
      <SearchBar type={"users"} onSearch={() => {}} notImplemented />

      <HeadingText sx={{ padding: `${defaultGap} ${defaultGap} 0` }}>
        Users table
      </HeadingText>
      <Table<User>
        deletable={false}
        filters={[
          { value: "All" },
          { value: "Low to High" },
          { value: "High to Low" },
          { value: "A to Z" },
          { value: "Z to A" },
        ]}
        items={users}
        idProperty="email"
        filterFunction={(value) => users}
        getItemRow={getUserRow}
      />
      {/* <PrimaryButton onClick={PopUpTest}>PopUp Test</PrimaryButton> */}
    </Box>
  );
}
