import { Stack, Typography } from "@mui/material";
import {
  borderOrange,
  borderStyle,
  defaultTransition,
  perelynPrimary,
  smallGap,
  tinyGap,
} from "../../../../styles/consts";

export default function Candidate({ name, isDisplayed, onClick }) {
  return (
    <Stack
      onClick={onClick}
      sx={{
        flexDirection: "row",
        border: isDisplayed ? borderOrange : borderStyle,
        padding: tinyGap,
        // minWidth: sidebarWidth,
        width: "fit-content",
        alignItems: "center",
        gap: smallGap,
        transition: defaultTransition,
        "&:hover": {
          border: borderOrange,
          cursor: "crosshair",
          "& > *": {
            color: perelynPrimary,
          },
        },
      }}
    >
      <Typography
        sx={{
          transition: defaultTransition,
          color: isDisplayed && perelynPrimary,
        }}
      >
        {name}
      </Typography>
    </Stack>
  );
}
