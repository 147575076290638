import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

export default function PerelynLogo() {
  return (
    <Svg
      width="80"
      height="26"
      style={{
        overflow: "hidden",
      }}
    >
      <Defs>
        <ClipPath id="clip0">
          <Rect x="0" y="0" width="80" height="26" />
        </ClipPath>
        <ClipPath id="clip1">
          <Rect x="0" y="0" width="80" height="26" />
        </ClipPath>
        <ClipPath id="clip2">
          <Rect x="0" y="0" width="79" height="26" />
        </ClipPath>
        <ClipPath id="clip3">
          <Rect x="0" y="0" width="79" height="26" />
        </ClipPath>
        <ClipPath id="clip4">
          <Rect x="0" y="0" width="79" height="26" />
        </ClipPath>
      </Defs>
      <G clip-path="url(#clip0)">
        <G clip-path="url(#clip1)">
          <G clip-path="url(#clip2)">
            <G clip-path="url(#clip3)">
              <G clip-path="url(#clip4)">
                <Path
                  d="M74.6442 5.18442C72.1082 5.18442 70.7502 6.81369 69.7198 8.76629L68.5151 8.76629 68.6599 8.57089C69.4063 7.44347 69.8885 6.30622 69.9433 5.54992L67.3455 5.54992 67.3567 19.1267 70.2357 19.1267 70.2357 12.9737C70.2357 11.4049 70.2357 7.73306 73.5168 7.73306 75.1952 7.73306 76.0457 8.77753 76.0457 10.8398L76.0457 19.1281 78.9205 19.1281 78.9205 10.1749C78.9205 6.8643 77.4824 5.18583 74.6456 5.18583Z"
                  fill="#242424"
                  fill-rule="nonzero"
                  fill-opacity="1"
                  transform="matrix(1 0 0 1.00344 0.0404613 -3.22126e-16)"
                />
                <Path
                  d="M61.4273 11.4133C60.9338 12.9681 60.6415 14.0688 60.6133 16.1128L60.6133 16.2505 59.5281 16.2505 59.5253 16.1128C59.4986 14.4427 59.0178 13.0496 58.3234 11.4541L55.8703 5.57804 52.5991 5.57804 58.8632 19.1562 58.866 25.9109 59.5253 25.9109 66.4557 5.57804 63.2829 5.57804 61.4273 11.4133Z"
                  fill="#242424"
                  fill-rule="nonzero"
                  fill-opacity="1"
                  transform="matrix(1 0 0 1.00344 0.0404613 -3.22126e-16)"
                />
                <Path
                  d="M23.7699 6.99082C22.6917 5.80577 21.1918 5.18021 19.4332 5.18021 15.7332 5.18021 13.1494 8.1337 13.1494 12.3622 13.1494 16.5907 15.5772 19.5428 19.4866 19.5428 23.157 19.5428 24.7891 17.5354 25.5145 15.7937L22.8393 14.7056C22.1224 16.356 21.0427 17.16 19.54 17.16 17.4637 17.16 16.2182 15.7163 16.1226 13.1986L16.117 13.0538 25.4891 13.0538C25.693 10.5825 25.0843 8.43312 23.7713 6.99082ZM16.1845 10.837 16.2027 10.6809C16.4403 8.6721 17.6788 7.42379 19.4332 7.42379 21.1875 7.42379 22.4246 8.67491 22.5314 10.6894L22.5399 10.837 16.1845 10.837Z"
                  fill="#242424"
                  fill-rule="nonzero"
                  fill-opacity="1"
                  transform="matrix(1 0 0 1.00344 0.0404613 -3.22126e-16)"
                />
                <Path
                  d="M45.687 6.99504C44.6088 5.81139 43.1089 5.18442 41.3503 5.18442 37.6503 5.18442 35.0666 8.13651 35.0666 12.3636 35.0666 16.5907 37.4943 19.5428 41.4037 19.5428 45.0741 19.5428 46.7062 17.5354 47.4316 15.7951L44.7564 14.707C44.0395 16.3574 42.9599 17.16 41.4571 17.16 39.3808 17.16 38.1353 15.7163 38.0397 13.2L38.0341 13.0552 47.4063 13.0552C47.6101 10.5853 47.0014 8.43593 45.6884 6.99363ZM38.1016 10.8412 38.1199 10.6851C38.3574 8.67632 39.5959 7.42941 41.3503 7.42941 43.1047 7.42941 44.3417 8.68054 44.4486 10.695L44.457 10.8426 38.1016 10.8426Z"
                  fill="#242424"
                  fill-rule="nonzero"
                  fill-opacity="1"
                  transform="matrix(1 0 0 1.00344 0.0404613 -3.22126e-16)"
                />
                <Path
                  d="M6.06302 0 0 0 0 19.1267 2.78902 19.1267 2.79183 13.2 6.2036 13.2C8.01561 13.2 9.6969 12.4564 10.9368 11.1055 12.0979 9.84169 12.7628 8.16181 12.7628 6.49459 12.7628 3.30212 10.2578 0 6.06302 0ZM5.40934 10.4869 2.78902 10.4869 2.78621 2.77215 5.54711 2.77215C8.01561 2.77215 9.673 4.26787 9.673 6.49318 9.673 8.71849 7.80053 10.4869 5.40934 10.4869Z"
                  fill="#242424"
                  fill-rule="nonzero"
                  fill-opacity="1"
                  transform="matrix(1 0 0 1.00344 0.0404613 -3.22126e-16)"
                />
                <Path
                  d="M29.4871 9.67581 28.3246 9.67581 28.4581 9.46214C29.0724 8.47389 29.6952 6.86571 29.7373 5.57944L27.1044 5.57944 27.1044 19.1281 29.8484 19.1281 29.8484 13.6119C29.8484 10.0835 31.226 8.50903 34.3145 8.50903 34.4607 8.50903 34.6027 8.51185 34.7531 8.51747L34.7531 5.3503C34.7418 5.3503 34.7306 5.3503 34.7179 5.3503 32.1145 5.3503 29.892 7.96922 29.4857 9.67581Z"
                  fill="#242424"
                  fill-rule="nonzero"
                  fill-opacity="1"
                  transform="matrix(1 0 0 1.00344 0.0404613 -3.22126e-16)"
                />
                <Path
                  d="M49.0131 0.387988 51.6235 0.387988 51.6235 19.1267 49.0131 19.1267Z"
                  fill="#242424"
                  fill-rule="nonzero"
                  fill-opacity="1"
                  transform="matrix(1 0 0 1.00344 0.0404613 -3.22126e-16)"
                />
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
}
